import { ExportConst } from '../config'

const userEmailExists = (form) => {

    for (let i = 0; i < form.components.length; i++) {
        if (form.components[i].key === ExportConst.USER_EMAIL && (form.components[i].inputType === "textfield" || form.components[i].inputType === "email" || form.components[i].inputType === "text")) {
            return true
        }
    }
    return false
}

const userNameExists = (form) => {

    for (let i = 0; i < form.components.length; i++) {
        if (form.components[i].key === ExportConst.USER_NAME && (form.components[i].inputType === "textfield" || form.components[i].inputType === "text")) {
            return true
        }
    }
    return false
}
const userEmailContactExists = (form) => {
    if (form.properties.gsis && form.properties.gsis === true) {
        for (let i = 0; i < form.components.length; i++) {
            if (form.components[i].key === ExportConst.USER_EMAIL_CONTACT && form.components[i].inputType === "email") {
                return true
            }
        }
        return false
    }
}

export { userEmailExists, userNameExists, userEmailContactExists }
