/** ********************************************************************************
 * G. Papaioannou
 * g_papaioannou@rocketmail.com
 * Mar, 2022
 *
 * 05-04-2022 Added UPDATE_ADMIN_USER Action
 * 25-05-2022 Added News Reducers
 * 26-01-2024 Added connectedUser redux state
 *
 *
 ********************************************************************************** */

import { combineReducers } from 'redux';
import { auth, form, forms, submission, submissions, USER_LOGOUT } from 'react-formio';

import queryReducer from './redux/reducers/queryReducer';
import maintenanceReducer from './redux/reducers/maintenanceReducer';
import pinReducer from './redux/reducers/pinReducer';

// Combine all reducers.
const appReducer = combineReducers({
	auth: auth(),
	connecteduser: (state = { userrealname: '' }, action) => {
		switch (action.type) {
			case 'setname':
				return {
					...state,
					userrealname: action.payload,
				};
			default:
				return {
					...state,
				};
		}
	},
	form: form({ name: 'form' }),
	forms: forms({ name: 'forms', query: { type: 'form' } }),
	submission: submission({ name: 'submission' }),
	submissions: submissions({ name: 'submissions' }),
	admin: combineReducers({
		form: form({ name: 'admin' }),
		submission: submission({ name: 'admin' }),
		submissions: submissions({ name: 'admin' }),
	}),
	query: queryReducer,
	maintenance: maintenanceReducer,
	pin: pinReducer,
	news: combineReducers({
		form: form({ name: 'news' }),
		submission: submission({ name: 'news' }),
		submissions: submissions({ name: 'news' }),

		// add this state to avoid Unexpected key "sort" found in previous state
		// but I am too lazy to write an extra reducer...
		// sort: '-data.newsdatetime',

		sort: (state = '-data.newsdatetime', action) => state,
		limit: (state = 10, action) => state,
		skip: (state = 0, action) => state,
	}),
});

const rootReducer = (state, action) => {
	if (action.type === 'RESET_REDUX') {
		sessionStorage.removeItem('userData');
		localStorage.removeItem('userData');
		localStorage.removeItem('persist:root');
		localStorage.removeItem('formioAppUser');
		localStorage.removeItem('formioAppRole');

		//
		// Fix Admin sidebar appearance after logout. Solution : keep "auth" state and reset the rest. The USER_LOGOUT action will reset the auth.
		//
		const { auth } = state;
		const ResetState = {
			auth,
			form: undefined,
			forms: undefined,
			submission: undefined,
			submissions: undefined,
			admin: undefined,
			query: undefined,
			maintenance: undefined,
			pin:undefined,
		};
		return appReducer(ResetState, USER_LOGOUT);
	}

	//* ****************************************************************************************************************** */
	// Ok dude, let's write some comments about this shitty Action "UPDATE_ADMIN_USER"
	// Keep in mind that both Admin and FormCreators are Admins and we kepp them under the same Admin formio resource
	//
	// - 1st Why is needed? Cos after editing the credentials of an admin (or formbuilder) user, despite the fact that mongo DB is updated
	// as it should do,  Redux "auth" state does not. Someone has to logout/login to get a fresh copy of the updated record.
	// Also, Formio does not have any retrive data in the middle for the "auth" module. It presupposes that its one fuckin' time job (login).
	// Expirementing with the documented initAuth, setUser & logout of this module, does not seem to serve the workability I wanted
	//
	// - 2nd What's the fuckin' problem with that man ? Are you fuckin' nuts? Hmmm ! Well, let me explain.
	// If state "auth" has not been updated and you try to create a new form then you fucked up. Why?
	// The property details of the form will save the old data instead of the new one.
	//
	// - 3nd Solution ? Send this action message just after the save "edit" submission, ie edit Admin or edit FormBuilder.
	// Now are you happy?
	//
	// G. Papaioannou March 2022
	//* ****************************************************************************************************************** */
	if (action.type === 'UPDATE_ADMIN_USER') {
		state.auth.user.data.agency = action.payload.agency;
		state.auth.user.data.email = action.payload.email;
		state.auth.user.data.name = action.payload.name;
		state.auth.user.data.phonenumber = action.payload.phonenumber;
		state.auth.user.data.selectedAdminRole = action.payload.selectedAdminRole;
	}

	return appReducer(state, action);
};

export default rootReducer;
