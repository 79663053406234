/**********************************************************************************
 * G. Papaioannou
 * g_papaioannou@rocketmail.com
 * Mar, 2022
 *
 * 01-03-2022 Added HTML formatting to messages
 * 20-10-2022 [OnYes] function now returns the AdminRole  as a parameter. Use conditional chaining to
 * 			  return undefine in case of non existing key pair
 *
 *
 ***********************************************************************************/

import React, { Component } from 'react';

export default class extends Component {
	render() {
		const { onYes, onNo, message, yesText = 'Ναι', noText = 'Όχι' } = this.props;

		return (
			<div>
				<h4 dangerouslySetInnerHTML={{ __html: message }}></h4>
				<br />
				<br />
				<div className="btn-toolbar">
					<span onClick={() => onYes(this.props.auth?.user.data.selectedAdminRole)} className="btn btn-danger">
						{yesText}
					</span>
					<span onClick={onNo} className="btn btn-default">
						{noText}
					</span>
				</div>
			</div>
		);
	}
}
