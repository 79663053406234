const updateUserMetaData = (submissionData, userDataLocal) => {

    for(let k in userDataLocal.BasicInfo) {
        if(k in submissionData) {
            submissionData[k] = userDataLocal.BasicInfo[k]
        }
    }
    for(let k in userDataLocal.UnitInfo) {
        if(k in submissionData) {
            submissionData[k] = userDataLocal.UnitInfo[k]
        }
    }
    if (userDataLocal.UnitInfo[0]) {
        for(let k in userDataLocal.UnitInfo[0]) {
            if(k in submissionData) {
                submissionData[k] = userDataLocal.UnitInfo[0][k]
            }
        }
    }
    return submissionData
}
export default updateUserMetaData