import {FormConst} from '../config'

const singleSubmissionValidator = (form) => {

    let singleSubmission

    if (form.properties !== undefined) { 
        if (form.properties.numberOfSubmissions !== undefined) {
            if (form.properties.numberOfSubmissions === FormConst.SINGLE_SUBMISSION) {
                singleSubmission = true
            } else {
                singleSubmission = false
            }
        }
        
    }
    
    return singleSubmission
}


export default singleSubmissionValidator