import React from 'react';

function NoValidUserData() {
	return (
		<div className="alert alert-primary" role="alert">
			<h4>Τα στοιχεία σας είναι ελλιπή και δεν έχετε πρόσβαση στις Φόρμες, </h4>
		</div>
	);
}

export default NoValidUserData;
