export default [{
  "id": 1,
  "value": "ΠΕ01-ΘΕΟΛΟΓΟΙ",
  "label": "ΠΕ01-ΘΕΟΛΟΓΟΙ"
}, {
"id": 2,
  "value": "ΠΕ01.50-ΘΕΟΛΟΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ01.50-ΘΕΟΛΟΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 3,
  "value": "ΠΕ1-ΔΙΟΙΚΗΤΙΚΟΣ ΟΙΚΟΝΟΜΙΚΟΣ",
  "label": "ΠΕ1-ΔΙΟΙΚΗΤΙΚΟΣ ΟΙΚΟΝΟΜΙΚΟΣ"
},{
"id": 4,
  "value": "ΠΕ02-ΦΙΛΟΛΟΓΟΙ",
  "label": "ΠΕ02-ΦΙΛΟΛΟΓΟΙ"
},{
"id": 5,
  "value": "ΠΕ02.50-ΦΙΛΟΛΟΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ02.50-ΦΙΛΟΛΟΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 6,
  "value": "ΠΕ03-ΜΑΘΗΜΑΤΙΚΟΙ",
  "label": "ΠΕ03-ΜΑΘΗΜΑΤΙΚΟΙ"
},{
"id": 7,
  "value": "ΠΕ03.50-ΜΑΘΗΜΑΤΙΚΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ03.50-ΜΑΘΗΜΑΤΙΚΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 8,
  "value": "ΠΕ04.01-ΦΥΣΙΚΟΙ",
  "label": "ΠΕ04.01-ΦΥΣΙΚΟΙ"
},{
"id": 9,
  "value": "ΠΕ04.02-ΧΗΜΙΚΟΙ",
  "label": "ΠΕ04.02-ΧΗΜΙΚΟΙ"
},{
"id": 10,
  "value": "ΠΕ04.03-ΦΥΣΙΟΓΝΩΣΤΕΣ",
  "label": "ΠΕ04.03-ΦΥΣΙΟΓΝΩΣΤΕΣ"
},{
"id": 11,
  "value": "ΠΕ04.04-ΒΙΟΛΟΓΟΙ",
  "label": "ΠΕ04.04-ΒΙΟΛΟΓΟΙ"
},{
"id": 12,
  "value": "ΠΕ04.05-ΓΕΩΛΟΓΟΙ",
  "label": "ΠΕ04.05-ΓΕΩΛΟΓΟΙ"
},{
"id": 13,
  "value": "ΠΕ04.01.50-ΦΥΣΙΚΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ04.01.50-ΦΥΣΙΚΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 14,
  "value": "ΠΕ04.02.50-ΧΗΜΙΚΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ04.02.50-ΧΗΜΙΚΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 15,
  "value": "ΠΕ04.05.50-ΓΕΩΛΟΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ04.05.50-ΓΕΩΛΟΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 16,
  "value": "ΠΕ4-ΠΛΗΡΟΦΟΡΙΚΗΣ (προσωρινός κλάδος)",
  "label": "ΠΕ4-ΠΛΗΡΟΦΟΡΙΚΗΣ (προσωρινός κλάδος)"
},{
"id": 17,
  "value": "ΠΕ05-ΓΑΛΛΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ",
  "label": "ΠΕ05-ΓΑΛΛΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ"
},{
"id": 18,
  "value": "ΠΕ05.50-ΓΑΛΛΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ05.50-ΓΑΛΛΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 19,
  "value": "ΠΕ06-ΑΓΓΛΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ",
  "label": "ΠΕ06-ΑΓΓΛΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ"
},{
"id": 20,
  "value": "ΠΕ06.50-ΑΓΓΛΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ06.50-ΑΓΓΛΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 21,
  "value": "ΠΕ07-ΓΕΡΜΑΝΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ",
  "label": "ΠΕ07-ΓΕΡΜΑΝΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ"
},{
"id": 22,
  "value": "ΠΕ07.50-ΓΕΡΜΑΝΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ07.50-ΓΕΡΜΑΝΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 23,
  "value": "ΠΕ08-ΚΑΛΛΙΤΕΧΝΙΚΩΝ",
  "label": "ΠΕ08-ΚΑΛΛΙΤΕΧΝΙΚΩΝ"
},{
"id": 24,
  "value": "ΠΕ08.50-ΚΑΛΛΙΤΕΧΝΙΚΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ08.50-ΚΑΛΛΙΤΕΧΝΙΚΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 25,
  "value": "ΠΕ8 ΑΡΧΕΙΟΝΟΜΟΣ - ΒΙΒΛΙΟΘΗΚΟΝΟΜΟΣ",
  "label": "ΠΕ8 ΑΡΧΕΙΟΝΟΜΟΣ - ΒΙΒΛΙΟΘΗΚΟΝΟΜΟΣ"
},{
"id": 26,
  "value": "ΠΕ09.50-ΟΙΚΟΝΟΜΟΛΟΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ09.50-ΟΙΚΟΝΟΜΟΛΟΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 27,
  "value": "ΠΕ11-ΦΥΣΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ11-ΦΥΣΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 28,
  "value": "ΠΕ11.01-ΕΙΔΙΚΗΣ ΦΥΣΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ11.01-ΕΙΔΙΚΗΣ ΦΥΣΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 29,
  "value": "ΠΕ21-ΘΕΡΑΠΕΥΤΩΝ ΛΟΓΟΥ",
  "label": "ΠΕ21-ΘΕΡΑΠΕΥΤΩΝ ΛΟΓΟΥ"
},{
"id": 30,
  "value": "ΠΕ22-ΕΠΑΓΓΕΛΜΑΤΙΚΟΙ ΣΥΜΒΟΥΛΟΙ ΑΕΙ",
  "label": "ΠΕ22-ΕΠΑΓΓΕΛΜΑΤΙΚΟΙ ΣΥΜΒΟΥΛΟΙ ΑΕΙ"
},{
"id": 31,
  "value": "ΠΕ23-ΨΥΧΟΛΟΓΟΙ",
  "label": "ΠΕ23-ΨΥΧΟΛΟΓΟΙ"
},{
"id": 32,
  "value": "ΠΕ24-ΠΑΙΔΟΨΥΧΙΑΤΡΟΙ",
  "label": "ΠΕ24-ΠΑΙΔΟΨΥΧΙΑΤΡΟΙ"
},{
"id": 33,
  "value": "ΠΕ25-ΣΧΟΛΙΚΟΙ ΝΟΣΗΛΕΥΤΕΣ",
  "label": "ΠΕ25-ΣΧΟΛΙΚΟΙ ΝΟΣΗΛΕΥΤΕΣ"
},{
"id": 34,
  "value": "ΠΕ28-ΦΥΣΙΟΘΕΡΑΠΕΥΤΕΣ",
  "label": "ΠΕ28-ΦΥΣΙΟΘΕΡΑΠΕΥΤΕΣ"
},{
"id": 35,
  "value": "ΠΕ29-ΕΡΓΟΘΕΡΑΠΕΥΤΕΣ",
  "label": "ΠΕ29-ΕΡΓΟΘΕΡΑΠΕΥΤΕΣ"
},{
"id": 36,
  "value": "ΠΕ30-ΚΟΙΝΩΝΙΚΟΙ ΛΕΙΤΟΥΡΓΟΙ",
  "label": "ΠΕ30-ΚΟΙΝΩΝΙΚΟΙ ΛΕΙΤΟΥΡΓΟΙ"
},{
"id": 37,
  "value": "ΠΕ31ΝΟ-ΝΟΗΜΑΤΙΚΗΣ ΓΛΩΣΣΑΣ ΚΩΦΩΝ",
  "label": "ΠΕ31ΝΟ-ΝΟΗΜΑΤΙΚΗΣ ΓΛΩΣΣΑΣ ΚΩΦΩΝ"
},{
"id": 38,
  "value": "ΠΕ31ΚΠΔ-ΔΕΞΙΟΤΗΤΩΝ ΔΙΑΒΙΩΣΗΣ ΤΥΦΛΩΝ",
  "label": "ΠΕ31ΚΠΔ-ΔΕΞΙΟΤΗΤΩΝ ΔΙΑΒΙΩΣΗΣ ΤΥΦΛΩΝ"
},{
"id": 39,
  "value": "ΠΕ31ΣΕΠΤ-ΕΠΑΓΓΕΛΜΑΤΙΚΟΥ ΠΡΟΣΑΝΑΤΟΛΙΣΜΟΥ ΤΥΦΛΩΝ",
  "label": "ΠΕ31ΣΕΠΤ-ΕΠΑΓΓΕΛΜΑΤΙΚΟΥ ΠΡΟΣΑΝΑΤΟΛΙΣΜΟΥ ΤΥΦΛΩΝ"
},{
"id": 40,
  "value": "ΠΕ33-ΜΕΘΟΔΟΛΟΓΙΑΣ ΙΣΤΟΡΙΑΣ (ΜΙΘΕ)",
  "label": "ΠΕ33-ΜΕΘΟΔΟΛΟΓΙΑΣ ΙΣΤΟΡΙΑΣ (ΜΙΘΕ)"
},{
"id": 41,
  "value": "ΠΕ34-ΙΤΑΛΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ",
  "label": "ΠΕ34-ΙΤΑΛΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ"
},{
"id": 42,
  "value": "ΠΕ35-ΠΑΙΔΟΝΕΥΡΟΛΟΓΟΙ",
  "label": "ΠΕ35-ΠΑΙΔΟΝΕΥΡΟΛΟΓΟΙ"
},{
"id": 43,
  "value": "ΠΕ36-ΜΟΥΣΙΚΟΘΕΡΑΠΕΥΤΕΣ",
  "label": "ΠΕ36-ΜΟΥΣΙΚΟΘΕΡΑΠΕΥΤΕΣ"
},{
"id": 44,
  "value": "ΠΕ40-ΙΣΠΑΝΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ",
  "label": "ΠΕ40-ΙΣΠΑΝΙΚΗΣ ΦΙΛΟΛΟΓΙΑΣ"
},{
"id": 45,
  "value": "ΠΕ41-ΘΕΩΡΙΑΣ ΚΑΙ ΙΣΤΟΡΙΑΣ ΤΗΣ ΤΕΧΝΗΣ",
  "label": "ΠΕ41-ΘΕΩΡΙΑΣ ΚΑΙ ΙΣΤΟΡΙΑΣ ΤΗΣ ΤΕΧΝΗΣ"
},{
"id": 46,
  "value": "ΠΕ60-ΝΗΠΙΑΓΩΓΟΙ",
  "label": "ΠΕ60-ΝΗΠΙΑΓΩΓΟΙ"
},{
"id": 47,
  "value": "ΠΕ60.50-ΝΗΠΙΑΓΩΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ60.50-ΝΗΠΙΑΓΩΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 48,
  "value": "ΠΕ61-ΝΗΠΙΑΓΩΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ61-ΝΗΠΙΑΓΩΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 49,
  "value": "ΠΕ70-ΔΑΣΚΑΛΟΙ",
  "label": "ΠΕ70-ΔΑΣΚΑΛΟΙ"
},{
"id": 50,
  "value": "ΠΕ70.50-ΔΑΣΚΑΛΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ70.50-ΔΑΣΚΑΛΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 51,
  "value": "ΠΕ71-ΔΑΣΚΑΛΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ71-ΔΑΣΚΑΛΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 52,
  "value": "ΠΕ73-ΔΑΣΚΑΛΟΙ ΜΕΙΟΝΟΤΙΚΟΥ ΠΡΟΓΡΑΜΜΑΤΟΣ",
  "label": "ΠΕ73-ΔΑΣΚΑΛΟΙ ΜΕΙΟΝΟΤΙΚΟΥ ΠΡΟΓΡΑΜΜΑΤΟΣ"
},{
"id": 53,
  "value": "ΠΕ78-ΚΟΙΝΩΝΙΚΩΝ ΕΠΙΣΤΗΜΩΝ",
  "label": "ΠΕ78-ΚΟΙΝΩΝΙΚΩΝ ΕΠΙΣΤΗΜΩΝ"
},{
"id": 54,
  "value": "ΠΕ78.50-ΚΟΙΝΩΝΙΚΩΝ ΕΠΙΣΤΗΜΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ78.50-ΚΟΙΝΩΝΙΚΩΝ ΕΠΙΣΤΗΜΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 55,
  "value": "ΠΕ79.01-ΜΟΥΣΙΚΗΣ ΕΠΙΣΤΗΜΗΣ",
  "label": "ΠΕ79.01-ΜΟΥΣΙΚΗΣ ΕΠΙΣΤΗΜΗΣ"
},{
"id": 56,
  "value": "ΠΕ79.02-ΤΕΧΝΟΛΟΓΟΙ ΜΟΥΣΙΚΗΣ ΤΕΧΝΟΛΟΓΙΑΣ, ΗΧΟΥ ΚΑΙ ΜΟΥΣΙΚΩΝ ΟΡΓΑΝΩΝ",
  "label": "ΠΕ79.02-ΤΕΧΝΟΛΟΓΟΙ ΜΟΥΣΙΚΗΣ ΤΕΧΝΟΛΟΓΙΑΣ, ΗΧΟΥ ΚΑΙ ΜΟΥΣΙΚΩΝ ΟΡΓΑΝΩΝ"
},{
"id": 57,
  "value": "ΠΕ79.01.50-ΜΟΥΣΙΚΗΣ ΕΠΙΣΤΗΜΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ79.01.50-ΜΟΥΣΙΚΗΣ ΕΠΙΣΤΗΜΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 58,
  "value": "ΠΕ79.02.50-ΤΕΧΝΟΛΟΓΟΙ ΜΟΥΣΙΚΗΣ ΤΕΧΝΟΛΟΓΙΑΣ, ΗΧΟΥ ΚΑΙ ΜΟΥΣΙΚΩΝ ΟΡΓΑΝΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ79.02.50-ΤΕΧΝΟΛΟΓΟΙ ΜΟΥΣΙΚΗΣ ΤΕΧΝΟΛΟΓΙΑΣ, ΗΧΟΥ ΚΑΙ ΜΟΥΣΙΚΩΝ ΟΡΓΑΝΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 59,
  "value": "ΠΕ80-ΟΙΚΟΝΟΜΙΑΣ",
  "label": "ΠΕ80-ΟΙΚΟΝΟΜΙΑΣ"
},{
"id": 60,
  "value": "ΠΕ80.50-ΟΙΚΟΝΟΜΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ80.50-ΟΙΚΟΝΟΜΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 61,
  "value": "ΠΕ81-ΠΟΛ.ΜΗΧΑΝΙΚΩΝ-ΑΡΧΙΤΕΚΤΟΝΩΝ",
  "label": "ΠΕ81-ΠΟΛ.ΜΗΧΑΝΙΚΩΝ-ΑΡΧΙΤΕΚΤΟΝΩΝ"
},{
"id": 62,
  "value": "ΠΕ81.50-ΠΟΛ.ΜΗΧΑΝΙΚΩΝ-ΑΡΧΙΤΕΚΤΟΝΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ81.50-ΠΟΛ.ΜΗΧΑΝΙΚΩΝ-ΑΡΧΙΤΕΚΤΟΝΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 63,
  "value": "ΠΕ82-ΜΗΧΑΝΟΛΟΓΩΝ",
  "label": "ΠΕ82-ΜΗΧΑΝΟΛΟΓΩΝ"
},{
"id": 64,
  "value": "ΠΕ82.50-ΜΗΧΑΝΟΛΟΓΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ82.50-ΜΗΧΑΝΟΛΟΓΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 65,
  "value": "ΠΕ83-ΗΛΕΚΤΡΟΛΟΓΩΝ",
  "label": "ΠΕ83-ΗΛΕΚΤΡΟΛΟΓΩΝ"
},{
"id": 66,
  "value": "ΠΕ83.50-ΗΛΕΚΤΡΟΛΟΓΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ83.50-ΗΛΕΚΤΡΟΛΟΓΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 67,
  "value": "ΠΕ84-ΗΛΕΚΤΡΟΝΙΚΩΝ",
  "label": "ΠΕ84-ΗΛΕΚΤΡΟΝΙΚΩΝ"
},{
"id": 68,
  "value": "ΠΕ84.50-ΗΛΕΚΤΡΟΝΙΚΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ84.50-ΗΛΕΚΤΡΟΝΙΚΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 69,
  "value": "ΠΕ85-ΧΗΜΙΚΩΝ ΜΗΧΑΝΙΚΩΝ",
  "label": "ΠΕ85-ΧΗΜΙΚΩΝ ΜΗΧΑΝΙΚΩΝ"
},{
"id": 70,
  "value": "ΠΕ85.50-ΧΗΜΙΚΩΝ ΜΗΧΑΝΙΚΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ85.50-ΧΗΜΙΚΩΝ ΜΗΧΑΝΙΚΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 71,
  "value": "ΠΕ86-ΠΛΗΡΟΦΟΡΙΚΗΣ",
  "label": "ΠΕ86-ΠΛΗΡΟΦΟΡΙΚΗΣ"
},{
"id": 72,
  "value": "ΠΕ86.50-ΠΛΗΡΟΦΟΡΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ86.50-ΠΛΗΡΟΦΟΡΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 73,
  "value": "ΠΕ87.01-ΙΑΤΡΙΚΗΣ",
  "label": "ΠΕ87.01-ΙΑΤΡΙΚΗΣ"
},{
"id": 74,
  "value": "ΠΕ87.02-ΝΟΣΗΛΕΥΤΙΚΗΣ",
  "label": "ΠΕ87.02-ΝΟΣΗΛΕΥΤΙΚΗΣ"
},{
"id": 75,
  "value": "ΠΕ87.03-ΑΙΣΘΗΤΙΚΗΣ",
  "label": "ΠΕ87.03-ΑΙΣΘΗΤΙΚΗΣ"
},{
"id": 76,
  "value": "ΠΕ87.04-ΙΑΤΡΙΚΩΝ ΕΡΓΑΣΤΗΡΙΩΝ",
  "label": "ΠΕ87.04-ΙΑΤΡΙΚΩΝ ΕΡΓΑΣΤΗΡΙΩΝ"
},{
"id": 77,
  "value": "ΠΕ87.05-ΟΔΟΝΤΟΤΕΧΝΙΚΗΣ",
  "label": "ΠΕ87.05-ΟΔΟΝΤΟΤΕΧΝΙΚΗΣ"
},{
"id": 78,
  "value": "ΠΕ87.06-ΚΟΙΝΩΝΙΚΗΣ ΕΡΓΑΣΙΑΣ",
  "label": "ΠΕ87.06-ΚΟΙΝΩΝΙΚΗΣ ΕΡΓΑΣΙΑΣ"
},{
"id": 79,
  "value": "ΠΕ87.07-ΡΑΔΙΟΛΟΓΙΑΣ-ΑΚΤΙΝΟΛΟΓΙΑΣ",
  "label": "ΠΕ87.07-ΡΑΔΙΟΛΟΓΙΑΣ-ΑΚΤΙΝΟΛΟΓΙΑΣ"
},{
"id": 80,
  "value": "ΠΕ87.08-ΦΥΣΙΟΘΕΡΑΠΕΙΑΣ",
  "label": "ΠΕ87.08-ΦΥΣΙΟΘΕΡΑΠΕΙΑΣ"
},{
"id": 81,
  "value": "ΠΕ87.09-ΒΡΕΦΟΝΗΠΙΟΚΟΜΩΝ",
  "label": "ΠΕ87.09-ΒΡΕΦΟΝΗΠΙΟΚΟΜΩΝ"
},{
"id": 82,
  "value": "ΠΕ87.10-ΔΗΜΟΣΙΑΣ ΥΓΙΕΙΝΗΣ",
  "label": "ΠΕ87.10-ΔΗΜΟΣΙΑΣ ΥΓΙΕΙΝΗΣ"
},{
"id": 83,
  "value": "ΠΕ87.01.50-ΙΑΤΡΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ87.01.50-ΙΑΤΡΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 84,
  "value": "ΠΕ87.02.50-ΝΟΣΗΛΕΥΤΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ87.02.50-ΝΟΣΗΛΕΥΤΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 85,
  "value": "ΠΕ87.03.50-ΑΙΣΘΗΤΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ87.03.50-ΑΙΣΘΗΤΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 86,
  "value": "ΠΕ87.04.50-ΙΑΤΡΙΚΩΝ ΕΡΓΑΣΤΗΡΙΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ87.04.50-ΙΑΤΡΙΚΩΝ ΕΡΓΑΣΤΗΡΙΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 87,
  "value": "ΠΕ87.05.50-ΟΔΟΝΤΟΤΕΧΝΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ87.05.50-ΟΔΟΝΤΟΤΕΧΝΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 88,
  "value": "ΠΕ87.06.50-ΚΟΙΝΩΝΙΚΗΣ ΕΡΓΑΣΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ87.06.50-ΚΟΙΝΩΝΙΚΗΣ ΕΡΓΑΣΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 89,
  "value": "ΠΕ87.07.50-ΡΑΔΙΟΛΟΓΙΑΣ-ΑΚΤΙΝΟΛΟΓΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ87.07.50-ΡΑΔΙΟΛΟΓΙΑΣ-ΑΚΤΙΝΟΛΟΓΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 90,
  "value": "ΠΕ87.08.50-ΦΥΣΙΟΘΕΡΑΠΕΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ87.08.50-ΦΥΣΙΟΘΕΡΑΠΕΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 91,
  "value": "ΠΕ87.09.50-ΒΡΕΦΟΝΗΠΙΟΚΟΜΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ87.09.50-ΒΡΕΦΟΝΗΠΙΟΚΟΜΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 92,
  "value": "ΠΕ87.10.50-ΔΗΜΟΣΙΑΣ ΥΓΙΕΙΝΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ87.10.50-ΔΗΜΟΣΙΑΣ ΥΓΙΕΙΝΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 93,
  "value": "ΠΕ88.01-ΓΕΩΠΟΝΟΙ",
  "label": "ΠΕ88.01-ΓΕΩΠΟΝΟΙ"
},{
"id": 94,
  "value": "ΠΕ88.02-ΦΥΤΙΚΗΣ ΠΑΡΑΓΩΓΗΣ",
  "label": "ΠΕ88.02-ΦΥΤΙΚΗΣ ΠΑΡΑΓΩΓΗΣ"
},{
"id": 95,
  "value": "ΠΕ88.03-ΖΩΙΚΗΣ ΠΑΡΑΓΩΓΗΣ",
  "label": "ΠΕ88.03-ΖΩΙΚΗΣ ΠΑΡΑΓΩΓΗΣ"
},{
"id": 96,
  "value": "ΠΕ88.04-ΔΙΑΤΡΟΦΗΣ",
  "label": "ΠΕ88.04-ΔΙΑΤΡΟΦΗΣ"
},{
"id": 97,
  "value": "ΠΕ88.05-ΦΥΣΙΚΟΥ ΠΕΡΙΒΑΛΛΟΝΤΟΣ",
  "label": "ΠΕ88.05-ΦΥΣΙΚΟΥ ΠΕΡΙΒΑΛΛΟΝΤΟΣ"
},{
"id": 98,
  "value": "ΠΕ88.01.50-ΓΕΩΠΟΝΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ88.01.50-ΓΕΩΠΟΝΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 99,
  "value": "ΠΕ88.02.50-ΦΥΤΙΚΗΣ ΠΑΡΑΓΩΓΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ88.02.50-ΦΥΤΙΚΗΣ ΠΑΡΑΓΩΓΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 100,
  "value": "ΠΕ88.03.50-ΖΩΙΚΗΣ ΠΑΡΑΓΩΓΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ88.03.50-ΖΩΙΚΗΣ ΠΑΡΑΓΩΓΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 101,
  "value": "ΠΕ88.04.50-ΔΙΑΤΡΟΦΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ88.04.50-ΔΙΑΤΡΟΦΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 102,
  "value": "ΠΕ88.05.50-ΦΥΣΙΚΟΥ ΠΕΡΙΒΑΛΛΟΝΤΟΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ88.05.50-ΦΥΣΙΚΟΥ ΠΕΡΙΒΑΛΛΟΝΤΟΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 103,
  "value": "ΠΕ89.01-ΚΑΛΛΙΤΕΧΝΙΚΩΝ ΣΠΟΥΔΩΝ",
  "label": "ΠΕ89.01-ΚΑΛΛΙΤΕΧΝΙΚΩΝ ΣΠΟΥΔΩΝ"
},{
"id": 104,
  "value": "ΠΕ89.02-ΣΧΕΔΙΑΣΜΟΥ ΚΑΙ ΠΑΡΑΓΩΓΗΣ ΠΡΟΪΟΝΤΩΝ",
  "label": "ΠΕ89.02-ΣΧΕΔΙΑΣΜΟΥ ΚΑΙ ΠΑΡΑΓΩΓΗΣ ΠΡΟΪΟΝΤΩΝ"
},{
"id": 105,
  "value": "ΠΕ89.01.50-ΚΑΛΛΙΤΕΧΝΙΚΩΝ ΣΠΟΥΔΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ89.01.50-ΚΑΛΛΙΤΕΧΝΙΚΩΝ ΣΠΟΥΔΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 106,
  "value": "ΠΕ89.02.50-ΣΧΕΔΙΑΣΜΟΥ ΚΑΙ ΠΑΡΑΓΩΓΗΣ ΠΡΟΪΟΝΤΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ89.02.50-ΣΧΕΔΙΑΣΜΟΥ ΚΑΙ ΠΑΡΑΓΩΓΗΣ ΠΡΟΪΟΝΤΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 107,
  "value": "ΠΕ90-ΝΑΥΤΙΚΩΝ ΜΑΘΗΜΑΤΩΝ",
  "label": "ΠΕ90-ΝΑΥΤΙΚΩΝ ΜΑΘΗΜΑΤΩΝ"
},{
"id": 108,
  "value": "ΠΕ90.50-ΝΑΥΤΙΚΩΝ ΜΑΘΗΜΑΤΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ90.50-ΝΑΥΤΙΚΩΝ ΜΑΘΗΜΑΤΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 109,
  "value": "ΠΕ91.01-ΘΕΑΤΡΙΚΩΝ ΣΠΟΥΔΩΝ",
  "label": "ΠΕ91.01-ΘΕΑΤΡΙΚΩΝ ΣΠΟΥΔΩΝ"
},{
"id": 110,
  "value": "ΠΕ91.02-ΔΡΑΜΑΤΙΚΗΣ ΤΕΧΝΗΣ",
  "label": "ΠΕ91.02-ΔΡΑΜΑΤΙΚΗΣ ΤΕΧΝΗΣ"
},{
"id": 111,
  "value": "ΠΕ91.01.50-ΘΕΑΤΡΙΚΩΝ ΣΠΟΥΔΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ91.01.50-ΘΕΑΤΡΙΚΩΝ ΣΠΟΥΔΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 112,
  "value": "ΠΕ91.02.50-ΔΡΑΜΑΤΙΚΗΣ ΤΕΧΝΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΠΕ91.02.50-ΔΡΑΜΑΤΙΚΗΣ ΤΕΧΝΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 113,
  "value": "ΤΕ01.04-ΨΥΚΤΙΚΟΙ",
  "label": "ΤΕ01.04-ΨΥΚΤΙΚΟΙ"
},{
"id": 114,
  "value": "ΤΕ01.06-ΗΛΕΚΤΡΟΛΟΓΟΙ",
  "label": "ΤΕ01.06-ΗΛΕΚΤΡΟΛΟΓΟΙ"
},{
"id": 115,
  "value": "ΤΕ01.07-ΗΛΕΚΤΡΟΝΙΚΟΙ",
  "label": "ΤΕ01.07-ΗΛΕΚΤΡΟΝΙΚΟΙ"
},{
"id": 116,
  "value": "ΤΕ01.13-ΠΡΟΓΡΑΜΜΑΤΙΣΤΕΣ Η/Υ",
  "label": "ΤΕ01.13-ΠΡΟΓΡΑΜΜΑΤΙΣΤΕΣ Η/Υ"
},{
"id": 117,
  "value": "ΤΕ01.19-ΚΟΜΜΩΤΙΚΗΣ",
  "label": "ΤΕ01.19-ΚΟΜΜΩΤΙΚΗΣ"
},{
"id": 118,
  "value": "ΤΕ01.20-ΑΙΣΘΗΤΙΚΗΣ",
  "label": "ΤΕ01.20-ΑΙΣΘΗΤΙΚΗΣ"
},{
"id": 119,
  "value": "ΤΕ01.25-ΑΡΓΥΡΟΧΡΥΣΟΧΟΪΑΣ",
  "label": "ΤΕ01.25-ΑΡΓΥΡΟΧΡΥΣΟΧΟΪΑΣ"
},{
"id": 120,
  "value": "ΤΕ01.26-ΟΔΟΝΤΟΤΕΧΝΙΚΗΣ",
  "label": "ΤΕ01.26-ΟΔΟΝΤΟΤΕΧΝΙΚΗΣ"
},{
"id": 121,
  "value": "ΤΕ01.29-ΒΟΗΘ. ΙΑΤΡ.ΚΑΙ ΒΙΟΛΟΓ. ΕΡΓΑΣΤΗΡΙΩΝ",
  "label": "ΤΕ01.29-ΒΟΗΘ. ΙΑΤΡ.ΚΑΙ ΒΙΟΛΟΓ. ΕΡΓΑΣΤΗΡΙΩΝ"
},{
"id": 122,
  "value": "ΤΕ01.30-ΒΟΗΘ. ΒΡΕΦΟΚΟΜΟΙ-ΠΑΙΔΟΚΟΜΟΙ",
  "label": "ΤΕ01.30-ΒΟΗΘ. ΒΡΕΦΟΚΟΜΟΙ-ΠΑΙΔΟΚΟΜΟΙ"
},{
"id": 123,
  "value": "ΤΕ01.31-ΧΕΙΡΙΣΤΕΣ ΙΑΤΡΙΚΩΝ ΣΥΣΚΕΥΩΝ",
  "label": "ΤΕ01.31-ΧΕΙΡΙΣΤΕΣ ΙΑΤΡΙΚΩΝ ΣΥΣΚΕΥΩΝ"
},{
"id": 124,
  "value": "ΤΕ01.13.50-ΠΡΟΓΡΑΜΜΑΤΙΣΤΕΣ Η/Υ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ01.13.50-ΠΡΟΓΡΑΜΜΑΤΙΣΤΕΣ Η/Υ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 125,
  "value": "ΤΕ01.19.50-ΚΟΜΜΩΤΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ01.19.50-ΚΟΜΜΩΤΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 126,
  "value": "ΤΕ01.20.50-ΑΙΣΘΗΤΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ01.20.50-ΑΙΣΘΗΤΙΚΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 127,
  "value": "ΤΕ02.01-ΣΧΕΔΙΑΣΤΕΣ-ΔΟΜΙΚΟΙ",
  "label": "ΤΕ02.01-ΣΧΕΔΙΑΣΤΕΣ-ΔΟΜΙΚΟΙ"
},{
"id": 128,
  "value": "ΤΕ02.02-ΜΗΧΑΝΟΛΟΓΟΙ",
  "label": "ΤΕ02.02-ΜΗΧΑΝΟΛΟΓΟΙ"
},{
"id": 129,
  "value": "ΤΕ02.03-ΧΗΜΙΚΟΙ ΕΡΓΑΣΤΗΡΙΩΝ",
  "label": "ΤΕ02.03-ΧΗΜΙΚΟΙ ΕΡΓΑΣΤΗΡΙΩΝ"
},{
"id": 130,
  "value": "ΤΕ02.04-ΟΙΚΟΝΟΜΙΑΣ-ΔΙΟΙΚΗΣΗΣ",
  "label": "ΤΕ02.04-ΟΙΚΟΝΟΜΙΑΣ-ΔΙΟΙΚΗΣΗΣ"
},{
"id": 131,
  "value": "ΤΕ02.05-ΕΦΑΡΜΟΣΜΕΝΩΝ ΤΕΧΝΩΝ",
  "label": "ΤΕ02.05-ΕΦΑΡΜΟΣΜΕΝΩΝ ΤΕΧΝΩΝ"
},{
"id": 132,
  "value": "ΤΕ02.06-ΣΧΕΔΙΑΣΜΟΥ ΚΑΙ ΠΑΡΑΓΩΓΗΣ ΠΡΟΪΟΝΤΩΝ",
  "label": "ΤΕ02.06-ΣΧΕΔΙΑΣΜΟΥ ΚΑΙ ΠΑΡΑΓΩΓΗΣ ΠΡΟΪΟΝΤΩΝ"
},{
"id": 133,
  "value": "ΤΕ02.07-ΓΕΩΠΟΝΙΑΣ",
  "label": "ΤΕ02.07-ΓΕΩΠΟΝΙΑΣ"
},{
"id": 134,
  "value": "ΤΕ02.01.50-ΣΧΕΔΙΑΣΤΕΣ-ΔΟΜΙΚΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ02.01.50-ΣΧΕΔΙΑΣΤΕΣ-ΔΟΜΙΚΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 135,
  "value": "ΤΕ02.02.50-ΜΗΧΑΝΟΛΟΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ02.02.50-ΜΗΧΑΝΟΛΟΓΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 136,
  "value": "ΤΕ02.03.50-ΧΗΜΙΚΟΙ ΕΡΓΑΣΤΗΡΙΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ02.03.50-ΧΗΜΙΚΟΙ ΕΡΓΑΣΤΗΡΙΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 137,
  "value": "ΤΕ02.04.50-ΟΙΚΟΝΟΜΙΑΣ-ΔΙΟΙΚΗΣΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ02.04.50-ΟΙΚΟΝΟΜΙΑΣ-ΔΙΟΙΚΗΣΗΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 138,
  "value": "ΤΕ02.05.50-ΕΦΑΡΜΟΣΜΕΝΩΝ ΤΕΧΝΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ02.05.50-ΕΦΑΡΜΟΣΜΕΝΩΝ ΤΕΧΝΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 139,
  "value": "ΤΕ02.06.50-ΣΧΕΔΙΑΣΜΟΥ ΚΑΙ ΠΑΡΑΓΩΓΗΣ ΠΡΟΪΟΝΤΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ02.06.50-ΣΧΕΔΙΑΣΜΟΥ ΚΑΙ ΠΑΡΑΓΩΓΗΣ ΠΡΟΪΟΝΤΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 140,
  "value": "ΤΕ02.07.50-ΓΕΩΠΟΝΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ02.07.50-ΓΕΩΠΟΝΙΑΣ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 141,
  "value": "ΤΕ1-ΤΕ Διοικητικός-Λογιστικός",
  "label": "ΤΕ1-ΤΕ Διοικητικός-Λογιστικός"
},{
"id": 142,
  "value": "ΤΕ4-ΤΕ Πληροφορικής",
  "label": "ΤΕ4-ΤΕ Πληροφορικής"
},{
"id": 143,
  "value": "ΤΕ5-ΤΕ Βιβλιοθηκονόμων",
  "label": "ΤΕ5-ΤΕ Βιβλιοθηκονόμων"
},{
"id": 144,
  "value": "ΤΕ7-ΚΟΙΝΩΝΙΚΟΙ ΛΕΙΤΟΥΡΓΟ",
  "label": "ΤΕ7-ΚΟΙΝΩΝΙΚΟΙ ΛΕΙΤΟΥΡΓΟ"
},{
"id": 145,
  "value": "ΤΕ16-ΜΟΥΣΙΚΗΣ ΜΗ ΑΝΩΤΑΤΩΝ ΙΔΡΥΜΑΤΩΝ",
  "label": "ΤΕ16-ΜΟΥΣΙΚΗΣ ΜΗ ΑΝΩΤΑΤΩΝ ΙΔΡΥΜΑΤΩΝ"
},{
"id": 146,
  "value": "ΤΕ16.00.50-ΜΟΥΣΙΚΗΣ ΜΗ ΑΝΩΤΑΤΩΝ ΙΔΡΥΜΑΤΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΤΕ16.00.50-ΜΟΥΣΙΚΗΣ ΜΗ ΑΝΩΤΑΤΩΝ ΙΔΡΥΜΑΤΩΝ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 147,
  "value": "ΔΕ01.05-ΟΙΚΟΔΟΜΟΙ",
  "label": "ΔΕ01.05-ΟΙΚΟΔΟΜΟΙ"
},{
"id": 148,
  "value": "ΔΕ01.13-ΞΥΛΟΥΡΓΟΙ",
  "label": "ΔΕ01.13-ΞΥΛΟΥΡΓΟΙ"
},{
"id": 149,
  "value": "ΔΕ01.14-ΚΟΠΤΙΚΗΣ-ΡΑΠΤΙΚΗΣ",
  "label": "ΔΕ01.14-ΚΟΠΤΙΚΗΣ-ΡΑΠΤΙΚΗΣ"
},{
"id": 150,
  "value": "ΔΕ01.15-ΑΡΓΥΡΟΧΡΥΣΟΧΟΪΑΣ",
  "label": "ΔΕ01.15-ΑΡΓΥΡΟΧΡΥΣΟΧΟΪΑΣ"
},{
"id": 151,
  "value": "ΔΕ01.17-ΚΟΜΜΩΤΙΚΗΣ",
  "label": "ΔΕ01.17-ΚΟΜΜΩΤΙΚΗΣ"
},{
"id": 152,
  "value": "ΔΕ1-Διοικητικών-Γραμματέων",
  "label": "ΔΕ1-Διοικητικών-Γραμματέων"
},{
"id": 153,
  "value": "ΔΕ1 ΕΒΠ-ΒΟΗΘΗΤΙΚΟ ΠΡΟΣΩΠΙΚΟ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΔΕ1 ΕΒΠ-ΒΟΗΘΗΤΙΚΟ ΠΡΟΣΩΠΙΚΟ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 154,
  "value": "ΔΕ02.02-ΜΗΧΑΝΟΛΟΓΟΙ",
  "label": "ΔΕ02.02-ΜΗΧΑΝΟΛΟΓΟΙ"
},{
"id": 155,
  "value": "ΔΕ02.01-ΗΛΕΚΤΡΟΛΟΓΟΙ-ΗΛΕΚΤΡΟΝΙΚΟΙ",
  "label": "ΔΕ02.01-ΗΛΕΚΤΡΟΛΟΓΟΙ-ΗΛΕΚΤΡΟΝΙΚΟΙ"
},{
"id": 156,
  "value": "ΔΕ02.01.50-ΗΛΕΚΤΡΟΛΟΓΟΙ-ΗΛΕΚΤΡΟΝΙΚΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ",
  "label": "ΔΕ02.01.50-ΗΛΕΚΤΡΟΛΟΓΟΙ-ΗΛΕΚΤΡΟΝΙΚΟΙ ΕΙΔΙΚΗΣ ΑΓΩΓΗΣ"
},{
"id": 157,
  "value": "ΔΕ4-Προσωπικού Ηλεκτρονικών Υπολογιστών",
  "label": "ΔΕ4-Προσωπικού Ηλεκτρονικών Υπολογιστών"
},{
"id": 158,
  "value": "ΔΕ5-ΔΕ ΤΕΧΝΙΚΩΝ",
  "label": "ΔΕ5-ΔΕ ΤΕΧΝΙΚΩΝ"
},{
"id": 159,
  "value": "ΔΕ8-ΔΕ ΟΔΗΓΩΝ",
  "label": "ΔΕ8-ΔΕ ΟΔΗΓΩΝ"
},{
"id": 160,
  "value": "ΔΕ9-ΔΕ ΣΥΝΟΔΩΝ",
  "label": "ΔΕ9-ΔΕ ΣΥΝΟΔΩΝ"
},{
"id": 161,
  "value": "ΔΕ-ΙΔΚ-ΙΕΡΟΔΙΔΑΣΚΑΛΟΙ ΚΑΘΟΛΙΚΟΥ ΔΟΓΜΑΤΟΣ",
  "label": "ΔΕ-ΙΔΚ-ΙΕΡΟΔΙΔΑΣΚΑΛΟΙ ΚΑΘΟΛΙΚΟΥ ΔΟΓΜΑΤΟΣ"
},{
"id": 162,
  "value": "ΘΚ-ΚΙΝΗΣΗ-ΘΚ-ΚΙΝΗΣΗ",
  "label": "ΘΚ-ΚΙΝΗΣΗ-ΘΚ-ΚΙΝΗΣΗ"
},{
"id": 163,
  "value": "ΘΚ-ΣΚΗΝΟΘΕΤΗΣ-ΘΚ-ΣΚΗΝΟΘΕΤΗΣ",
  "label": "ΘΚ-ΣΚΗΝΟΘΕΤΗΣ-ΘΚ-ΣΚΗΝΟΘΕΤΗΣ"
},{
"id": 164,
  "value": "ΕΜ16.00-ΜΟΥΣΙΚΗΣ - ΕΜΠΕΙΡΟΤΕΧΝΕΣ",
  "label": "ΕΜ16.00-ΜΟΥΣΙΚΗΣ - ΕΜΠΕΙΡΟΤΕΧΝΕΣ"
},{
"id": 165,
  "value": "ΥΕ2-ΥΕ Κλητήρων-Επιστατών-Φυλάκων-Νυχτοφυλάκων",
  "label": "ΥΕ2-ΥΕ Κλητήρων-Επιστατών-Φυλάκων-Νυχτοφυλάκων"
},{
"id": 166,
  "value": "ΥΕ0-ΥΕ Καθαριστριών",
  "label": "ΥΕ0-ΥΕ Καθαριστριών"
},{
"id": 167,
  "value": "ΑΛΛΗ - ΕΚΠΑΙΔΕΥΤΙΚΟΥ ΙΕΚ",
  "label": "ΑΛΛΗ - ΕΚΠΑΙΔΕΥΤΙΚΟΥ ΙΕΚ"
},{
"id": 168,
  "value": "ΙΕΡΟΔΙΔ-ΙΕΡΟΔΙΔΑΣΚΑΛΟΙ",
  "label": "ΙΕΡΟΔΙΔ-ΙΕΡΟΔΙΔΑΣΚΑΛΟΙ"
},{
"id": 169,
  "value": "ΧΟΡΟΣ-ΚΛΑΣΣΙΚΟΣ-ΧΟΡΟΣ-ΚΛΑΣΣΙΚΟΣ",
  "label": "ΧΟΡΟΣ-ΚΛΑΣΣΙΚΟΣ-ΧΟΡΟΣ-ΚΛΑΣΣΙΚΟΣ"
},{
"id": 170,
  "value": "ΧΟΡΟΣ-ΣΥΓΧΡΟΝΟΣ-ΧΟΡΟΣ-ΣΥΓΧΡΟΝΟΣ",
  "label": "ΧΟΡΟΣ-ΣΥΓΧΡΟΝΟΣ-ΧΟΡΟΣ-ΣΥΓΧΡΟΝΟΣ"
}
]
