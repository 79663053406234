import jsPDF from 'jspdf'
import asyncFetch from '../../../../../helpers/helperFunctions';
import { AppConfig, ExportConst } from '../../../../../config'
import logoImage from './logoForExportPDF/newLogo'
import cleanDataBeforeSubmission from '../../../../../helpers/cleanDataBeforeSubmission'

//import { object } from 'prop-types';

const exportSubmissionDataPDF = async (submission) => {

	const url = `${AppConfig.apiUrl}/form/${submission.form}`

	const form = await asyncFetch({
		url,
		method: 'GET',
	});

	//ID, DATE, TIME
	const submissionID = submission._id

	const submissionDateAndTime = submission.modified
	const theDateAndTime = new Date(submissionDateAndTime)

	const strDate = `${theDateAndTime.getDate()}/${theDateAndTime.getMonth() + 1}/${theDateAndTime.getFullYear()}`
	const strTime = `${(theDateAndTime.getHours() < 10 ? '0' : '') + theDateAndTime.getHours()}:${theDateAndTime.getMinutes() < 10 ? '0' : ''}${theDateAndTime.getMinutes()}`

	//USER EMAIL
	const userEmail = submission.data.userEmail

	function footer(doc) {
		const pageHeight = doc.internal.pageSize.height;
		let pageCount = doc.internal.getNumberOfPages();

		for (var i = 1; i <= pageCount; i++) {
			// Go to page i
			doc.setPage(i);
			// Print Page i of N
			doc.text('Σελίδα ' + String(i) + ' από ' + String(pageCount), 100, pageHeight - 20, { align: "center" })
		}
	}

	function submissionsToArray(submission) {

		var arrayLabels = []
		var objKeyLabelPairs = {}

		var pair = {}
		var theForm = form
		//console.log(theForm.properties.gsis)

		for (var i = 0; i < theForm.components.length; i++) {
			// console.log('TSAK ', theForm.components[i])
			var exportLabel = ''
			theForm.components[i].hasOwnProperty('title') ? exportLabel = theForm.components[i].title : exportLabel = theForm.components[i].label
			//thelo to input na einai true
			if (theForm.components[i].input) {
				// console.log('TSAK 1 ', theForm.components[i])

				arrayLabels.push(exportLabel)
				pair[theForm.components[i].key] = exportLabel
				// arrayLabels.push(theForm.components[i].label)
				// pair[theForm.components[i].key] = theForm.components[i].label
				objKeyLabelPairs = { ...objKeyLabelPairs, ...pair }

			}
			// gia ta components poy to input einai false
			else {
				//CASE FIELDS IN COLUMNS
				if (theForm.components[i].hasOwnProperty("columns")) {
					//console.log("columns ", theForm.components[i])
					for (let j = 0; j < theForm.components[i].columns.length; j++) {
						if (theForm.components[i].columns[j].hasOwnProperty('components')) {
							for (let k = 0; k < theForm.components[i].columns[j].components.length; k++) {
								arrayLabels.push(exportLabel + '_' + theForm.components[i].columns[j].components[k].label)
								pair[theForm.components[i].columns[j].components[k].key] = exportLabel + '_' + theForm.components[i].columns[j].components[k].label
								objKeyLabelPairs = { ...objKeyLabelPairs, ...pair }
							}
						} else {
							arrayLabels.push(exportLabel + '_' + theForm.components[i].columns[j].label)
							pair[theForm.components[i].columns[j].key] = exportLabel + '_' + theForm.components[i].columns[j].label
							objKeyLabelPairs = { ...objKeyLabelPairs, ...pair }
						}
					}
				} //CASE FIELDS IN COLUMNS

				if (theForm.components[i].hasOwnProperty('components')) {
					//  console.log('TSAK 2: ', theForm.components[i])
					for (var j = 0; j < theForm.components[i].components.length; j++) {
						
						//CASE FIELDS IN COLUMNS INCLUDED IN PANEL
						if (theForm.components[i].components[j].hasOwnProperty("columns")) {
							//console.log("columns ", theForm.components[i].components[j])
							for (let z = 0; z < theForm.components[i].components[j].columns.length; z++) {
								if (theForm.components[i].components[j].columns[z].hasOwnProperty('components')) {
									for (let k = 0; k < theForm.components[i].components[j].columns[z].components.length; k++) {
										arrayLabels.push(exportLabel + '_' + theForm.components[i].components[j].columns[z].components[k].label)
										pair[theForm.components[i].components[j].columns[z].components[k].key] = exportLabel + '_' + theForm.components[i].components[j].columns[z].components[k].label
										objKeyLabelPairs = { ...objKeyLabelPairs, ...pair }
									}
								} else {
									arrayLabels.push(exportLabel + '_' + theForm.components[i].components[j].columns[z].label)
									pair[theForm.components[i].components[j].columns[z].key] = exportLabel + '_' + theForm.components[i].components[j].columns[z].label
									objKeyLabelPairs = { ...objKeyLabelPairs, ...pair }
								}
							}
						} //CASE FIELDS IN COLUMNS INCLUDED IN PANEL						

						if (theForm.components[i].components[j].input) {
							// console.log ('TSAK 3: ', theForm.components[i].components[j])

							if (theForm.components[i].components[j].hasOwnProperty('components')) {
								for (var k = 0; k < theForm.components[i].components[j].components.length; k++) {
									// console.log ('TSAK 4: ', theForm.components[i].components[j].components[k])
									arrayLabels.push(exportLabel + '_' + theForm.components[i].components[j].components[k].label)
									pair[theForm.components[i].components[j].components[k].key] = exportLabel + '_' + theForm.components[i].components[j].components[k].label
									// arrayLabels.push(theForm.components[i].label + '_' + theForm.components[i].components[j].components[k].label)
									// pair[theForm.components[i].components[j].components[k].key] = theForm.components[i].label + '_' + theForm.components[i].components[j].components[k].label
									objKeyLabelPairs = { ...objKeyLabelPairs, ...pair }
								}
							} else {
								arrayLabels.push(exportLabel + '_' + theForm.components[i].components[j].label)
								pair[theForm.components[i].components[j].key] = exportLabel + '_' + theForm.components[i].components[j].label

								objKeyLabelPairs = { ...objKeyLabelPairs, ...pair }

							}

						}

					}
				}
			}

		}

		// console.log("Array Labels", arrayLabels)
		// console.log("Object Key Labels", objKeyLabelPairs)

		var objectForPdf = {}
		var headerKeys = Object.keys(objKeyLabelPairs)
		var values = []
		var value

		// console.log('KEYS ', keys)
		// console.log('HEADER KEYS ', headerKeys)

		//gia kathe ena apo ta headerKeys psaxno na vro tin timi
		headerKeys.forEach(headerKey => {
			// console.log('TSAK submission.data: ', submission.data)

			if (headerKey.toLowerCase() === ExportConst.DILOSI_SINAINESIS_API_PROPERTY_NAME.toLowerCase()) {
				var consentDescription = ''
				var exportLabel = ''
				var description = ''

				for (var i = 0; i < theForm.components.length; i++) {

					if (theForm.components[i].hasOwnProperty('components')) {
						// console.log('TSAK 2: ', theForm.components[i])

						for (var j = 0; j < theForm.components[i].components.length; j++) {
							if (theForm.components[i].components[j].input) {
								// console.log ('TSAK 3: ', theForm.components[i].components[j])

								theForm.components[i].components[j].hasOwnProperty('title') ? exportLabel = theForm.components[i].components[j].title : exportLabel = theForm.components[i].components[j].label

								if (exportLabel.toLowerCase() === ExportConst.DILOSI_SYNAINESIS.toLowerCase()) {

									theForm.components[i].components[j].hasOwnProperty('description') ? description = theForm.components[i].components[j].description : description = 'Μη Διαθέσιμη Δήλωση Συναίνεσης'

									consentDescription = description
								}
							}
						}
					} else {
						theForm.components[i].hasOwnProperty('title') ? exportLabel = theForm.components[i].title : exportLabel = theForm.components[i].label

						if (exportLabel.toLowerCase() === ExportConst.DILOSI_SYNAINESIS.toLowerCase()) {

							theForm.components[i].hasOwnProperty('description') ? description = theForm.components[i].description : description = 'Μη Διαθέσιμη Δήλωση Συναίνεσης'
							consentDescription = description
						}
					}
				}
				value = consentDescription
			}
			else {
				value = submission.data[headerKey]
			}

			if (value) {

				if (typeof value === "string") {
					//DATE CHECK - CHANGE FORMAT
					if (Date.parse(value)) {

						const regExUTCDates = /[0-9]{4}-[0-9]{2}-[0-9]{2}\s[A-Za-z0-9]+:([A-Za-z0-9]+( [A-Za-z0-9]+)+)/gi

						let updateDatesData = value.replaceAll(regExUTCDates, match => {
							let tempDate = new Date(match)
							let strDate = `${tempDate.getDate()}/${tempDate.getMonth() + 1}/${tempDate.getFullYear()}`
							//let strTime = `${(tempDate.getHours() < 10 ? '0' : '') + tempDate.getHours()}:${tempDate.getMinutes() < 10 ? '0' : ''}${tempDate.getMinutes()}`
							let newDateFormat = strDate + " " //+ strTime
							return newDateFormat
						})
						value = updateDatesData

						let ISO_8601_FULL = /[0-9]{4}-[0-9]{2}-[0-9]{2}T\d\d:\d\d:\d\d\.\d\d\dZ/gi
						let updateDatesDataISO_8601 = value.replaceAll(ISO_8601_FULL, match => {
							let tempDate = new Date(match)
							let strDate = `${tempDate.getDate()}/${tempDate.getMonth() + 1}/${tempDate.getFullYear()}`
							//let strTime = `${(tempDate.getHours() < 10 ? '0' : '') + tempDate.getHours()}:${tempDate.getMinutes() < 10 ? '0' : ''}${tempDate.getMinutes()}`
							let newDateFormat = strDate + " " //+ strTime
							return newDateFormat
						})
						value = updateDatesDataISO_8601
					}

					value = cleanDataBeforeSubmission(value)
					//END - DATE CHECK - CHANGE FORMAT
				}
				else if (typeof value === "object" && Array.isArray(value)) {
					//console.log(value)
					let tempValue = ''
					for (let i = 0; i < value.length; i++) {
						if (value[i].originalName) {
							tempValue = tempValue.concat(value[i].originalName + " ")
						} else {
							tempValue += value[i] + " "
						}
					}
					value = tempValue
				}
				else if (typeof value === "object") {
					if (value[0] && value[0].originalName) {
						value = value[0].originalName
					} else
						if (value.hasOwnProperty('name')) {
							value = value['name']
						} else {
							value = 'ΑΝΤΙΚΕΙΜΕΝΟ ΜΕ ΠΟΛΛΕΣ ΤΙΜΕΣ'
						}
				}
			} else {
				value = "-"
			}

			objectForPdf[headerKey] = value

			values = [...values, value]

		})

		// console.log('objectForPdf: ', objectForPdf)
		// console.log('objKeyLabelPairs: ', objKeyLabelPairs)

		return [objKeyLabelPairs, objectForPdf]
	}

	const [objKeyLabelPairs, objectForPdf] = submissionsToArray(submission)

	//console.log(objectForPdf)

	var prosklisi = '-'
	var submissionStr = "\n"
	var submissionStrArray = []
	submissionStr += "\n"

	for (const [key, value] of Object.entries(objKeyLabelPairs)) {
		// console.log(`${value}: ${objectForPdf[key]}`);

		if (key.toLowerCase() === ExportConst.PROSKLISI_API_PROPERTY_NAME.toLowerCase()) {
			// console.log(`${key}, ${value} : ${objectForPdf[key]}`);
			prosklisi = value + ': ' + objectForPdf[key]

		}
		// na min ektypwsei ta pedia prosklisi, userEmail kai osa den exoyn timi
		else if (objectForPdf[key] === '-' || key.toLowerCase() === ExportConst.USER_EMAIL.toLowerCase()) {
			continue
		}
		else {
			if (objectForPdf[key] === true) {
				objectForPdf[key] = 'ΑΛΗΘΕΣ'
			} else if (objectForPdf[key] === false) {
				objectForPdf[key] = 'ΨΕΥΔΕΣ'
			}
			submissionStr = value + ": " + objectForPdf[key]
		}

		submissionStrArray.push(submissionStr)
	}

	//var strHeader = ``
	//strHeader += `ΕΛΛΗΝΙΚΗ ΔΗΜΟΚΡΑΤΙΑ \nΥπουργείο Παιδείας, Θρησκευμάτων\nκαι Αθλητισμού\n\n-----\n\n`
	//strHeader += `ΓΕΝΙΚΗ ΔΙΕΥΘΥΝΣΗ ΨΗΦΙΑΚΩΝ ΣΥΣΤΗΜΑΤΩΝ,\nΥΠΟΔΟΜΩΝ ΚΑΙ ΕΞΕΤΑΣΕΩΝ\nΔΙΕΥΘΥΝΣΗ ΗΛΕΚΤΡΟΝΙΚΩΝ ΚΑΙ ΔΙΚΤΥΑΚΩΝ\nΣΥΣΤΗΜΑΤΩΝ\n`

	//console.log(form.title.indexOf(" "))
	let newFormTitle = form.title
	if (newFormTitle.length >= 35) {
		let middle = Math.round(newFormTitle.length / 2);

		while (newFormTitle.charAt(middle) !== " ") {
			middle = middle + 1
		}

		let tempTitle = newFormTitle.slice(0, middle) + " \n " + newFormTitle.slice(middle);

		newFormTitle = tempTitle
	}

	var strSubHeader1 = `ΣΤΟΙΧΕΙΑ ΦΟΡΜΑΣ ΜΕ ΤΙΤΛΟ: ${newFormTitle}\n${prosklisi}\n(επέχει θέση Υπεύθυνης Δήλωσης του Ν.1599/86)`
	var strSubHeader2 = ''
	if (form.properties && form.properties.gsis && form.properties.gsis === true) {
		strSubHeader2 = `Αρ. Πρωτοκόλλου: ${submissionID} \nΗμερομηνία Υποβολής: ${strDate}\nΏρα Υποβολής: ${strTime}\nΑναγνωριστικό: ${userEmail}`
	} else {
		strSubHeader2 = `Αρ. Πρωτοκόλλου: ${submissionID} \nΗμερομηνία Υποβολής: ${strDate}\nΏρα Υποβολής: ${strTime}\nEmail: ${userEmail}`
	}

	var strTitle = `... Υποβληθέντα δεδομένα ...`

	//DOWNLOAD PDF
	let doc = new jsPDF()
	//console.log("PAGE WIDTH:", doc.internal.pageSize.getWidth())
	let countLines = 115
	doc.page = 1;

	doc.setProperties({
		title: 'Forms2 PDF Document',
		subject: 'Form Data',
		author: 'Forms2',
		keywords: 'Forms2 PDF',
		creator: 'Forms2'
	})

	var logo = new Image()
	logo = logoImage

	doc.addImage(logo, 'jpeg', 70, 25, 80, 18)
	doc.setFont('FreeSans')

	doc.setFontSize('10')

	//doc.text(strHeader, 105, 30, { align: "center" })
	doc.text(strSubHeader1, 10, 65)
	doc.text(strSubHeader2, 200, 80, { align: "right" })
	doc.setFontSize('14')
	doc.text(strTitle, 105, 105, { align: "center" })
	doc.setFontSize('9')

	//console.log("**** submissionStrArray", submissionStrArray)
	if (!(submissionStrArray.length === 0)) {
		let pageHeight = Math.floor(doc.internal.pageSize.height);
		submissionStrArray.forEach((item, index) => {
			//console.log("item ", item)
			if ((countLines + Math.ceil(item.length / 190)) >= (pageHeight - 30)) {
				doc.addPage()
				countLines = 20
			}

			doc.text(item, 10, countLines, { maxWidth: 190, align: "justify" })
			if (item.length >= 190) {
				countLines += (10 * (Math.ceil(item.length / 170)))
			} else {
				countLines += (9 * (Math.ceil(item.length / 170)))
			}
		})
	}

	footer(doc)
	const userName = submission.data.name.replaceAll(" ", "_").replaceAll(".", "")
	const submissionIDLastDigits = submission._id.substring(submission._id.length - 6)
	let formTitleToPrint = ''
	if (form.title && (form.title.indexOf(' ') > 12 || form.title.indexOf(' ') === -1)) {
		formTitleToPrint = form.title.substring(0, 12)
	} else {
		formTitleToPrint = form.title.split(' ').slice(0, 2).join('_');
	}

	const submissionFileName = userName + "_" + formTitleToPrint + "_" + submissionIDLastDigits

	//doc.save(`${formTitleToPrint}_ID_${submission._id}`)
	doc.save(`${submissionFileName}`)

}

export default exportSubmissionDataPDF
