/**********************************************************************************
 * G. Papaioannou
 * g_papaioannou@rocketmail.com
 *
 *
 *
 *
 * 04-11-2022 Style Multiple/Unique submissions has changed
 * 			  nav-link to="/form" has changed to take into account the different user accounts
 *
 * 31-01-2023 Fixed class to className
 ***********************************************************************************/

import { Link, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getForm, selectRoot } from 'react-formio';
import View from './View';
import Edit from './Edit';
import Delete from './Delete';
import Submission from './Submission/index';
import UserSubmissions from './userSubmissions/index';
import SubmissionForm from './Submission/Item/SubmissionForm';
import Export from '../Export';
//import {FormConst} from '../../../config'
import singleSubmissionValidator from '../../../helpers/singleSubmissionValidator';
import Loading from '../../../containers/Loading';
import { Redirect } from 'react-router-dom';

const Item = class extends Component {
	constructor() {
		super();

		this.state = {
			formId: '',
			//numberOfSubmissions: ''
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.match.params.formId !== prevState.formId) {
			nextProps.getForm(nextProps.match.params.formId);
		}

		return {
			formId: nextProps.match.params.formId,
		};
	}

	render() {
		const { auth } = this.props;
		if (auth === undefined || auth.authenticated === false) {
			return <Redirect to="/" />;
		}

		const {
			form,
			match: {
				params: { formId },
			},
			//submissions
		} = this.props;

		const singlOrMultipleMessageStyle = {
			fontSize: '1rem',
			color: '#FFFFFF',
		};

		if (auth.isActive) {
			return <Loading />;
		}

		//const userEmail = this.state.userEmail
		let singleSubmission = singleSubmissionValidator(form.form);

		/* let numberOfSubmissions = 0
		if (submissions.submissions !== undefined) {
		  //numberOfSubmissions = submissions.submissions.length
		  numberOfSubmissions = submissions.submissions.reduce((s, n) => {
			return s + (n.data.userEmail === userEmail && n.metadata.state === "submitted")
		  }, 0)
		} */

		// if (form.form.properties !== undefined) {
		// 	if (form.form.properties.numberOfSubmissions !== undefined) {
		// 		console.log('TSAK form.form.properties.numberOfSubmissions: ', form.form.properties.numberOfSubmissions)
		// 		if (form.form.properties.numberOfSubmissions === FormConst.SINGLE_SUBMISSION) {
		// 			singleSubmission = true
		// 		} else {
		// 			singleSubmission = false
		// 		}
		// 		console.log('TSAK singleSubmission: ', singleSubmission)
		// 	}

		// }

		return (
			
			<div>
				{singleSubmission ? (
					<h3 style={singlOrMultipleMessageStyle}>
						<span className="badge" style={{backgroundColor: "#B40808"}}>Μοναδική Υποβολή</span>
					</h3>
				) : (
					<h3 style={singlOrMultipleMessageStyle}>
						<span className="badge" style={{backgroundColor: "#225D37"}}>Πολλαπλές Υποβολές</span>
					</h3>
				)}
				<ul className="nav nav-tabs">
					<li className="nav-item">
						{!auth.is.administrator ? (
							<Link className="nav-link" to="/form/userlistforms">
								<i className="fa fa-chevron-left" /> Λίστα Φορμών
							</Link>
						) : auth.user.data.selectedAdminRole === 'adminRole' ? (
							<Link className="nav-link" to="/form/adminlistforms" >
								<i className="fa fa-chevron-left" /> Λίστα Φορμών
							</Link>
						) : (
							<Link className="nav-link" to="/form/formbuilderlistforms" >
								<i className="fa fa-chevron-left" /> Λίστα Φορμών
							</Link>
						)}
					</li>
					{auth.is.administrator ? (
						<>
							<li className="nav-item">
								<Link className="nav-link" to={`/form/${formId}/submission`} >
									<i className="fa fa-list-alt" /> Δεδομένα
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={`/form/${formId}/edit`} >
									<i className="fa fa-edit" /> Επεξεργασία Φόρμας
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={`/form/${formId}/delete`} >
									<i className="fa fa-trash" /> Διαγραφή Φόρμας
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={`/form/${formId}/export`} >
									<i className="fa fa-file-download" /> Εξαγωγή Δεδομένων
								</Link>
							</li>
						</>
					) : (
						<>
							<li className="nav-item">
								{/* <Link className="nav-link" to={`/form/${formId}/userSubmissions`}>
									<i className="fa fa-list-alt" /> Αιτήσεις
								</Link> */}
								{/* <Link className="nav-link" to={`/form/${formId}/submission`}>
									<i className="fa fa-list-alt" /> Αιτήσεις
								</Link> */}
								{singleSubmission ? (
									<Link className="nav-link" to={`/form/${formId}/submissionSingle`} >
										<i className="fa fa-list-alt" /> Αιτήσεις
									</Link>
								) : (
									<Link className="nav-link" to={`/form/${formId}/userSubmissions`} >
										<i className="fa fa-list-alt" /> Αιτήσεις
									</Link>
								)}
							</li>
						</>
					)}
				</ul>
				<Switch>
					<Route exact path="/form/:formId" component={View} />
					<Route path="/form/:formId/edit" component={Edit} />
					<Route path="/form/:formId/delete" component={Delete} />
					<Route path="/form/:formId/submission" component={Submission} />
					<Route path="/form/:formId/submissionSingle" component={SubmissionForm} />
					<Route path="/form/:formId/userSubmissions" component={UserSubmissions} />
					<Route path="/form/:formId/export" component={Export} />
				</Switch>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		auth: selectRoot('auth', state),
		form: selectRoot('form', state),
		submissions: selectRoot('submissions', state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getForm: id => dispatch(getForm('form', id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
