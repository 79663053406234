import React, { Component } from "react";
import PropTypes from 'prop-types';
import * as XLSX from "xlsx";

const propTypes = {
  table: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  sheet: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.string,
  buttonText: PropTypes.string,
};

const defaultProps = {
  id: 'button-download-as-xlsx',
  className: 'button-download',
  buttonText: 'Download',
};

/* function testDate(str) {
  let t = str.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
  if(t === null)
    return false;
  let d = +t[1], m = +t[2]//, y = +t[3];

  if(m >= 1 && m <= 12 && d >= 1 && d <= 31) {
    return true;  
  }

  return false;
} */

class ReactHTMLTableToExcel extends Component {

  exportToExcel = () => {
    if (!document) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Failed to access document object');
      }

      return null;
    }

    if (document.getElementById(this.props.table).nodeType !== 1 || document.getElementById(this.props.table).nodeName !== 'TABLE') {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Provided table property is not html table element');
      }

      return null;
    }

    const table = document.getElementById(this.props.table);
    
    //console.log(table.outerHTML)
    let htmlString = table.outerHTML

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    
    const rows = doc.querySelectorAll('table tr');
    // Columns that contain dates
    //const isDate = str => !isNaN(Date.parse(str));
    //const isDate = str => testDate(str)
    //const epoch = new Date(Date.UTC(1899, 11, 30)); // Excel epoch start date
    //const toExcelDate = date => Math.floor((new Date(date) - epoch) / (24 * 60 * 60 * 1000)) + 1;

    // Prevent Excel from parsing str as Date in case dd < 12 
    const tableData = Array.from(rows).map((row, rowIndex) => {
      return Array.from(row.cells).map((cell, colIndex) => {
        return cell.innerText
       /*  if (rowIndex === 0) {
          // First row (header), return cell content
          return cell.innerText;
        } else {
          // Other rows, check if the cell content is a date
          return cell.innerText;
          //return isDate(cell.innerText) ? transformDate(cell.innerText) : cell.innerText;
        } */
      });
    });

    // Create a new workbook and worksheet
    //const XLSX = require('xlsx');
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(tableData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1',  {cellDates: true});

    // Write the workbook to a file
    //XLSX.writeFile(workbook, 'table_data.xlsx');

    //const workbook = XLSX.utils.table_to_book(table, { sheet: "Sheet1" });
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    this.saveAsExcelFile(excelBuffer, this.props.filename);
  };

  saveAsExcelFile = (buffer, fileName) => {
    const data = new Blob([buffer], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <div>
        <button id={this.props.id}
          className={this.props.className}
          style={this.props.style}
          onClick={this.exportToExcel}>
          {this.props.buttonText}
        </button>
      </div>
    );
  }
}

ReactHTMLTableToExcel.propTypes = propTypes;
ReactHTMLTableToExcel.defaultProps = defaultProps;

export default ReactHTMLTableToExcel;