/*
g_papaioannou@rocketmail.com

01-11-2021 : fixed the white spaces among the strings & in case of bearer error now it returns an object. Check with res.error

29-03-2023 : Added body section in case of POST method
*/

const fetchUser = async ({ url, method, body = {} }) => {
	//const formioToken = localStorage.getItem("formioToken");

	let userToken;

	const allCookiesArray = document.cookie.split(';');
	let cookieBearerToken = '';
	for (let i = 0; i < allCookiesArray.length; i++) {
		let input = allCookiesArray[i].replace(' ', '');
		if (input.startsWith('Bearer-token')) {
			cookieBearerToken = allCookiesArray[i];
			break;
		}
	}

	if (cookieBearerToken.indexOf('=') !== -1 || cookieBearerToken.indexOf('=') === 0) userToken = cookieBearerToken.split('=')[1].replace(/ /g, '');
	else return { error: 'Bearer Token not Found' };

	const authorization = 'Bearer ' + userToken;
	const conf = {
		method,
		mode: 'cors',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json',
			//'x-jwt-token': formioToken,
			Authorization: authorization,
		},
		redirect: 'follow',
	};

	if (method === 'POST') conf.body = body;

	try {
		const response = await fetch(url, conf);

		if (response.ok) return response.json();

		throw new Error(response.statusText);
	} catch (err) {
		return { error: err };
	}
};

export default fetchUser;
