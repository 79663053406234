import { SET_PIN } from "../actions/types";

const initialPinState = {
    pin: [],
};

export default function(state = initialPinState, action) {
	switch (action.type) {
		case SET_PIN:
			return {
				...state,
				pin: action.payload
			};
			
		default:
			return {
				...state,
			};
	}
}