const translateGR = JSON.stringify(
    {
        'en': {
            "_comment_Α.0": "LOGIN",
            'Login': 'Είσοδος',
            'Email must be a valid email':'Εισάγετε μια έγκυρη διεύθυνση email',
            'invalid_email': 'Στο πεδίο {{field}}, εισάγετε μια έγκυρη τιμή.',
            'required': 'Το πεδίο {{field}} απαιτείται.',
            'No forms found': 'Δεν βρέθηκαν φόρμες',
            'Please check the form and correct all errors before submitting.': 'Δες την φόρμα',
            'is required': 'Το πεδίο {{field}} απαιτείται 2.',
            '{{field}} is required': 'απαιτείται',
            //'Please fix the following errors before submitting.': 'Διορθώστε τα λάθη.',
            //'error': 'Ελέγξτε τα λάθη.',
            'submitError':'Ελέγξτε τη φόρμα και διορθώστε τα λάθη',
            //'Missing password':'Εισάγετε συνθηματικό',      
            
            'Drag and Drop a form component': "Σύρε και Άφησε ένα Στοιχείο της Φόρμας",
            'Forms': 'Φόρμες',
            'Operations': 'Λειτουργίες',
            'Enter Data':'Εισαγωγή Δεδομένων',
            'View Data':'Δεδομένα',
            'Edit Form':'Επεξεργασία Φόρμας',
            'Save Form':'Αποθήκευση Φόρμας',
            'Create Form':'Δημιουργία Φόρμας',

            'File Name': 'Όνομα Αρχείου',

            "_comment_Α": "TOOLBAR",
            "_comment_Α1": "Basic",
            'Submit': 'Υποβολή',
            'Basic Components': 'Βασικά Εργαλεία',
            'Basic': 'Βασικά',
            'Text Area': 'Πλαίσιο Κειμένου',
            'Text Field': 'Πεδίο Κειμένου',
            'Number': 'Αριθμός',
            'Password': 'Συνθηματικό',
            'Checkbox': 'Πλαίσιο Ελέγχου',
            'Select Boxes': 'Κουτί Επιλογής',
            'Select': 'Κατάλογος Επιλογής',
            'Radio': 'Κουμπιά Επιλογής (Radio)',
            'Button': 'Κουμπί',

            "_comment_Α2": "Advanced",
            'Advanced': 'Προχωρημένα',
            'Email': 'Ηλεκτρονικό Ταχυδρομείο',
            'Url': 'Διεύθυνση Ιστοσελίδας',
            'Phone Number': 'Αριθμός Τηλεφώνου',
            'Tags': 'Ετικέτες',
            'Address': 'Διεύθυνση',
            'Date/Time': 'Ημερομηνία/Ώρα',
            'Day': 'Ημέρα',
            'Month': 'Μήνας',
            'Year': 'Έτος',
            'Time': 'Ώρα',
            'Currency': 'Νόμισμα',
            'Survey': 'Ερωτηματολόγιο',
            'Signature': 'Υπογραφή',


            "_comment_Α3": "Layout",
            'HTML Element': 'Στοιχείο HTML',
            'Content': 'Περιεχόμενο',
            'Columns': 'Στήλες',
            'Field Set': 'Σύνολο Πεδίων',
            'Panel': 'Πίνακας Χειρισμού (Πάνελ)',
            'Table': 'Πίνακας',
            'Tabs': 'Καρτέλες',
            'Well': 'Well',

            "_comment_Α4": "Data",
            'Hidden': 'Κρυφό',
            'Container': 'Container',
            'Data Map': 'Χάρτης Δεδομένων',
            'Data Grid': 'Πλέγμα Δεδομένων',
            'Edit Grid': 'Επεξεργασία Πλέγματος',
            'Tree': 'Δένδρο',

            "_comment_BT": "SOME BASIC TERMS",
            'User Fields': 'Πεδία Χρήστη',
            'First Name': 'Όνομα',
            'Last Name': 'Επίθετο',
            'Mobile Phone': 'Κινητό Τηλέφωνο',
            'Layout': 'Διάταξη',
            'Display': 'Εμφάνιση',
            'Data': 'Δεδομένα',
            'Preview': 'Προεπισκόπηση',
            'Save': 'Αποθήκευση',
            'Cancel': 'Άκυρο',
            'Remove': 'Διαγραφή',
            'Validation': 'Επικύρωση',
            'Conditional': 'Υπο Συνθήκη',
            'Help': 'Βοήθεια',

            "_comment_B1": "TAB DISPLAY_NOT THE SAME FOR ALL OF THE COMPONENTS",
            'Label': 'Ετικέτα',
            'Component': 'Στοιχείο',
            'Label Position': 'Θέση Ετικέτας',
            'Placeholder': 'Κείμενο Υποκατάστασης',
            'Description': 'Περιγραφή',
            'Tooltip': 'Κείμενο Συμβουλής',
            'Prefix': 'Πρόθημα',
            'Suffix': 'Επίθημα',
            'Widget': 'Γραφικό Στοιχείο',
            'Input Mask': 'Μάσκα Εισαγωγής',
            'Allow Multiple Masks': 'Δυνατότητα Πολλαπλών Μασκών',
            'Custom CSS Class': 'Προσαρμοσμένο CSS',
            'Tab Index': 'Σειρά Εστίασης',
            'Description for this field.':'Περιγραφή του πεδίου.',
            'Τo add a tooltip to this field, enter text here.':'Εισάγετε κείμενο για το πλαίσιο συμβουλής.',
            'Hide Label': 'Κρυφή Μάσκα',
            'Show Word Counter': 'Εμφάνιση Μετρητή Λέξεων',
            'Show Character Counter': 'Εμφάνιση Μετρητή Χαρακτήρων',
            'Hide Input': 'Απόκρυψη Δεδομένων Εισόδου',
            'Initial Focus': 'Αρχική Εστίαση',
            'Allow Spellcheck': 'Δυνατότητα Ορθογραφικού Ελέγχου',
            'Disabled': 'Απενεργοποιημένο',
            'Table View': 'Εμφάνιση σε Πίνακα',
            'Modal Edit': 'Επεξεργασία σε Αποκλειστικό Παράθυρο',
            'Mask': 'Μάσκα',
            'Top':'Κορυφή',
            'Bottom':'Κάτω μέρος',
            'Type to search':'Αναζήτηση',
            
            "_comment_C1": "TAB DATA_NOT THE SAME FOR ALL OF THE COMPONENTS",
            'Multiple Values': 'Πολλαπλές Τιμές',
            'Default Value': 'Προκαθορισμένη Τιμή',
            'Persistent': 'Διατήρηση',
            'None': 'Καμία',
            'Server': 'Διακομιστής',
            'Client': 'Πελάτης',
            'Input Format': 'Μορφή Εισαγωγής',
            'Plain': 'Απλή',
            'Protected': 'Προστατευμένη',
            'Database Index': 'Ευρετήριο στη ΒΔ',
            'Text Case': 'Πεζά-Κεφαλαία Γράμματα',
            'Mixed (Allow upper and lower case)': 'Μικτά πεζά και κεφαλαία γράμματα',
            'Uppercase': 'Κεφαλαία',
            'Lowercase': 'Πεζά',
            'Encrypted (Enterprise Only)': 'Κρυπτογράφηση (Enterprise Only)',
            'Redraw On': 'Επανασχεδίαση όταν:',
            'Clear Value When Hidden': 'Διαγραφή τιμής όταν το πεδίο είναι κρυφό',
            'Custom Default Value': 'Προσαρμοσμένη Αρχική Τιμή',
            'Calculated Value': 'Υπολογιζόμενη Τιμή',

            "_comment_D1": "TOOLTIPS",
            'Allow Manual Override of Calculated Value': 'Δυνατότητα αυθαίρετης αντικατάστασης υπολογιζόμενης τιμής',
            'The label for this field that will appear next to it.': 'Η ετικέτα για το συγκεκριμένο πεδίο, που θα εμφανιστεί δίπλα του.'
        }
    });

export default translateGR;
