export default class StayOnPage {
	constructor() {
		this.stay = true
	}
    
    setStay(value) {
		this.stay = value
	}
	
	getStay() {
		return this.stay
	}
}

