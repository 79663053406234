const asyncFetch = async ({url, method, data}) => {
    const formioToken = localStorage.getItem("formioToken");
    const conf = {
        method,
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'x-jwt-token': formioToken,
        },
        redirect: 'follow'
      };
      if (data!== undefined) {
          conf.body = JSON.stringify(data)
      }
    const response = await fetch(url, conf);
    if (response.ok) return response.json();
    throw new Error(response.statusText);
  };


export default asyncFetch
