/* *************************************************************************
>> SessionTimeout


 g. papaioannou (g_papaioannou@rocketmail.com)

 Nov 2022

 30-11-2022 : Auto Logout Session Timeout. 
 			  Note : Install moment 
 03-12-2022 : Optimized code. No need to use the moment package - removed
 05-12-2022 : Fixed WARNING_TIME_BEFORE_LOGOUT in warningInactive()
 14-12-2022 : Added session countdown timer
 15-12-2022 : Changed the CSS style to Flex

 
 ************************************************************************* */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningIcon from '../assets/warning.png';
import { selectRoot } from 'react-formio';
import { connect } from 'react-redux';
import { AuthConfig } from '../config';

const SessionTimeout = props => {
	const toastWindowID = 1000;
	const SESSION_INACTIVITY_TIME = AuthConfig.MAX_SESSION_INACTIVITY_TIME * 60;

	const [events] = useState(['click', 'load', 'scroll']);
	const [second, setSecond] = useState(0);
	const [isOpen, setOpen] = useState(false);

	let warningInactiveTimerID = useRef();
	let inactivityTimerID = useRef();
	let isAuthenticated = useRef(false);
	const [countDownTime, setcountDownTime] = useState(SESSION_INACTIVITY_TIME);
	let countDown = useRef();
	let timerId = useRef();

	isAuthenticated.current = props.auth.authenticated;

	const innerStyleFlex = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		bottom: '10px',
		right: '20px',
		backgroundColor: 'red',
		color: 'white',
		width: '100px',
		height: '30px',
		zIndex: '200',
		border: '2px solid orange',
		borderRadius: '25px',
		position: 'fixed',
		opacity: 0.6,
	};

	// start inactive check. Check every MAX_SESSION_INACTIVITY_TIME - 1
	let inactivityTimeChecker = () => {
		countDown.current = SESSION_INACTIVITY_TIME;
		timerId.current = setInterval(refreshClock, 1000);
		inactivityTimerID.current = setTimeout(() => {
			if (isAuthenticated.current) {
				clearInterval(timerId.current);
				warningInactive();
			}
		}, (AuthConfig.MAX_SESSION_INACTIVITY_TIME - AuthConfig.WARNING_TIME_BEFORE_LOGOUT) * 60 * 1000); //mseconds
	};

	// warning timer
	let warningInactive = () => {
		clearTimeout(inactivityTimerID.current); // cancels a timeout previously established by calling setTimeout()
		let leftSecond = AuthConfig.WARNING_TIME_BEFORE_LOGOUT * 60;
		warningInactiveTimerID.current = setInterval(() => {
			leftSecond--;

			//Inform user with a popup window to is about to logout due inactivity
			if (leftSecond > 1) {
				setSecond(leftSecond);
				setOpen(true);
			} else {
				//Inactive for MaxTime, logout
				resetTimer();
				window.location.href = AuthConfig.SESSIONEXPIRED;
			}
		}, 1000); //check every 1 second
	};

	// reset interval timer
	let resetTimer = useCallback(() => {
		clearTimeout(inactivityTimerID.current);
		clearInterval(warningInactiveTimerID.current);
		clearInterval(timerId.current);
		toast.dismiss(toastWindowID);
		countDown.current = SESSION_INACTIVITY_TIME;
		setcountDownTime(countDown.current);
		inactivityTimeChecker();
		setOpen(false);
	}, [isAuthenticated.current]); // eslint-disable-line react-hooks/exhaustive-deps

	function refreshClock() {
		countDown.current--;
		setcountDownTime(countDown.current);
	}
	//Update useEffect when a)resetTimer b)user event is fired and c) inactivityTimeChecker
	useEffect(() => {
		
		events.forEach(event => {
			window.addEventListener(event, resetTimer); //reset timer on every event
		});

		return () => {
			events.forEach(event => {
				window.removeEventListener(event, resetTimer); //reset timer on every event
			});
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	//if popup window flag is not signaled ie not Open just return
	if (!isOpen) {
		if (isAuthenticated.current) {
			const date = new Date(null);
			date.setSeconds(countDownTime); // specify value for SECONDS here
			let timeLeft = date.toISOString().slice(14, 19);

			return <div style={innerStyleFlex}>{timeLeft}</div>;
		} else return null;
	}

	//iform the user that he is going to be disconnected
	const Msg = ({ closeToast, toastProps }) => {
		return (
			<div align="center">
				<h5>Αυτόματη αποσύνδεση (logout) από την εφαρμογή σε {second} δευτερόλεπτα.</h5>
				Κάντε κλικ οπουδήποτε επάνω στο παράθυρο, για να ανανεώσετε τον χρόνο σύνδεσή σας.
			</div>
		);
	};
	toast.update(toastWindowID, { render: <Msg /> }); //use this to update the seconds
	toast(<Msg />, {
		position: 'top-center',
		autoClose: false,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
		theme: 'dark',
		icon: ({ theme, type }) => <img src={WarningIcon} width="100px" alt="warning" />,
		toastId: toastWindowID,
		transition: Flip,
		closeButton: false,
	});
	return null;
};

const mapStateToProps = state => {
	return {
		auth: selectRoot('auth', state),
	};
};

export default connect(mapStateToProps)(SessionTimeout);
