import fetch from 'node-fetch';
import { AppConfig } from './config';

export default async function getServerMaintenance() {
	//maintenance

	let urlGetMaintenanceRest = `${AppConfig.apiUrlUser}/api/getMaintenance`;
	let MaintenanceValue;

	let httpHeaders = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	};

	try {
		const res = await fetch(`${urlGetMaintenanceRest}`, {
			method: 'GET',
			headers: httpHeaders,
		});

		const json_results = await res.json();
		MaintenanceValue = json_results['data']['maintenance'] === 'true';
		return MaintenanceValue;
	} catch (err) {
		//if back server is down set maintance = true
		MaintenanceValue = true;
		return MaintenanceValue;
	}
}
