import { ReactComponent } from 'react-formio';


export default class YpepthFileComponent extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Αρχείο',
      icon: 'file',
      group: 'advanced',
      documentation: '',
      weight: 100,
      schema: YpepthFileComponent.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'file',
      key: 'file',
      fileMaxSize: '2MB',
      label: 'Αρχείο',
      storage: 'base64'
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
//   static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
//   attachReact(element) {
//     console.log('attachReact', element);
//     return ReactDOM.render(
//       <Example
//         component={this.component} // These are the component settings if you want to use them to render the component.
//         value={this.dataValue} // The starting value of the component.
//         onChange={this.updateValue} // The onChange event to call when the value changes.
//       />,
//       element
//     );
//   }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  // static detachReact(element) {
  //   // console.log('detachReact', element);
  //   if (element) {
  //     ReactDOM.unmountComponentAtNode(element);
  //   }
  // }
}
