const cleanDataBeforeSubmission = (data) => {

	if (typeof data === "object") {

		for (let key in data) {
		
			let value = data[key]
			
			if (typeof value === "string"){
				value = cleanString(value)
			}
	
			data[key] = value
	
		}

	} else if (typeof data === "string"){ 
		cleanString(data)
	}

    return data
}

const cleanString = (str) => {
	//remove line breaks
	str = str.replace(/[\r\n]+/gm," ");
	//remove commas - create issues in export
	str = str.replace(/[,\s]/g," ");
	//remove extra whitespaces
	str = str.replace(/\s+/g," ");

	return str
}

export default cleanDataBeforeSubmission