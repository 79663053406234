/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectRoot } from 'react-formio';
import Loading from '../../containers/Loading';
import {AppConfig, ExportConst, FormConst} from '../../config'
import asyncFetch from '../../helpers/helperFunctions';
import SubmissionsInForm from './SubmissionsInForm'
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
 
const ListArchived = class extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userArchivedForms: [],
			userSubmissions: [],
			isActive : true,
			error: false,
		};
	  }

	componentDidMount() {
		const { auth } = this.props;
		
		let user = null;
		let isAdmin = false;
		if (auth.user === null) {
			user = JSON.parse(localStorage.getItem('formioUser'));
		} else {
			user = auth.user;
			isAdmin = auth.is.administrator;
		}

		const fetchUserForms = async (user, auth) => {

			const allArchivedForms = await asyncFetch({
				url: `${AppConfig.apiUrl}/form?properties.status=` + FormConst.ARCHIVED + /* `&properties.exportPDF=` + FormConst.EXPORT_PDF +  */`&limit=` + ExportConst.LIMIT,
				method: 'GET',
			});
	
			const isActive = await fetchFormsWithSubmission(allArchivedForms, user)
			
			this.setState( state => {
				return {
					...state,
					isActive
					}
			});

		}

		const fetchFormsWithSubmission = async (forms, user) => {
			var isActive = true
			
			forms.forEach(async (form)  => {
		
				await userSubmissionExistInForm(form, user)
				
			})
		
			isActive = false

			return isActive
		}

		const userSubmissionExistInForm = async (form, user) => {

			const submission = await asyncFetch({
				url: `${AppConfig.apiUrl}/${form.name}/submission?data.userEmail=`+ user.data.email,
				method: 'GET',
			});
			
			if (submission.length !== 0 ) {
				this.setState({
					...this.state,
					userSubmissions: [...this.state.userSubmissions, submission].sort(function(a,b){
						// Turn your strings into dates, and then subtract them
						// to get a value that is either negative, positive, or zero.
						// Prosoxi: doyleyei sosta giati o xristis kanei ena submission se kathe forma
						return new Date(b[0].modified) - new Date(a[0].modified);
					  }),
					userArchivedForms: [...this.state.userArchivedForms, form],
				});
			}
			
		}

		fetchUserForms(user, isAdmin)
		
	}

	renderItems() {
		const { userArchivedForms, userSubmissions, error } = this.state

		if (!error) {
            return userSubmissions.map( (submissionsInForm, index) => (
                <SubmissionsInForm key={index} submissionsInForm={submissionsInForm} forms={userArchivedForms}/>
            ))
        } else {
            return <div>An error occured!</div>
        }
			
	}

	render() {
		const { isActive } = this.state

		if (isActive) {
			return <Loading />;
		}

		return (
			<div className="animated fadeIn">
				{/* edv tha mporoysan na mpoyn ta erros apo ta fetsharismata */}
				{/* <Errors errors={errors} /> */}
				<h3>Ιστορικό Αιτήσεων</h3>
				<hr />

				<Row>
					<Col>
						<Card>
							{/* <CardHeader>
								<i className="fa fa-align-justify"></i> Ιστορικό Αιτήσεων
							</CardHeader> */}
							<CardBody>
								<Table hover bordered striped responsive size="sm">
									<thead>
									<tr>
										<th>Φόρμα</th>
										<th>Ημερομηνία Υποβολής</th>
										<th><span hidden>Εξαγωγή PDF</span><i class="fa fa-server fa-2x" aria-label="PDF" aria-hidden="true"></i></th>
									</tr>
									</thead>
									<tbody>
										{this.renderItems()}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Col>
				</Row>
		 	</div>
		 );
	}
};

const mapStateToProps = state => {
	return {
		auth: selectRoot('auth', state)
	};
};

export default connect(mapStateToProps)(ListArchived);
