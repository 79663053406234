const addDefaultCSSToFileFields = (form) => {

    for (let i = 0; i < form.components.length; i++) {
        //FORM root case
        if (form.components[i].type === "file") {
            form.components[i].customClass = "file_hover"
        }
        //PANEL case
        if (form.components[i].type === "panel") {
            for (let j = 0; j < form.components[i].components.length; j++) {
                if (form.components[i].components[j].type === "file") {
                    form.components[i].components[j].customClass = "file_hover"
                }
            }
        }
        //COLUMNS case
        if (form.components[i].type === "columns") {
            for (let j = 0; j < form.components[i].columns.length; j++) {
                for (let k = 0; k < form.components[i].columns[j].components.length; k++) {
                    if (form.components[i].columns[j].components[k].type === "file") {
                        form.components[i].columns[j].components[k].customClass = "file_hover"
                    }
                }
            }
        }

    }
}

export default addDefaultCSSToFileFields

