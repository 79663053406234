import React from "react";
import { useState, useEffect } from "react";
import { FormsSubmissionItem } from "./FormsSubmissionItem";
import { Button, Dropdown, ButtonGroup } from "react-bootstrap";

const FormsSubmissionGrid = (props) => {

    const [pageSize, setPageSize] = useState(10),
        onPageSizeChange = (n) => {
            setPageSize(n)
            setPage(0)
        }

    const [numberOfPages, setNumberOfPages] = useState(Math.ceil(props.submissions.length / pageSize))
    
    useEffect(() => {
        setNumberOfPages(Math.ceil(props.submissions.length / pageSize))
      }, [props.submissions.length, pageSize]);   

    //console.log(props)
    const [page, setPage] = useState(0),
        onNextPage = () => {
            setPage((page + 1) % numberOfPages)
        },
        onPrevPage = () => {
            setPage((page -1))
        },
        onStartPage = () => {
            setPage(0)
        },
        onLastPage = () => {
            setPage(numberOfPages - 1)
        }
    const [displayAll, setDisplayAll] = useState(false),
        clickDisplayAll = () => {
            setDisplayAll(!displayAll)
        }

    const sortedSubmissions = props.submissions.sort(function (a, b) {
        return new Date(b.modified) - new Date(a.modified);
    });

    
    return (
        <div style={{ marginBottom: "1.3rem" }}>
            {/* <ul class="list-group">
                {listSubmissions}
            </ul> */}
            {!displayAll ? sortedSubmissions.slice(page * pageSize, (page + 1) * pageSize).map((el, k) =>
                <FormsSubmissionItem key={el._id}
                    formID={props.formID}
                    submission={el}
                    formPDF={props.formPDF}
                />) : sortedSubmissions.map((el, k) =>
                    <FormsSubmissionItem key={el._id}
                        formID={props.formID}
                        submission={el}
                        formPDF={props.formPDF}
                    />)
            }
            <div style={{ marginTop: "1.3rem" }}>
                {!displayAll ?
                    <>
                        <Button variant="outline-primary" onClick={onStartPage} disabled={!page}><i style={{ pointerEvents: "none" }} class="fa fa-step-backward" aria-hidden="true"></i></Button>
                        <Button variant="outline-primary" onClick={onPrevPage} disabled={!page}>Προηγούμενο</Button>
                        <Button variant="outline-primary" onClick={onNextPage} disabled={page === Math.ceil(sortedSubmissions.length / pageSize) - 1 || sortedSubmissions.length === 0}>Επόμενο</Button>
                        <Button variant="outline-primary" onClick={onLastPage} disabled={page === numberOfPages - 1 || sortedSubmissions.length === 0}><i style={{ pointerEvents: "none" }} class="fa fa-step-forward" aria-hidden="true"></i></Button>
                        <Dropdown style={{marginLeft: "1.3rem"}} as={ButtonGroup}>
                            <Button variant="outline-primary">N</Button>
                            <Dropdown.Toggle split variant="outline-primary" id="dropdown-split-basic" />
                            <Dropdown.Menu>
                                <Dropdown.Item style={pageSize === 10 ? {color:"white",backgroundColor:"#43B6D7"} : {}} onClick={() => onPageSizeChange(10)}>10</Dropdown.Item>
                                <Dropdown.Item style={pageSize === 20 ? {color:"white",backgroundColor:"#43B6D7"} : {}} disabled={props.submissions.length < 20} onClick={() => onPageSizeChange(20)}>20</Dropdown.Item>
                                <Dropdown.Item style={pageSize === 30 ? {color:"white",backgroundColor:"#43B6D7"} : {}} disabled={props.submissions.length < 30} onClick={() => onPageSizeChange(30)}>30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <span class="alert alert-primary" style={{marginLeft: "1.3rem" }}>Σελίδα {page + 1} Από {numberOfPages === 0 ? numberOfPages + 1 : numberOfPages}</span>
                    </> : <></>}
                {props.submissions.length < 10 ? <></>
                    : <Button variant="info" onClick={clickDisplayAll} style={{ marginLeft: "1.3rem" }}> {!displayAll ? "Εμφάνιση όλων" : "Εμφάνιση Σελίδων"}</Button>
                }
            </div>
        </div>
    )
}

export { FormsSubmissionGrid }