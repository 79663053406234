import React from 'react'
import SubmissionRow from './SubmissionRow';


const SubmissionsInForm = ({ submissionsInForm, forms:userArchivedForms }) => {
    // console.log('submissionsInForm', submissionsInForm)
    // console.log('userArchivedForms', userArchivedForms)

    return Object.keys(submissionsInForm).map(key => (
        <SubmissionRow key={submissionsInForm[key]._id} submission={submissionsInForm[key]} form={userArchivedForms.find(form => form._id === submissionsInForm[key].form)}/>
    ))
}

export default SubmissionsInForm