import getFormGreekLabels from "./getFormGreekLabels";

const manipulateSubmissionsForExport = (data, submissions, form) => {
    //console.log(submissions)
    //update file link url
    /* eslint-disable no-unused-vars */
    //console.log("data ", data)

    const regExUTCDates = /[0-9]{4}-[0-9]{2}-[0-9]{2}\s[A-Za-z0-9]+:([A-Za-z0-9]+( [A-Za-z0-9]+)+)/gi
    
    let updateDatesData = data.replaceAll(regExUTCDates, match => {
        let tempDate = new Date(match)
        //console.log("tempDate in UTC", tempDate)
        let strDate = `${tempDate.getDate()}/${tempDate.getMonth() + 1}/${tempDate.getFullYear()}`
	    //let strTime = `${(tempDate.getHours() < 10 ? '0' : '') + tempDate.getHours()}:${tempDate.getMinutes() < 10 ? '0' : ''}${tempDate.getMinutes()}`
        let newDateFormat = strDate + " " //+ strTime
        return newDateFormat
    })
    data = updateDatesData

    let ISO_8601_FULL = /[0-9]{4}-[0-9]{2}-[0-9]{2}T\d\d:\d\d:\d\d\.\d\d\dZ/gi
    let updateDatesDataISO_8601 = data.replaceAll(ISO_8601_FULL, match => {
        let tempDate = new Date(match)
        //console.log("tempDate in ISO8601", tempDate)
        let strDate = `${tempDate.getDate()}/${tempDate.getMonth() + 1}/${tempDate.getFullYear()}`
	    //let strTime = `${(tempDate.getHours() < 10 ? '0' : '') + tempDate.getHours()}:${tempDate.getMinutes() < 10 ? '0' : ''}${tempDate.getMinutes()}`
        let newDateFormat = strDate + " " //+ strTime
        return newDateFormat
    })
    data = updateDatesDataISO_8601

    //console.log("new data", data)

    for (const [key1, submissionValue] of Object.entries(submissions)) {
        if (submissionValue && submissionValue.data && (submissionValue.data !== undefined)) {
            /* eslint-disable no-unused-vars */
            for (const [key2, value] of Object.entries(submissionValue.data)) {
                if (typeof value === 'object' && Array.isArray(value)) {
                    if (value[0] && value[0].storage === 'url') {
                        if (value.length > 1) {
                            let tempMultipleFilesInOneFileField = ""
                            let whatToReplaceInDataString = ""
                            for (let i = 0; i < value.length; i++) {
                                tempMultipleFilesInOneFileField += value[i].url.replace("%2F","/") + " - "
                                whatToReplaceInDataString += value[i].name + ", "
                            }
                            tempMultipleFilesInOneFileField += ","
                            whatToReplaceInDataString = whatToReplaceInDataString.slice(0, -1)
                            data = data.replace(whatToReplaceInDataString, tempMultipleFilesInOneFileField)
                        } else {
                            data = data.replace(value[0].name, value[0].url.replace("%2F","/"))
                        }
                    }
                }
            }
        }

    }
    //update file link url

    //fix multiple files in Base64 file field
    /* eslint-disable no-unused-vars */
    for (const [key1, submissionValue] of Object.entries(submissions)) {
        if (submissionValue && submissionValue.data && (submissionValue.data !== undefined)) {
            /* eslint-disable no-unused-vars */
            for (const [key2, value] of Object.entries(submissionValue.data)) {
                if (typeof value === 'object' && Array.isArray(value)) {
                    if (value[0] && value[0].storage === 'base64') {
                        if (value.length > 1) {
                            let tempMultipleFilesInOneFileField = ""
                            let whatToReplaceInDataString = ""
                            for (let i = 0; i < value.length; i++) {
                                tempMultipleFilesInOneFileField += value[i].name + " - "
                                whatToReplaceInDataString += value[i].name + ", "
                            }
                            tempMultipleFilesInOneFileField += ","
                            whatToReplaceInDataString = whatToReplaceInDataString.slice(0, -1)
                            data = data.replace(whatToReplaceInDataString, tempMultipleFilesInOneFileField)
                        } else {
                            //data = data.replace(value[0].name, value[0].url.replace("%2F","/"))
                        }
                    }
                }
            }
        }

    }
    //fix multiple files in Base64 file field

    //fix back tick
    data = data.replace(/`/g, '')
    
    //fix url chars for xls
    /* let decodedURL = decodeURI(data);
    data = decodedURL */

    let subIdsWithSubmittedState = new Set();
    let subIdsWithDraftState = new Set();

    submissions.forEach(sub => {
        if (sub.metadata.state === 'submitted') {
            subIdsWithSubmittedState.add(sub._id);
        }
    });

    submissions.forEach(sub => {
        if (sub.metadata.state === 'draft') {
            subIdsWithDraftState.add(sub._id);
        }
    });

    let dataFirstRow = data.split('\n')[0];

    let labels
    if(Array.isArray(form)) {
        labels = getFormGreekLabels(form[0]);
    } else {
        labels = getFormGreekLabels(form.form);
    }
    

    for (const key in labels) {
        if (dataFirstRow.includes(key)) {
            dataFirstRow = dataFirstRow.replace(key, labels[key]);
        }
    }

    var headers = dataFirstRow.split(',');

    var labelsKeys = Object.keys(labels);
    var headersValues = Object.values(headers);

    for (let index = 0; index < headersValues.length; index++) {
        labelsKeys.forEach(labelKey => {
            if (headersValues[index].includes(labelKey)) {
                headersValues[index] = headersValues[index].replace(labelKey, labels[labelKey]);
            }
        });
    }

    var lines = data.split('\n');
    lines.splice(0, 1);

    let newLines = lines.join('\n');
    let linesArray = newLines.split('\n').slice('\n');

    let newLinesDraft = lines.join('\n');

    // Kratame mono ta lines poy exoyn id sto Set subIdsWithSubmittedState
    let linesArraySavedSubmissions = linesArray.filter(line => {
        let id = line.split(/,/)[0];
        return subIdsWithSubmittedState.has(id);
    });

    let linesArrayDraftSubmissions = linesArray.filter(line => {
        let id = line.split(/,/)[0];
        return subIdsWithDraftState.has(id);
    });

    newLines = linesArraySavedSubmissions.join('\n');
    newLinesDraft = linesArrayDraftSubmissions.join('\n');

    let linesFinal = headersValues + '\n' + newLines;
    let linesFinalDraft = headersValues + '\n' + newLinesDraft;

    return { linesFinal, linesFinalDraft }
}

export default manipulateSubmissionsForExport
