//as seen on https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
import React from 'react'
import {Prompt} from 'react-router-dom'
import {Button,  Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Messages} from '../config'

export class RouteLeavingGuard extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false,
            cancel: false
        }

        this.toggleCancel = this.toggleCancel.bind(this);

    }

    showModal = (location) => this.setState({
        modalVisible: true,
        lastLocation: location,
    })

    closeModal = (callback) => this.setState({
        modalVisible: false
    }, callback)

    toggleCancel() {
        this.setState(state => {
            return {
                cancel: !this.state.cancel
            };
        });
    }


    handleBlockedNavigation = (nextLocation) => {
       
        const {confirmedNavigation} = this.state
        // eslint-disable-next-line no-unused-vars
        const {shouldBlockNavigation, userRole} = this.props

        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)){
            this.showModal(nextLocation)
            this.setState ({cancel: true}) 
            // this.state.cancel = true
            return false
        }
       
        return true
    }


    handleConfirmNavigationClick = () => this.closeModal(() => {
        const {navigate, userRole } = this.props
        const {lastLocation} = this.state

        if (lastLocation) {
            this.setState({
                confirmedNavigation: true
            }, () => {
                if(lastLocation.pathname.includes("userSubmission")) {
                    window.location.replace(`/#/form/userlistforms`);
                    //window.location.replace('/');
                }
                if(lastLocation.pathname.includes("submission")) {
                    if(userRole === 'adminRole' ) {
                        window.location.replace('/#/form/adminlistforms');
                    } else if (userRole === 'formbuilderRole') {
                        window.location.replace('/#/form/formbuilderlistforms');
                    } else {
                        window.location.replace('/#/form/');
                    }
                } 
                // Navigate to the previous blocked location with your navigate function     
                navigate(lastLocation.pathname)
            })
        }
    })

    render() {
        const {when} = this.props
        const {cancel} = this.state

        return (
            <>
                <Prompt
                when={when}
                message={this.handleBlockedNavigation}/>

                <Modal  isOpen={cancel} 
                        toggle={this.toggleCancel}
						className={'modal-danger' + this.props.className}>
                    <ModalHeader toggle={this.toggleCancel}>Προειδοποίηση</ModalHeader>
                    <ModalBody>
                        {Messages.MSG_NOT_SAVED}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" 
                                onClick={this.handleConfirmNavigationClick}>Δεν επιθυμώ αποθήκευση
                        </Button>{' '}
                        <Button color="secondary" onClick={this.toggleCancel}>Ακύρωση</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
export default RouteLeavingGuard