export default [{
  "id": 1,
  "value": "Ανατολική Μακεδονία και Θράκη",
  "label": "ΑΝΑΤΟΛΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ ΚΑΙ ΘΡΑΚΗΣ",
  "perfectures": [
    {"id":1, "region_id":1, "value": "ΔΡΑΜΑΣ", "label": "ΔΡΑΜΑΣ" },
    {"id":2, "region_id":1, "value": "ΚΑΒΑΛΑΣ", "label": "ΚΑΒΑΛΑΣ" },
    {"id":3, "region_id":1, "value": "ΞΑΝΘΗΣ", "label": "ΞΑΝΘΗΣ" },
    {"id":4, "region_id":1, "value": "ΡΟΔΟΠΗΣ", "label": "ΡΟΔΟΠΗΣ"},
    {"id":5, "region_id":1, "value":"ΕΒΡΟΥ", "label":"ΕΒΡΟΥ"  }]
}, {
  "id": 2,
  "value": "Αττική",
  "label": "ΑΤΤΙΚΗΣ",
  "perfectures": [
    {"id":6, "region_id":2, "value":"ΠΕΙΡΑΙΩΣ", "label":"ΠΕΙΡΑΙΩΣ"},
    {"id":7, "region_id":2, "value":"ΒΟΡΕΙΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ", "label":"ΒΟΡΕΙΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ"},
    {"id":8, "region_id":2, "value":"ΔΥΤΙΚΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ", "label":"ΔΥΤΙΚΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ"},
    {"id":9, "region_id":2, "value":"ΚΕΝΤΡΙΚΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ", "label":"ΚΕΝΤΡΙΚΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ"},
    {"id":99, "region_id":2, "value":"ΝΟΤΙΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ", "label":"ΝΟΤΙΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ"}]
}, {
  "id": 3,
  "value": "Βόρειο Αιγαίο",
  "label": "ΒΟΡΕΙΟΥ ΑΙΓΑΙΟΥ",
  "perfectures": [
    {"id":10, "region_id":3, "value": "ΙΚΑΡΙΑΣ", "label": "ΙΚΑΡΙΑΣ" },
    {"id":11, "region_id":3, "value": "ΛΕΣΒΟΥ", "label": "ΛΕΣΒΟΥ" },
    {"id":12, "region_id":3, "value": "ΣΑΜΟΥ", "label": "ΣΑΜΟΥ" },
    {"id":13, "region_id":3, "value": "ΧΙΟΥ", "label": "ΧΙΟΥ" },
    {"id":14, "region_id":3, "value": "ΛΗΜΝΟΥ", "label": "ΛΗΜΝΟΥ" }]
}, {
  "id": 4,
  "value": "Δυτική Ελλάδα",
  "label": "ΔΥΤΙΚΗΣ ΕΛΛΑΔΑΣ",
  "perfectures": [
    {"id":15, "region_id":4, "value": "ΑΙΤΩΛΟΑΚΑΡΝΑΝΙΑΣ", "label": "ΑΙΤΩΛΟΑΚΑΡΝΑΝΙΑΣ" },
    {"id":16, "region_id":4, "value": "ΑΧΑΪΑΣ", "label": "ΑΧΑΪΑΣ" },
    {"id":17, "region_id":4, "value": "ΗΛΕΙΑΣ", "label": "ΗΛΕΙΑΣ" }]
}, {
  "id": 5,
  "value": "Δυτική Μακεδονία",
  "label": "ΔΥΤΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ",
  "perfectures": [
    {"id":18, "region_id":5, "value": "ΓΡΕΒΕΝΩΝ", "label": "ΓΡΕΒΕΝΩΝ" },
    {"id":19, "region_id":5, "value": "ΚΑΣΤΟΡΙΑΣ", "label": "ΚΑΣΤΟΡΙΑΣ" },
    {"id":20, "region_id":5, "value": "ΚΟΖΑΝΗΣ", "label": "ΚΟΖΑΝΗΣ" },
    {"id":21, "region_id":5, "value": "ΦΛΩΡΙΝΑΣ", "label": "ΦΛΩΡΙΝΑΣ" }]
}, {
  "id": 6,
  "value": "Ήπειρος",
  "label": "ΗΠΕΙΡΟΥ",
  "perfectures": [
    {"id":22, "region_id":6, "value": "ΑΡΤΑΣ", "label": "ΑΡΤΑΣ" },
    {"id":23, "region_id":6, "value": "ΘΕΣΠΡΩΤΙΑΣ", "label": "ΘΕΣΠΡΩΤΙΑΣ" },
    {"id":24, "region_id":6, "value": "ΙΩΑΝΝΙΝΩΝ", "label": "ΙΩΑΝΝΙΝΩΝ" },
    {"id":25, "region_id":6, "value": "ΠΡΕΒΕΖΑΣ", "label": "ΠΡΕΒΕΖΑΣ" }]
}, {
  "id": 7,
  "value": "Θεσσαλία",
  "label": "ΘΕΣΣΑΛΙΑΣ",
  "perfectures": [
    {"id":26, "region_id":7, "value": "ΚΑΡΔΙΤΣΑΣ", "label": "ΚΑΡΔΙΤΣΑΣ" },
    {"id":27, "region_id":7, "value": "ΛΑΡΙΣΑΣ", "label": "ΛΑΡΙΣΑΣ" },
    {"id":28, "region_id":7, "value": "ΜΑΓΝΗΣΙΑΣ", "label": "ΜΑΓΝΗΣΙΑΣ" },
    {"id":29, "region_id":7, "value": "ΣΠΟΡΑΔΩΝ", "label": "ΣΠΟΡΑΔΩΝ" },
    {"id":30, "region_id":7, "value": "ΤΡΙΚΑΛΩΝ", "label": "ΤΡΙΚΑΛΩΝ" }]
}, {
  "id": 8,
  "value": "Ιόνιοι Νήσοι",
  "label": "ΙΟΝΙΩΝ ΝΗΣΩΝ",
  "perfectures": [
    {"id":31, "region_id":8, "value": "ΚΕΡΚΥΡΑΣ", "label": "ΚΕΡΚΥΡΑΣ" },
    {"id":32, "region_id":8, "value": "ΖΑΚΥΝΘΟΥ", "label": "ΖΑΚΥΝΘΟΥ" },
    {"id":33, "region_id":8, "value": "ΛΕΥΚΑΔΑΣ", "label": "ΛΕΥΚΑΔΑΣ" },
    {"id":34, "region_id":8, "value": "ΚΕΦΑΛΛΗΝΊΑΣ", "label": "ΚΕΦΑΛΛΗΝΊΑΣ" },
    {"id":35, "region_id":8, "value": "ΙΘΑΚΗΣ", "label": "ΙΘΑΚΗΣ" }]
}, {
  "id": 9,
  "value": "Κεντρική Μακεδονία",
  "label": "ΚΕΝΤΡΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ",
  "perfectures": [
    {"id":36, "region_id":9, "value": "ΘΕΣΣΑΛΟΝΙΚΗΣ", "label": "ΘΕΣΣΑΛΟΝΙΚΗΣ" },
    {"id":37, "region_id":9, "value": "ΗΜΑΘΙΑΣ", "label": "ΗΜΑΘΙΑΣ" },
    {"id":38, "region_id":9, "value": "ΚΙΛΚΙΣ", "label": "ΚΙΛΚΙΣ" },
    {"id":39, "region_id":9, "value": "ΠΕΛΛΑΣ", "label": "ΠΕΛΛΑΣ" },
    {"id":40, "region_id":9, "value": "ΠΙΕΡΙΑΣ", "label": "ΠΙΕΡΙΑΣ" },
    {"id":41, "region_id":9, "value": "ΣΕΡΡΩΝ", "label": "ΣΕΡΡΩΝ" },
    {"id":42, "region_id":9, "value": "ΧΑΛΚΙΔΙΚΗΣ", "label": "ΧΑΛΚΙΔΙΚΗΣ" }]
}, {
  "id": 10,
  "value": "Κρήτη",
  "label": "ΚΡΗΤΗΣ",
  "perfectures": [
    {"id":43, "region_id":10, "value": "ΗΡΑΚΛΕΙΟΥ", "label": "ΗΡΑΚΛΕΙΟΥ" },
    {"id":44, "region_id":10, "value": "ΛΑΣΙΘΙΟΥ", "label": "ΛΑΣΙΘΙΟΥ" },
    {"id":45, "region_id":10, "value": "ΡΕΘΥΜΝΟΥ", "label": "ΡΕΘΥΜΝΟΥ" },
    {"id":46, "region_id":10, "value": "ΧΑΝΙΩΝ", "label": "ΧΑΝΙΩΝ" }]
}, {
  "id": 11,
  "value": "Νότιο Αιγαίο",
  "label": "ΝΟΤΙΟΥ ΑΓΑΙΟΥ",
  "perfectures": [
    {"id":47, "region_id":11, "value": "ΆΝΔΡΟΥ", "label": "ΆΝΔΡΟΥ" },
    {"id":48, "region_id":11, "value": "ΘΥΡΑΣ", "label": "ΘΥΡΑΣ" },
    {"id":49, "region_id":11, "value": "ΚΑΛΥΜΝΟΥ", "label": "ΚΑΛΥΜΝΟΥ" },
    {"id":50, "region_id":11, "value": "ΚΑΡΠΑΘΟΥ", "label": "ΚΑΡΠΑΘΟΥ" },
    {"id":51, "region_id":11, "value": "ΚΕΑΣ-ΚΥΝΘΟΥ", "label": "ΚΕΑΣ-ΚΥΝΘΟΥ" },
    {"id":52, "region_id":11, "value": "ΚΩ", "label": "ΚΩ" },
    {"id":53, "region_id":11, "value": "ΜΗΛΟΥ", "label": "ΜΗΛΟΥ" },
    {"id":54, "region_id":11, "value": "ΜΥΚΟΝΟΥ", "label": "ΜΥΚΟΝΟΥ" },
    {"id":55, "region_id":11, "value": "ΝΑΞΟΥ", "label": "ΝΑΞΟΥ" },
    {"id":56, "region_id":11, "value": "ΠΑΡΟΥ", "label": "ΠΑΡΟΥ" },
    {"id":57, "region_id":11, "value": "ΡΟΔΟΥ", "label": "ΡΟΔΟΥ" },
    {"id":58, "region_id":11, "value": "ΣΥΡΟΥ", "label": "ΣΥΡΟΥ" }]
}, {
  "id": 12,
  "value": "Πελοπόννησος",
  "label": "ΠΕΛΟΠΟΝΝΗΣΟΥ",
  "perfectures": [
    {"id":60, "region_id":12, "value": "ΑΡΓΟΛΙΔΑΣ", "label": "ΑΡΓΟΛΙΔΑΣ" },
    {"id":61, "region_id":12, "value": "ΑΡΚΑΔΙΑΣ", "label": "ΑΡΚΑΔΙΑΣ" },
    {"id":62, "region_id":12, "value": "ΚΟΡΙΝΘΙΑΣ", "label": "ΚΟΡΙΝΘΙΑΣ" },
    {"id":63, "region_id":12, "value": "ΛΑΚΩΝΙΑΣ", "label": "ΛΑΚΩΝΙΑΣ" },
    {"id":64, "region_id":12, "value": "ΜΕΣΣΗΝΙΑΣ", "label": "ΜΕΣΣΗΝΙΑΣ" }]
}, {
  "id": 13,
  "value": "Στερεά Ελλάδα",
  "label": "ΣΤΕΡΕΑΣ ΕΛΛΑΔΑΣ",
  "perfectures": [
    {"id":65, "region_id":13, "value": "ΒΟΙΩΤΙΑΣ", "label": "ΒΟΙΩΤΙΑΣ" },
    {"id":66, "region_id":13, "value": "ΕΥΒΟΙΑΣ", "label": "ΕΥΒΟΙΑΣ" },
    {"id":67, "region_id":13, "value": "ΕΥΡΥΤΑΝΙΑΣ", "label": "ΕΥΡΥΤΑΝΙΑΣ" },
    {"id":68, "region_id":13, "value": "ΦΘΙΩΤΙΔΑΣ", "label": "ΦΘΙΩΤΙΔΑΣ" },
    {"id":69, "region_id":13, "value": "ΦΩΚΙΔΑΣ", "label": "ΦΩΚΙΔΑΣ" }]
}]
