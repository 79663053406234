/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */

import { FormGrid } from 'react-formio';
import React from 'react';
import _isFunction from 'lodash/isFunction';
import _get from 'lodash/get';
import { stopPropagationWrapper } from './utils/utils';

function getTag(iconStr) {
	if(iconStr === 'star') {
		return 'Προεπισκόπηση'
	} else if (iconStr === 'pencil') {
		return 'Υποβολή'
	}
	else if (iconStr === 'eye') {
		return 'Δεδομένα'
	}
	else if (iconStr === 'edit') {
		return 'Επεξεργασία'
	}
	else if (iconStr === 'trash') {
		return 'Διαγραφή'
	}
 }

export default class YpepthFormGrid extends FormGrid {
	Cell = ({ row: form_Current_Table_Row, column }) => {
		const { formAccess, onAction, operations = [] } = this.props;
		const access = formAccess(form_Current_Table_Row);

		if (column.key === 'title') {
			return (
				<span
					role="button"
					tabIndex={0}
					style={{ cursor: 'pointer' }}
					onClick={stopPropagationWrapper(() => {
						if (!operations.some(item => item.action === 'userSubmissions')) {
							onAction(form_Current_Table_Row, 'edit');
						} else if (access.submission.create) {
							onAction(form_Current_Table_Row, 'userSubmissions');
						}
					})}
				>
					<h5>{form_Current_Table_Row.title}</h5>
				</span>
			);
		}

		if (column.key === 'properties.customOwnerEmail') {
			const Email = form_Current_Table_Row.properties.customOwnerSelectedAdminRole === 'adminRole' ? `<span style="color:#A30000;font-weight:bold">[ A ]</span> ${form_Current_Table_Row.properties.customOwnerEmail}` : `<span style="color:#004200;font-weight:bold">[ C ]</span> ${form_Current_Table_Row.properties.customOwnerEmail}`;

			return <span dangerouslySetInnerHTML={{ __html: Email }}></span>;
		}

		if (column.key === 'properties.status') {
			let formStatus

			if (form_Current_Table_Row.properties.status === "working") {
				formStatus = `<span title="Επεξεργασία" style="color:blue;font-size:10pt;font-weight:bold">- E -</span>`
			} else if (form_Current_Table_Row.properties.status === "published") {
				if (new Date(form_Current_Table_Row.properties.endDate).getTime() < new Date().getTime()) {
					formStatus = `<span title="Δημοσιευμένη, σε Λήξη" style="color:#703E00;font-size:10pt;font-weight:bold">- ΔΛ -</span>`

				}
				else if (new Date(form_Current_Table_Row.properties.startDate).getTime() > new Date().getTime()) {
					formStatus = `<span title="Δημοσιευμένη, σε Αναμονή" style="color:#104F65;font-size:10pt;font-weight:bold">- ΔΑ -</span>`
				}
				else {
					formStatus = `<span title="Δημοσιευμένη" style="color:DarkGreen;font-size:10pt;font-weight:bold">- Δ - </span>`
				}
			} else if (form_Current_Table_Row.properties.status === "archived") {
				formStatus = `<span title="Αρχειοθετημένη" style="color:#A30000;font-size:10pt;font-weight:bold">- Α -</span>`
			}

			return (
				<div style = {{textAlign: 'center'}}
					/* style={{
						textAlign: 'center',
						backgroundColor: '#25ebc4',
						color: 'white',
						padding: '4px 8px',
						textAlign: 'center',
						borderRadius: '5px'
					}} */> <span dangerouslySetInnerHTML={{ __html: formStatus }}></span>
				</div >
			)
		}

		if (column.key === 'operations') {
			return (
				<>
					{operations.map(({ action, buttonType = 'primary', icon = '', permissionsResolver = () => true, title = '' }) =>
					
						permissionsResolver(form_Current_Table_Row) ? (
							
							<span title={
								getTag(`${icon}`)
								} role="button" tabIndex={0} className={`btn btn-${buttonType} 
								btn-sm form_Current_Table_Row-btn`}
								onClick={stopPropagationWrapper(() => onAction(form_Current_Table_Row, action))}
								key={action}>
									<span >
										<i className={`fa fa-${icon}`} />
										&nbsp;
									</span>
							</span>
						) : null,
					)}
				</>
			);
		}
		return <span>{_isFunction(column.value) ? column.value(form_Current_Table_Row) : _get(form_Current_Table_Row, column.key, '')}</span>;
	};
}
