/******************************************************************************************
 * filterUserForm
 *
 * 23-03-2023 - Fixed the TypeError: Cannot read properties of undefined (reading 'includes')
 * 			  - Deleted all NULLs from 	recieversIndex
 * 			  - Fixed wrong comparison between String and Array of userData.BasicInfo.unit_description
 * 			  - Added some extra optional chainings to check_school_units
 *
 ********************************************************************************************/

const recieversIndex = [
	'ΠΕΡΙΦΕΡΕΙΑΚΗ ΔΙΕΥΘΥΝΣΗ ΕΚΠΑΙΔΕΥΣΗΣ',
	'ΔΙΕΥΘΥΝΣΗ ΠΡΩΤΟΒΑΘΜΙΑΣ ΕΚΠΑΙΔΕΥΣΗΣ',
	'ΔΙΕΥΘΥΝΣΗ ΔΕΥΤΕΡΟΒΑΘΜΙΑΣ ΕΚΠΑΙΔΕΥΣΗΣ',
	'ΓΕΝΙΚΑ ΑΡΧΕΙΑ',
	'ΔΗΜΟΣΙΑ ΒΙΒΛΙΟΘΗΚΗ',
	'ΚΕΝΤΡΟ ΠΕΡΙΒΑΛΛΟΝΤΙΚΗΣ ΕΚΠΑΙΔΕΥΣΗΣ',
	'ΠΕ.Κ.Ε.Σ.',
	'ΚΕΣΥ',
	'ΚΕΑ',
	'ΕΚΦΕ',
	'ΕΚΠΑΙΔΕΥΤΙΚΗ ΜΟΝΑΔΑ ΝΗΠΙΑΓΩΓΕΙΟ',
	'ΕΚΠΑΙΔΕΥΤΙΚΗ ΜΟΝΑΔΑ ΔΗΜΟΤΙΚΟ',
	'ΕΚΠΑΙΔΕΥΤΙΚΗ ΓΥΜΝΑΣΙΟ',
	'ΕΚΠΑΙΔΕΥΤΙΚΗ ΜΟΝΑΔΑ ΛΥΚΕΙΟ',
	'ΕΚΠΑΙΔΕΥΤΙΚΗ ΜΟΝΑΔΑ ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ',
	'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΝΗΠΙΑΓΩΓΕΙΟ',
	'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΔΗΜΟΤΙΚΟ',
	'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΓΥΜΝΑΣΙΟ',
	'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΛΥΚΕΙΟ',
	'ΕΚΠΑΙΔΕΥΤΙΚΟΣ ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ',
	'ΜΑΘΗΤΗΣ ΝΗΠΕΙΑΓΩΓΕΙΟ',
	'ΜΑΘΗΤΗΣ ΔΗΜΟΤΙΚΟ',
	'ΜΑΘΗΤΗΣ ΓΥΜΝΑΣΙΟ',
	'ΜΑΘΗΤΗΣ ΛΥΚΕΙΟ',
	'ΜΑΘΗΤΗΣ ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ',
];

function check_gsis(userData, formItem) {
	//console.log("start gsis check for form "+formItem.title)
	/*  if ((formItem.properties.recievers) && (userData.BasicInfo.unit_description === "GSIS") && (formItem.properties.recievers.includes("ΓΓΠΣ")))
        return true;
    else
        return false; */

	if (formItem.properties.recievers && userData.BasicInfo.unit_description && userData.BasicInfo.unit_description.includes('GSIS') && formItem.properties.recievers.includes('ΓΓΠΣ')) return true;
	else return false;
}

function check_psd(userData, formItem) {
	//console.log("start psd check for form "+formItem.title)

	if (formItem.properties.recievers && !userData.BasicInfo.unit_description.includes('GSIS') && formItem.properties.recievers.includes('ΠΣΔ')) return true;
	else return false;
}

function check_support_units(userData, formItem) {
	if ((formItem.properties.recievers && formItem.properties.recievers.includes('ΓΓΠΣ')) || userData.BasicInfo.unit_description.includes('GSIS')) return false;

	if (formItem.properties.recievers)
		for (let i = 0; i < formItem.properties.recievers.length; i++) {
			let x = parseInt(formItem.properties.recievers[i]);

			if (!isNaN(x) && x < 10)
				if (x < 3) {
					//NanN in case of gsis, psd
					// pde,dde,dpe
					switch (x) {
						case 0:
							if (check_region(userData, formItem) && (userData.BasicInfo.name.includes(recieversIndex[x]) || userData.BasicInfo.unit_description.includes('ΠΔΕ'))) return true;
							break;

						case 1: //dpe
							if (check_region(userData, formItem) && (userData.BasicInfo.unit_description.includes(recieversIndex[x]) || userData.BasicInfo.unit_description.includes('ΔΙΕΥΘΥΝΣΗ Π.Ε.'))) return true;
							break;

						case 2: // dde
							if (check_region(userData, formItem) && (userData.BasicInfo.unit_description.includes(recieversIndex[x]) || userData.BasicInfo.unit_description.includes('ΔΙΕΥΘΥΝΣΗ Δ.Ε.'))) return true;
							break;

						default:
							break;
					}
				} // all other units
				else if (userData.BasicInfo.name.includes(recieversIndex[x])) return true;
		}
	return false;
}

/* function check_main_units(userData,formItem){

    //console.log("start main unit check for form "+formItem.title)

    const mainUnits = ["ΠΕΡΙΦΕΡΕΙΑΚΗ ΔΙΕΥΘΥΝΣΗ ΕΚΠΑΙΔΕΥΣΗΣ","ΔΙΕΥΘΥΝΣΗ ΠΡΩΤΟΒΑΘΜΙΑΣ ΕΚΠΑΙΔΕΥΣΗΣ","ΔΙΕΥΘΥΝΣΗ ΔΕΥΤΕΡΟΒΑΘΜΙΑΣ ΕΚΠΑΙΔΕΥΣΗΣ"]
    
    if (((formItem.properties.recievers)) && (userData.BasicInfo.status.includes("ΔΙΟΙΚΗΤΙΚΗ ΜΟΝΑΔΑ")) ) {
        
        for(let i=0;i<mainUnits.length;i++){
            if(userData.BasicInfo.name.includes(mainUnits[i]) && (formItem.properties.recievers.includes(mainUnits[i])) )
            return true
        }        
    }
        return false
} */

function check_school_units(userData, formItem) {
	if (formItem.properties.recievers && userData?.UnitInfo?.[0].category?.includes('ΣΧΟΛΙΚΕΣ ΜΟΝΑΔΕΣ')) {
		for (let i = 0; i < formItem.properties.recievers.length; i++) {
			let x = parseInt(formItem.properties.recievers[i]);
			switch (x) {
				case 10: // nipiagogeio
					if (userData.UnitInfo[0].unit_type.includes('ΝΗΠΙΑΓΩΓΕΙΟ')) return true;
					break;
				case 11: //dimotiko
					if (userData.UnitInfo[0].unit_type.includes('ΔΗΜΟΤΙΚΟ')) return true;
					break;
				case 12: //gym
					if (userData.UnitInfo[0].unit_type.includes('ΓΥΜΝΑΣΙΟ')) return true;
					break;
				case 13: //lyceum
					if (userData.UnitInfo[0].unit_type.includes('ΓΕΝΙΚΟ ΛΥΚΕΙΟ')) return true;
					break;
				case 14: //epal
					if (userData.UnitInfo[0].unit_type.includes('ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ')) return true;
					break;
				default:
					break;
			}
		}
	}
	return false;
}

function check_student(userData, formItem) {
	//console.log("start student check for form "+formItem.title)

	if (formItem.properties.recievers && userData.BasicInfo.status.includes('ΜΑΘΗΤΗΣ')) {
		for (let i = 0; i < formItem.properties.recievers.length; i++) {
			let x = parseInt(formItem.properties.recievers[i]);
			switch (x) {
				case 30: // nipiagogeio
					if (userData.UnitInfo[0].unit_type.includes('ΝΗΠΙΑΓΩΓΕΙΟ')) return true;
					break;
				case 31: //dimotiko
					if (userData.UnitInfo[0].unit_type.includes('ΔΗΜΟΤΙΚΟ')) return true;
					break;
				case 32: //gym
					if (userData.UnitInfo[0].unit_type.includes('ΓΥΜΝΑΣΙΟ')) return true;
					break;
				case 33: //lyceum
					if (userData.UnitInfo[0].unit_type.includes('ΓΕΝΙΚΟ ΛΥΚΕΙΟ')) return true;
					break;
				case 34: //epal
					if (userData.UnitInfo[0].unit_type.includes('ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ')) return true;
					break;
				default:
					break;
			}
		}
	}
	return false;
}

function check_perfecture(userData, formItem) {
	if (!formItem.properties.recieversPerfectures || formItem.properties.recieversPerfectures.length === 0) return true;
	else for (let i = 0; i < formItem.properties.recieversPerfectures.length; i++) if (userData.UnitInfo[0].prefecture.includes(formItem.properties.recieversPerfectures[i].label)) return true;
	return false;
}

function check_region(userData, formItem) {
	if (!formItem.properties.recieversRegions || formItem.properties.recieversRegions.length === 0) return true;
	else for (let i = 0; i < formItem.properties.recieversRegions.length; i++) if (userData.UnitInfo[0].region_edu_admin.includes(formItem.properties.recieversRegions[i].label)) return true;
	return false;
}

function check_regional_data(userData, formItem) {
	if ((formItem.properties.recievers && formItem.properties.recievers.includes('ΓΓΠΣ')) || userData.BasicInfo.unit_description.includes('GSIS')) return false;

	if (check_region(userData, formItem) && check_perfecture(userData, formItem)) {
		return true;
	}

	return false;
}

function check_teacher_branch(userData, formItem) {
	if (userData.BasicInfo.status.includes('ΕΚΠΑΙΔΕΥΤΙΚΟΣ'))
		if (!formItem.properties.recieversTeacherBranches || formItem.properties.recieversTeacherBranches.length === 0)
			//no branch limit exists
			return true;
		else {
			for (let i = 0; i < formItem.properties.recieversTeacherBranches.length; i++) if (userData.BasicInfo.branch.includes(formItem.properties.recieversTeacherBranches[i].value)) return true;
		}
	return false;
}

/* function check_teacher_in_support_units(userData, formItem) {

    if (userData.BasicInfo.status.includes("ΕΚΠΑΙΔΕΥΤΙΚΟΣ") && (userData.UnitInfo[1])) {  // teacher works in support unit
        // check if unit_type match
        for (let i = 0; i < formItem.properties.recievers.length; i++) {
            let x = parseInt(formItem.properties.recievers[i])

            if ((!isNaN(x)) && (x < 10) && (recieversIndex[x].includes(userData.UnitInfo[1].unit_type)))
                return true
        }
    }
    return false
}*/

function check_teacher(userData, formItem) {
	//console.log("start teacher check for form "+formItem.title)
	//check teacher branch
	if (!check_teacher_branch(userData, formItem)) return false;

	// chech teacher level
	if (formItem.properties.recievers && userData.BasicInfo.status.includes('ΕΚΠΑΙΔΕΥΤΙΚΟΣ')) {
		for (let i = 0; i < formItem.properties.recievers.length; i++) {
			let x = parseInt(formItem.properties.recievers[i]);
			switch (x) {
				case 20: // nipiagogeio
					if (userData.UnitInfo[0].unit_type.includes('ΝΗΠΙΑΓΩΓΕΙΟ')) return true;
					break;
				case 21: //dimotiko
					if (userData.UnitInfo[0].unit_type.includes('ΔΗΜΟΤΙΚΟ')) return true;
					break;
				case 22: //gym
					if (userData.UnitInfo[0].unit_type.includes('ΓΥΜΝΑΣΙΟ')) return true;
					break;
				case 23: //lyceum
					if (userData.UnitInfo[0].unit_type.includes('ΓΕΝΙΚΟ ΛΥΚΕΙΟ')) return true;
					break;
				case 24: //epal
					if (userData.UnitInfo[0].unit_type.includes('ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΛΥΚΕΙΟ')) return true;
					break;
				default:
					break;
			}
		}
	}
	return false;
}

function check_startDate(formItem) {
	// Don't list a Form if it is not yet availiable due to start date (form's start date > current date)
	if (Date.parse(formItem.properties.startDate) > Date.now()) return true;
	return false;
}

function check_endDate(formItem) {
	// Don't list a Form if it is expired (form's end date < current date)
	if (Date.parse(formItem.properties.endDate) < Date.now()) return true;
	return false;
}

const filterUserForms = (userData, forms) => {
	/* console.log("IN FILTERING userData", userData)
    console.log("IN FILTERING forms", forms) */
	let formsToRenderArray = [];
	//console.log("start reading forms")
	// for each form in database
	for (let i = 0; i < forms.forms.length; i++) {
		// if a forms startDate is later than NOW no need for other checks just continue

		if (check_startDate(forms.forms[i])) {
			continue;
		}

		//Check end date
		if (check_endDate(forms.forms[i])) {
			continue;
		}

		//console.log("form name "+forms.forms[i].title)
		//form for gsis users
		if (check_gsis(userData, forms.forms[i])) {
			formsToRenderArray.push(forms.forms[i]);
			continue;
		}

		//if form for all PSD
		if (check_psd(userData, forms.forms[i])) {
			formsToRenderArray.push(forms.forms[i]);
			continue;
		}

		//if form  for Support Unit
		if (check_support_units(userData, forms.forms[i])) {
			formsToRenderArray.push(forms.forms[i]);
			continue;
		}

		//check regional data

		if (check_regional_data(userData, forms.forms[i])) {
			if (check_school_units(userData, forms.forms[i])) formsToRenderArray.push(forms.forms[i]);
			else if (check_teacher(userData, forms.forms[i])) formsToRenderArray.push(forms.forms[i]);
			else if (check_student(userData, forms.forms[i])) formsToRenderArray.push(forms.forms[i]);
		}

		//console.log("form  "+forms.forms[i].title +"checked")
	}

	/* console.log("formsToRenderArray in filterUserForms", formsToRenderArray) */
	return formsToRenderArray;
};

export default filterUserForms;
