import removeCommasFromLabel from "./removeCommasFromLabel"

const getFormGreekLabels = (form) => {
    const labels = {}
    if (form.components !== undefined) {
        for (let i = 0; i < form.components.length; i++) {
            let tempTitle
            let tempLabel

            if (form.components[i].title !== undefined) {
                if (form.components[i].title.includes(",")) {
                    tempTitle = removeCommasFromLabel(form.components[i].title)
                } else {
                    tempTitle = form.components[i].title
                }
                labels[form.components[i].key] = form.components[i].tempTitle
                if (form.components[i].components !== undefined) {
                    for (let j = 0; j < form.components[i].components.length; j++) {
                        if (form.components[i].components[j].label.includes(",")) {
                            tempLabel = removeCommasFromLabel(form.components[i].components[j].label)
                            labels[form.components[i].components[j].key] = tempTitle + '.' + tempLabel
                        }
                        else if (form.components[i].components[j].type === "columns") {
                            for (let m = 0; m < form.components[i].components[j].columns.length; m++) {
                                for (let k = 0; k < form.components[i].components[j].columns[m].components.length; k++) {
                                    if (form.components[i].components[j].columns[m].components[k].label.includes(",")) {
                                        tempLabel = removeCommasFromLabel(form.components[i].components[j].columns[m].components[k].label)
                                        labels[form.components[i].components[j].columns[m].components[k].key] = tempTitle + '.' + tempLabel
                                    }
                                    else {
                                        labels[form.components[i].components[j].columns[m].components[k].key] = tempTitle + '.' + form.components[i].components[j].columns[m].components[k].label
                                    }
                                }
                            }
                        }
                        else {
                            labels[form.components[i].components[j].key] = tempTitle + '.' + form.components[i].components[j].label
                        }
                    }
                }
            } else if (form.components[i].type === "columns") {
                for (let j = 0; j < form.components[i].columns.length; j++) {
                    for (let k = 0; k < form.components[i].columns[j].components.length; k++) {
                        if (form.components[i].columns[j].components[k].label.includes(",")) {
                            tempLabel = removeCommasFromLabel(form.components[i].columns[j].components[k].label)
                            labels[form.components[i].columns[j].components[k].key] = tempLabel
                        }
                        else {
                            labels[form.components[i].columns[j].components[k].key] = form.components[i].columns[j].components[k].label
                        }
                    }
                }
            }
            else {
                if (form.components[i].label.includes(",")) {
                    tempLabel = removeCommasFromLabel(form.components[i].label)
                    labels[form.components[i].key] = tempLabel
                } else {
                    labels[form.components[i].key] = form.components[i].label
                }
            }
        }
    }
    return labels
}

export default getFormGreekLabels