import React from 'react'

function EndFormDateMessage(props) {
    return (
        <div className="alert alert-danger" role="alert">
            <h4>Η ημερομηνία υποβολής για τη Φόρμα <b>{props.formTitle}</b> έχει λήξει.</h4>
        </div>
    )
}

export default EndFormDateMessage