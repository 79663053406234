import React from 'react'
import { Badge, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useState } from 'react'

export const PinComponent = ({ setPinEntered, theProperties, form }) => {

  const [pinInputValue, setPinInputValue] = useState("");
  const [showPin, setShowPin] = useState(false);
  const [iconIsHovered, setIconIsHovered] = useState(false);

  const togglePinVisibility = () => {
    setShowPin(!showPin);
  };

  const handlePinInputValue = (e) => {
    setPinInputValue(e.target.value)
  }

  const iconHovered = () => {
    setIconIsHovered(!iconIsHovered)
  }

  const checkPin = (props) => {
    if (pinInputValue !== theProperties.pin) {
      toast.error('Λάθος PIN', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
      window.history.back();
    } else {
      setPinEntered(pinInputValue)
    }
  }

  const checkCancel = () => {
    window.history.back();
  }

  return (
    <div>
      <p></p>
      <h3><Badge color="danger">PIN</Badge></h3>
      <p>Εισάγετε <Badge>PIN</Badge> για την πρόσβαση στη φόρμα <strong>{form.title}</strong>.</p>
      <div class="mb-4 flex">
        <input type={showPin ? 'text' : 'password'}
          value={pinInputValue} class="form-control border border-primary" required pattern="[0-9]{6}" style={{ "width": "55pt" }}
          minlength="6"
          maxlength="6"
          onChange={handlePinInputValue} />
        <span class={
          showPin ? "fa fa-solid fa-eye-slash fa-2x flex justify-around items-center" :
            "fa fa-solid fa-eye fa-2x flex justify-around items-center"
        } aria-hidden="true"
          //style={{ display: "block", overflow: "hidden", paddingLeft: "5pt" }} 
          style={
            iconIsHovered ? {color: "#0A3545", paddingLeft: "5pt"} : {paddingLeft: "5pt"}
          }
          onMouseEnter={iconHovered}
          onMouseLeave={iconHovered}
          onClick={togglePinVisibility}>
        </span>
      </div>
      <p></p>
      <Button color="primary" onClick={checkPin}>
        Υποβολή
      </Button>{' '}
      <Button color="danger" onClick={checkCancel}>
        Άκυρο
      </Button>
    </div>
  )
} // Pin Component

