import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveForm, selectError, Errors, selectRoot } from 'react-formio';
import { push } from 'connected-react-router';
import translateGR from '../../translate/translateGR';
import FormEdit from '../../components/FormEdit';
import { FormConst } from '../../config';
import { Redirect } from 'react-router-dom';
import {setInitialSatrtDate, setInitialEndDate} from "../../helpers/updateStartAndEndFormDates"

const Create = class extends Component {
	render() {
		const { errors, form, saveForm, saveText, options, roles, auth } = this.props;

		if (auth === undefined || auth.authenticated === false || !auth.is.administrator || auth.user.data.selectedAdminRole !== 'adminRole') {
			return <Redirect to="/" />;
		}

		return (
			<div>
				<h3>Δημιουργία φόρμας</h3>
				<hr />
				<Errors errors={errors} />
				<FormEdit form={form} saveText={saveText} saveForm={saveForm} options={options} roles={roles} flagToHidePublishOptionFromCreate={true} gsis={false} />
			</div>
		);
	}
};

const mapStateToProps = state => {
	let initialFormStartDate = setInitialSatrtDate()
	let initialFormEndDate = setInitialEndDate()

	const auth = selectRoot('auth', state);

	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		auth: auth,
		form: {
			display: 'form',
			properties: {
				status: FormConst.WORKING,
				startDate: initialFormStartDate,
				endDate: initialFormEndDate,
				exportPDF: FormConst.EXPORT_PDF,
				numberOfSubmissions: FormConst.SINGLE_SUBMISSION,
				customOwnerID: auth.user._id,
				customOwnerName: auth.user.data.name,
				customOwnerEmail: auth.user.data.email,
				customOwnerPhonenumber: auth.user.data.phonenumber,
				customOwnerAgency: auth.user.data.agency,
				customOwnerSelectedAdminRole: auth.user.data.selectedAdminRole,
				sendUserEmail: FormConst.DO_NOT_NOTIFY_USER_BY_EMAIL,
				sendAdminEmail: FormConst.DO_NOT_NOTIFY_ADMIN_BY_EMAIL,
			},
			access: [
				{
					roles: [auth.roles.administrator._id, auth.roles.authenticated._id],
					type: 'read_all',
				},
			],
			submissionAccess: [
				{
					roles: [],
					type: 'create_all',
				},
				{
					roles: [],
					type: 'read_all',
				},
				{
					roles: [],
					type: 'update_all',
				},
				{
					roles: [],
					type: 'delete_all',
				},
				{
					roles: [auth.roles.authenticated._id],
					type: 'create_own',
				},
				{
					roles: [auth.roles.authenticated._id],
					type: 'read_own',
				},
				{
					roles: [auth.roles.authenticated._id],
					type: 'update_own',
				},
				{
					roles: [auth.roles.authenticated._id],
					type: 'delete_own',
				},
			],
			components: [
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'userEmail',
					label: 'Email Χρήστη',
					hideLabel: false,
				},
				/* {}, */
				{
					label: 'Columns',
					key: 'columns',
					type: 'columns',
					columns: [
						{
							components: [
								{
									type: 'button',
									key: 'saveDraft',
									theme: 'warning',
									action: 'saveState',
									state: FormConst.DRAFT,
									label: 'Προσωρινή Αποθήκευση',
								},
							],
							offset: 0,
							push: 0,
							pull: 0,
							size: 'md',
							width: 3,
						},
						{
							components: [
								{
									type: 'button',
									action: 'submit',
									theme: 'primary',
									label: 'Υποβολή',
									key: 'submit',
								},
							],
							offset: 0,
							push: 0,
							pull: 0,
							size: 'md',
							width: 3,
						},
					],
				},

				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'name',
					label: 'Όνομα Χρήστη',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'fathername',
					label: 'Πατρώνυμο',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'branch',
					label: 'Ειδικότητα',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'registry_no',
					label: 'Αριθμός Μητρώου',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'street_address',
					label: 'Διεύθυνση',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'phone_number',
					label: 'Τηλέφωνο',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'status',
					label: 'Ιδιότητα',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'unit_description',
					label: 'Μονάδα',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'unit_type',
					label: 'Τύπος Μονάδας',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'prefecture',
					label: 'Περιφέρεια',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'region_edu_admin',
					label: 'Περοφερειακή Διεύθυνση',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'edu_admin',
					label: 'Διεύθυνση',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'municipality',
					label: 'Δήμος',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'municipality_community',
					label: 'Τοπική Αυτοδιοίκηση',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'education_level',
					label: 'Βαθμίδα Εκπαίδευσης',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'operation_shift',
					label: 'Κύκλος Λειτουργίας',
					hideLabel: false,
				},
				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'legal_character',
					label: 'Νομικός Χαρακτήρας',
					hideLabel: false,
				},

				{
					type: 'textfield',
					input: true,
					disabled: true,
					inputType: 'text',
					key: 'category',
					label: 'Κατηγορία',
					hideLabel: false,
				},
			],
		},
		roles: auth.roles,
		options: { language: 'en', i18n: JSON.parse(translateGR) },
		saveText: 'Δημιουργία Φόρμας',
		errors: selectError('form', state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		saveForm: (form, auth) => {
			const newForm = {
				...form,
				// properties: { start: 'test' },
			};
			dispatch(
				saveForm('form', newForm, (err, form) => {
					if (!err) {
						dispatch(push(`/form/${form._id}`));
					}
				}),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
