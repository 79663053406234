/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectRoot, selectError } from 'react-formio';
import exportSubmissionDataPDF from './exportSubmissionDataPDF';
import { Redirect } from 'react-router-dom';
import asyncFetch from '../../../../../helpers/helperFunctions';
import { AppConfig } from '../../../../../config';

const getSubmission = async (formID, submissionID) => {
    const submission = await asyncFetch({
        url: `${AppConfig.apiUrl}/form/${formID}/submission/${submissionID}`,
        method: 'GET',
    });
    return submission;
};
const getForm = async (formID) => {
    const form = await asyncFetch({
        url: `${AppConfig.apiUrl}/form/${formID}`,
        method: 'GET',
    });
    return form;
};

class SubmissionExportPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submission: null,
            form: null,
            formNoPDF: false,
            submissionDraft: false
        };
    }

    componentDidMount() {
        const { submission } = this.props
        getSubmission(submission.formId, submission.id)
            .then(res => {
                this.setState({ submission: res })
                if (res.metadata.state === "draft") {
                    this.setState({ submissionDraft: true })
                }
            })

        getForm(submission.formId)
            .then(res => {
                this.setState({ form: res })
                if (res.properties.exportPDF === "NO") {
                    this.setState({ formNoPDF: true })
                }
            })
    }

    render() {

        const { auth } = this.props;
        if (auth === undefined || auth.authenticated === false) {
            return <Redirect to="/" />;
        }

        const buttonWidth = {
            width: '130px',
        };

        const warningStyle = {
            color: '#f86c6b',
        };

        return (
            <div>
                <p></p>
                {this.state.submissionDraft
                    ?
                    <p style={warningStyle}>Η υποβολή είναι Προσωρινή, δεν μπορείτε να εξάγετε PDF.</p>
                    :
                    <>
                        {this.state.formNoPDF
                            ? <p style={warningStyle}>Δεν μπορείτε να εξάγετε PDF από την συγκεκριμένη Φόρνα.</p>
                            :
                            <p>
                                <button className="btn btn-primary" style={buttonWidth} onClick={() => { exportSubmissionDataPDF(this.state.submission); }}>
                                    Εκτύπωση PDF
                                </button>
                            </p>
                        }
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const auth = selectRoot('auth', state);
    if (auth === undefined || auth.authenticated === false) return { auth };
    return {
        auth: auth,
        form: selectRoot('form', state),
        submission: selectRoot('submission', state),
        submissions: selectRoot('submissions', state),
        options: {
            noAlerts: true,
        },
        errors: [selectError('submission', state), selectError('form', state)],
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionExportPDF);
