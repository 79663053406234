import React from 'react'
import { Button } from 'reactstrap';
import exportSubmissionDataPDF from './Item/Submission/Item/exportSubmissionDataPDF'


const SubmissionRow = ({ submission, form }) => {

    //console.log('submission: ',submission.metadata.state)
    //console.log('form: ',form)

    const startDownload = () => {
        exportSubmissionDataPDF(submission)
    }

    const convertTimeStamp = (stringDate) => {

        const jsonDate = new Date(stringDate).toJSON()

        const readableDate = new Date(jsonDate).toLocaleString('el-GR')

        return readableDate
    }

    return (
        <tr>
            <td>{form.title}</td>
            <td>{convertTimeStamp(submission.modified)}</td>
            <td>{form.properties.exportPDF === "YES" ?
                <>
                    {
                        submission.metadata.state === "submitted" ?
                            <Button title="Εξαγωγή" color="info" size="sm" onClick={startDownload}><i className="fa fa-download"></i></Button>
                            :
                            <Button disabled title="Προσωρινή Υποβολή" color="warning" size="sm"><i className="fa fa-spinner "></i></Button>
                    }
                </>
                :
                <Button disabled title="Επιτυχής Καταχώρηση" color="success" size="sm"/*  onClick={startDownload} */><i className="fa fa-check "></i></Button>
            }
            </td>
        </tr>


    )
}

export default SubmissionRow