/* Sumissions List for User's View */

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getSubmissions, selectRoot, selectError, SubmissionGrid, Errors, getSubmission } from 'react-formio';
import Loading from '../../../../containers/Loading';
import { /* ExportConst, */ FormConst } from '../../../../config';

//import singleSubmissionValidator from '../../../../helpers/singleSubmissionValidator'

// const operations = () => {
// 	return [];
// return [
// 	{
// 		action: 'view',
// 		buttonType: 'warning',
// 		icon: 'eye',
// 		permissionsResolver() {
// 			return true;
// 		},
// 		title: 'Υποβολή',
// 	},
// 	{
// 		action: 'edit',
// 		buttonType: 'secondary',
// 		icon: 'edit',
// 		permissionsResolver() {
// 			return true;
// 		},
// 		title: 'Τροποποίηση',
// 	},
// ];

// };

const operations = isFormArchived => {
	if (isFormArchived) {
		return [
			{
				action: 'view',
				buttonType: 'warning',
				icon: 'eye',
				permissionsResolver() {
					return true;
				},
				title: 'Προβολή',
			},
		];
	}
	return [
		{
			action: 'view',
			buttonType: 'warning',
			icon: 'eye',
			permissionsResolver() {
				return true;
			},
			title: 'Προβολή',
		},
		{
			action: 'edit',
			buttonType: 'secondary',
			icon: 'edit',
			permissionsResolver() {
				return true;
			},
			title: 'Τροποποίηση',
		},
		{
			action: 'exportPDF',
			buttonType: 'info',
			icon: 'print',
			permissionsResolver() {
				return true;
			},
			title: 'ΕξαγωγήPDF',
		},
		{
			action: 'delete',
			buttonType: 'danger',
			icon: 'trash',
			permissionsResolver() {
				return true;
			},
		},
	];
};

const List = class extends Component {
	// componentDidMount() {
	//   this.props.getSubmissions(1);
	// }

	componentDidMount() {
		const { auth, getSubmissions } = this.props;

		if (auth === undefined || auth.authenticated === false) {
			return;
		}

		if (auth.user === null) {
			const user = JSON.parse(localStorage.getItem('formioUser'));
			getSubmissions(1, user.owner);
		} else {
			getSubmissions(1, auth.user._id);
		}
	}

	/**
	 * get user either from state or local storage
	 * use user to get his submissions for the current form
	 */
	// componentDidMount() {
	// 	const { auth, getSubmissions, getUserSubmissions, resetSubmission, form } = this.props;
	// 	// resetSubmission('submission');
	// 	if (auth.user === null) {
	// 		const user = JSON.parse(localStorage.getItem('formioUser'));
	// 		getUserSubmissions(user.owner, form.id);
	// 	} else {
	// 		getUserSubmissions(auth.user._id, form.id);
	// 	}
	// }

	// onPageSizeChanged(page) {
	//   console.log(page);
	//   const { getSubmissions, submissions } = this.props;
	//   submissions.limit = page;
	//   getSubmissions(auth.user._id, form.id);
	// }

	getSubmissionsWithQuery(page, ownerid) {
		const { getSubmissions } = this.props;
		getSubmissions(page, ownerid);
	}

	render() {
		if (this.props.auth === undefined || this.props.auth.authenticated === false) {
			return <Redirect to="/" />;
		}

		const {
			match: {
				params: { formId },
			},
		} = this.props;

		const { form, submissions, isLoading, onAction, errors, auth, pin /* userSubmissions */ } = this.props;

		if (isLoading) {
			return <Loading />;
		}

		const isFormArchived = form.properties.status === FormConst.ARCHIVED;

		/* const submissionStatusObjFormField = {
			type: 'textfield',
			input: true,
			disabled: true,
			inputType: 'text',
			key: 'submissionStatus',
			label: 'Κατάσταση Υποβολής',
			hideLabel: false,
			"tableView": true,
		}
		let found = false;
		for (let i = 0; i < form.components.length; i++) {
			if (form.components[i].key === 'submissionStatus') {
				found = true;
				break;
			}
		}
		if (!found) {
			form.components.unshift(submissionStatusObjFormField)
		}
		const newSubs = submissions.submissions.map(el => {
			if (el.metadata.state === "submitted") {
				el.data = { submissionStatus: "[Τελική]", ...el.data }
			} else if (el.metadata.state === "draft") {
				el.data = { submissionStatus: "[Προσωρινή]", ...el.data }
			}
			return el
		})
		submissions.submissions = newSubs */
		//let singleSubmission = singleSubmissionValidator(form)

		/* let numberOfSubmissions = 0
		if (submissions.submissions !== undefined) {
		  numberOfSubmissions = submissions.submissions.reduce((s, n) => {
			//return s + (n.data.userEmail === userEmail && n.metadata.state === "submitted")
		  }, 0)
		  
		} */

		const enterDataButton = (
			<Link className="btn btn-primary" to={`/form/${formId}`}>
				<i className="glyphicon glyphicon-plus fa fa-plus" aria-hidden="true"></i> Νέα Αίτηση: {form.title}
			</Link>
		);

		if(auth.is.authenticated) {
			if(form.properties.pin) {
				if(form.properties.pin !== undefined && form.properties.pin === pin) {
					return (
						<div className="form-index">
							<h4>Αιτήσεις Χρήστη: {form.title}</h4>
							<Errors errors={errors} />
							<SubmissionGrid
								submissions={submissions}
								form={form}
								// operations={operations()}
								operations={operations(isFormArchived)}
								onAction={onAction}
								onPageSizeChanged={page => this.onPageSizeChanged(page)}
								getSubmissions={page => this.getSubmissionsWithQuery(page, auth.user._id)}
							/>
							{/* {(singleSubmission && numberOfSubmissions<1) || !singleSubmission ?  */}
							{enterDataButton}
							{/* : null} */}
						</div>
					);
				} else {
					return (<div></div>)
				}
			} else {
				return (
					<div className="form-index">
						<h4>Αιτήσεις Χρήστη: {form.title}</h4>
						<Errors errors={errors} />
						<SubmissionGrid
							submissions={submissions}
							form={form}
							// operations={operations()}
							operations={operations(isFormArchived)}
							onAction={onAction}
							onPageSizeChanged={page => this.onPageSizeChanged(page)}
							getSubmissions={page => this.getSubmissionsWithQuery(page, auth.user._id)}
						/>
						{/* {(singleSubmission && numberOfSubmissions<1) || !singleSubmission ?  */}
						{enterDataButton}
						{/* : null} */}
					</div>
				);
			}
		} else if (auth.is.administrator) {
			return (
				<div className="form-index">
					<h4>Αιτήσεις Χρήστη: {form.title}</h4>
					<Errors errors={errors} />
					<SubmissionGrid
						submissions={submissions}
						form={form}
						// operations={operations()}
						operations={operations(isFormArchived)}
						onAction={onAction}
						onPageSizeChanged={page => this.onPageSizeChanged(page)}
						getSubmissions={page => this.getSubmissionsWithQuery(page, auth.user._id)}
					/>
					{/* {(singleSubmission && numberOfSubmissions<1) || !singleSubmission ?  */}
					{enterDataButton}
					{/* : null} */}
				</div>
			);
		}
	}
};

const mapStateToProps = (state, ownProps) => {
	const form = selectRoot('form', state);
	const submissions = selectRoot('submissions', state);
	const auth = selectRoot('auth', state);
	const pin = selectRoot('pin', state);
	if (auth === undefined || auth.authenticated === false) return { auth };
	return {
		form: form.form,
		auth: auth,
		submissions,
		pin: pin.pin,
		isLoading: form.isActive || submissions.isActive,
		errors: [selectError('submissions', state), selectError('form', state)],
		userSubmissions: selectRoot('userSubmissions', state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getSubmissions: (page, ownerid) => dispatch(getSubmissions('submissions', page, { owner: ownerid }, ownProps.match.params.formId)),
		onAction: (submission, action) => {
			// switch (action) {
			// 	case 'row':
			// 		dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}`));
			// 		break;
			// 	default:
			// }
			switch (action) {
				
				case 'view':
				case 'row':
					dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}`));
					break;
				case 'edit':
					dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}/edit`));
					break;
				case 'exportPDF':
					dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}/exportPDF`));
					break;
				case 'delete':
					dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}/delete`));
					break;
				default:
			}
		},
		getUserSubmissions: (ownerid, formid) => {
			dispatch(
				getSubmissions('submission', 0, { owner: ownerid }, formid, (err, submissionOfUser) => {
					if (submissionOfUser !== undefined) {
						const [subm] = submissionOfUser;
						dispatch(getSubmission('submission', subm._id, subm.form));
					}
				}),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
