// g_papaioannou
// 13-02-2024 - Disabled Custom Option CSS to the Form builder at line 42
// 10-Sep-24 - Added {...options} to Form to set it as disabled.

import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectRoot, resetSubmissions, saveSubmission, Form, selectError, Errors } from 'react-formio';
import { push } from 'connected-react-router';
import Loading from '../../../../../containers/Loading';
import { Redirect } from 'react-router-dom';

const View = class extends Component {
	render() {
		if (this.props.auth === undefined || this.props.auth.authenticated === false) {
			return <Redirect to="/" />;
		}

		const {
			hideComponents,
			onSubmit,
			options,
			errors,
			form: { form, isActive: isFormActive },
			submission: { submission, isActive: isSubActive, url },
			pin,
		} = this.props;

		if (isFormActive || isSubActive) {
			return <Loading />;
		}

		if (form.components[0].key === 'submissionStatus') {
			form.components.shift();
		}

		/* console.log("VIEW JS src/views/Form/Item/Submission/Item/View.js")
		console.log("pin", pin) */
		if (form.properties.pin) {
			if (this.props.auth.is.authenticated && form.properties.pin !== undefined && form.properties.pin === pin) {
				return (
					<div>
						<h4>Προβολή: {form.title}</h4>
						<Errors errors={errors} />
						<Form
							form={form}
							submission={submission}
							url={url}
							hideComponents={hideComponents}
							onSubmit={onSubmit}
							options={{ ...options }}
							// options={{ ...{ template: 'bootstrap3', iconset: 'fa' }, ...options }}
						/>
					</div>
				);
			} else if (this.props.auth.is.administrator) {
				return (
					<div>
						<h4>Προβολή: {form.title}</h4>
						<Errors errors={errors} />
						<Form
							form={form}
							submission={submission}
							url={url}
							hideComponents={hideComponents}
							onSubmit={onSubmit}
							options={{ ...options }}
							// options={{ ...{ template: 'bootstrap3', iconset: 'fa' }, ...options }}
						/>
					</div>
				);
			}
		} else {
			return (
				<div>
					<h4>Προβολή: {form.title}</h4>
					<Errors errors={errors} />
					<Form
						form={form}
						submission={submission}
						url={url}
						hideComponents={hideComponents}
						onSubmit={onSubmit}
						// options={{ ...{ template: 'bootstrap3', iconset: 'fa' }, ...options }}
						options={{ ...options }}
					/>
				</div>
			);
		}
	}
};

const mapStateToProps = state => {
	const auth = selectRoot('auth', state);
	const pin = selectRoot('pin', state) || '';
	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		auth: auth,
		form: selectRoot('form', state),
		submission: selectRoot('submission', state),
		options: {
			readOnly: true,
		},
		pin: pin.pin,
		errors: [selectError('submission', state), selectError('form', state)],
		location: state.router.location,
		pathname: state.router.location.pathname,
		state: state,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSubmit: (submission, form) => {
			dispatch(
				saveSubmission('submission', submission, ownProps.match.params.formId, (err, submission) => {
					if (!err) {
						dispatch(resetSubmissions('submission'));
						dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}`));
					}
				}),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
