import { Route, Switch } from 'react-router-dom'
import React from 'react'
import List from './List'
import { connect } from 'react-redux';
import { useState, useEffect } from 'react'
import { selectRoot, selectError } from "react-formio"
import { store } from './../../../../store';
import { SET_PIN } from './../../../../redux/actions/types';
// import Item from './Item/index'
import { PinComponent } from '../../../../helpers/pinComponent/PinComponent';

const Form = (props) => {
  const { form, auth, statePin } = props;
  const [isBusy, setBusy] = useState(true)
  const [theProperties, setProperties] = useState("")
  const [pinEntered, setPin] = useState("")

  useEffect(() => {
    setProperties(form.properties)
    if (form.properties !== undefined) {
      setBusy(false)
    }
  }, [form])

  const setPinEntered = (pinValue) => {
    setPin(pinValue);
    const state = store.getState();
    store.dispatch({ ...state, type: SET_PIN, payload: pinValue });
  }

 /*  console.log("INDEX JS src/views/Form/Item/userSubmissions/index.js")
  console.log(auth) */
  return (
    <div>
      {
        isBusy === false ?
          (auth.is.authenticated &&
            theProperties.pin !== undefined && theProperties.pin !== ""
            && pinEntered === "" ?
            (theProperties.pin === statePin ?
              <Switch>
                <Route exact path="/form/:formId/userSubmissions" component={List} />
                {/* <Route path="/form/:formId/submission/:submissionId" component={Item} /> */}
              </Switch>
              :
              <PinComponent setPinEntered={setPinEntered} theProperties={theProperties} form={form}/>
            )
            :
            (auth.is.authenticated && theProperties.pin === pinEntered ?
              <Switch>
                <Route exact path="/form/:formId/userSubmissions" component={List} />
                {/*  <Route
              path="/form/:formId/userSubmissions"
              render={(props) => <List {...props} pin={pinEntered} />}
            /> */}
                {/* <Route path="/form/:formId/submission/:submissionId" component={Item} /> */}
              </Switch>
              :
              (auth.is.administrator || auth.is.authenticated ?
                <Switch>
                  <Route exact path="/form/:formId/userSubmissions" component={List} />
              {/* <Route path="/form/:formId/submission/:submissionId" component={Item} /> */}
                </Switch>
              :<div></div>)
            )
          )
          : (
            <Switch>
              <Route exact path="/form/:formId/userSubmissions" component={List} />
              {/* <Route path="/form/:formId/submission/:submissionId" component={Item} /> */}
            </Switch>
          )
      }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const form = selectRoot('form', state);
  const auth = selectRoot('auth', state);
  const pin = selectRoot('pin', state);
  /* const submissions = selectRoot('submissions', sta
  
  if (auth === undefined || auth.authenticated === false) return { auth }; */

  return {
    auth: auth,
    form: form.form,
    statePin: pin.pin,
    /* 	submissions,
      isLoading: form.isActive || submissions.isActive, */
    errors: [selectError('submissions', state), selectError('form', state)],
  };
};

export default connect(mapStateToProps)(Form);

