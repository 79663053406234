/* Sumissions List for Admin's View */

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getForm, getSubmissions, selectRoot, selectError, /* SubmissionGrid, */ Errors } from 'react-formio';
import { Badge } from 'reactstrap';
import Loading from '../../../../containers/Loading';
import { AppConfig, FormConst } from '../../../../config';
import singleSubmissionValidator from '../../../../helpers/singleSubmissionValidator';
import { FormsSubmissionGrid } from './FormsSubmissionGrid'


/* const operations = isFormArchived => {
	if (isFormArchived) {
		return [
			{
				action: 'view',
				buttonType: 'warning',
				icon: 'eye',
				permissionsResolver() {
					return true;
				},
				title: 'Προβολή',
			},
		];
	}
	return [
		{
			action: 'view',
			buttonType: 'warning',
			icon: 'eye',
			permissionsResolver() {
				return true;
			},
			title: 'Προβολή',
		},
		{
			action: 'edit',
			buttonType: 'secondary',
			icon: 'edit',
			permissionsResolver() {
				return true;
			},
			title: 'Τροποποίηση',
		},
		{
			action: 'exportPDF',
			buttonType: 'info',
			icon: 'print',
			permissionsResolver() {
				return true;
			},
			title: 'ΕξαγωγήPDF',
		},
		{
			action: 'delete',
			buttonType: 'danger',
			icon: 'trash',
			permissionsResolver() {
				return true;
			},
		},
	];
};*/

const List = class extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userEmail: '',
			countSubmitted: 0,
			countDraft: 0,
			countAll: 0,
			allSubmissions: [],
			loaded: false
		};
	}

	componentDidMount() {
		const { auth, getForm } = this.props;
		if (auth === undefined || auth.authenticated === false) {
			return;
		}
		getForm();
		this.props.getSubmissions(1);
		this.setState({ userEmail: JSON.parse(localStorage.getItem('formioUser')).data.email })
	}

	componentWillReceiveProps(newProps) {
		if (this.props !== newProps) {
			this.getAllSubmissions(newProps);
		}
	}

	componentWillMount() {
		this.getAllSubmissions(this.props);
	}

	getAllSubmissions(props) {
		const formId = props.match.params.formId;
		const formioToken = localStorage.getItem('formioToken');
		const url = `${AppConfig.apiUrl}/form/${formId}/submission?limit=10000`
		fetch(url, {
			mode: 'cors',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'x-jwt-token': formioToken,
			},
			redirect: 'follow',
		})
			.then(response => {
				return response.json();
			})
			.then(res => {
				const countSubmitted = res.reduce((s, n) => {
					return s + (n.metadata.state === "submitted");
				}, 0)
				const countDraft = res.reduce((s, n) => {
					return s + (n.metadata.state === "draft");
				}, 0)
				const countAll = res.length
				this.setState({
					countSubmitted: countSubmitted,
					countDraft: countDraft,
					countAll: countAll,
					allSubmissions: res,
					loaded: true
				});
			})
	}

	onPageSizeChanged(page) {
		//console.log(page);
		const { getSubmissions, submissions } = this.props;
		submissions.limit = page;
		getSubmissions(1);
	}

	getSubmissionsWithQuery(page) {
		const { getSubmissions } = this.props;
		getSubmissions(page);
	}

	render() {
		if (this.props.auth === undefined || this.props.auth.authenticated === false) {
			return <Redirect to="/" />;
		}

		const {
			match: {
				params: { formId },
			},
		} = this.props;
		const { form, submissions, isLoading, /* onAction, */ errors, auth } = this.props;

		const userEmail = this.state.userEmail;

		if (isLoading) {
			return <Loading />;
		}

		const isFormArchived = form.properties.status === FormConst.ARCHIVED;
		let enterDataButton;

		let singleSubmission = singleSubmissionValidator(form);

		// Na afora ston Current User
		let numberOfSubmissions;
		if (submissions.submissions !== undefined) {
			//numberOfSubmissions = submissions.submissions.length

			numberOfSubmissions = submissions.submissions.reduce((s, n) => {
				//console.log("N=", n.data.userEmail === userEmail)
				return s + (n.data.userEmail === userEmail && n.metadata.state === 'submitted');
			}, 0);
		}

		/* const submissionStatusObjFormField = {
			type: 'textfield',
			input: true,
			disabled: true,
			inputType: 'text',
			key: 'submissionStatus',
			label: 'Κατάσταση Υποβολής',
			hideLabel: false,
			"tableView": true,
		}
		let found = false;
		for (let i = 0; i < form.components.length; i++) {
			if (form.components[i].key === 'submissionStatus') {
				found = true;
				break;
			}
		}
		if (!found) {
			form.components.unshift(submissionStatusObjFormField)
		}
		const newSubs = submissions.submissions.map(el => {
			if (el.metadata.state === "submitted") {
				el.data = { submissionStatus: "[Τελική]", ...el.data }
			} else if (el.metadata.state === "draft") {
				el.data = { submissionStatus: "[Προσωρινή]", ...el.data }
			}
			return el
		})
		submissions.submissions = newSubs */


		// if (form.properties !== undefined) {
		// 	if (form.properties.numberOfSubmissions !== undefined) {
		// 		console.log('TSAK form.form.properties.numberOfSubmissions: ', form.properties.numberOfSubmissions)
		// 		if (form.properties.numberOfSubmissions === FormConst.SINGLE_SUBMISSION) {
		// 			singleSubmission = true
		// 		} else {
		// 			singleSubmission = false
		// 		}
		// 		console.log('TSAK singleSubmission: ', singleSubmission)
		// 	}

		// }

		//na koitaei kai to singleSubmission kai to an exei idi eggrafi

		//to numberOfSubmissions na afora ton sygkekrimeno xristi
		if (isFormArchived || (singleSubmission && numberOfSubmissions >= 1)) {
			enterDataButton = '';
		} else {
			enterDataButton = (
				<Link className="btn btn-primary" to={`/form/${formId}`}>
					<i className="glyphicon glyphicon-plus fa fa-plus" aria-hidden="true"></i> Νέα Αίτηση: {form.title}
				</Link>
			);
		}

		//console.log("this.state.allSubmissions", this.state.allSubmissions)
		return (
			<div className="form-index">
				<h4>Αιτήσεις: {form.title}</h4>
				<Errors errors={errors} />
				{auth.is.administrator ? (
					<div style={{ "marginBottom": "10px" }}>
						<Badge color="primary" pill>Σύνολο Υποβολών: {this.state.countAll}</Badge>
						<Badge color="info" pill>Τελικές Υποβολές: {this.state.countSubmitted}</Badge>
						<Badge color="warning" pill>Προσωρινές Υποβολές: {this.state.countDraft}</Badge>
					</div>
				) : (
					<></>
				)
				}
				{this.state.loaded ? <FormsSubmissionGrid formID={form._id} submissions={this.state.allSubmissions} formPDF={form.properties.exportPDF}/>
					: <p style={{textAlign:"center", color:"navy"}}><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
						<span class="sr-only">Loading...</span></p>}

				{/* <SubmissionGrid submissions={submissions} form={form} operations={operations(isFormArchived)} onAction={onAction} onPageSizeChanged={page => this.onPageSizeChanged(page)} getSubmissions={page => this.getSubmissionsWithQuery(page)} /> */}
				<p>{enterDataButton}</p>
			</div>
		);
	}
};

const mapStateToProps = (state, ownProps) => {
	const form = selectRoot('form', state);
	const submissions = selectRoot('submissions', state);
	const auth = selectRoot('auth', state);
	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		auth: auth,
		form: form.form,
		submissions,
		isLoading: form.isActive || submissions.isActive,
		errors: [selectError('submissions', state), selectError('form', state)],
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getForm: () => dispatch(getForm('admin')),
		getSubmissions: (page, query) => dispatch(getSubmissions('submissions', page, query, ownProps.match.params.formId)),
		onAction: (submission, action) => {
			switch (action) {
				case 'view':
				case 'row':
					dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}`));
					break;
				case 'edit':
					dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}/edit`));
					break;
				case 'exportPDF':
					dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}/exportPDF`));
					break;
				case 'delete':
					dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}/delete`));
					break;
				default:
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
