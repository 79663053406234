import { SET_MAINTENANCE } from '../actions/types';

const initialMaintenanceState = {
	maintenance: false,
};

export default function(state = initialMaintenanceState, action) {
	switch (action.type) {
		case SET_MAINTENANCE:
			return {
				...state,
				maintenance: action.payload,
			};
		default:
			return {
				...state,
			};
	}
}
