import React, { Component } from 'react';
import './Fonts/FreeSans.js';
import { connect } from 'react-redux';
import { selectRoot, getSubmissions } from 'react-formio';
import DOMPurify from 'dompurify';
import ReactHTMLTableToExcel from './ExportXLSComponent/ReactHTMLTableToExcel';
import ReactHTMLTableToHTML from './ExportHTMLComponent/ReactHTMLTableToHTML';
import { ExportConst, AppConfig } from '../../config.js';
//import getFormGreekLabels from '../../helpers/getFormGreekLabels';
import { Redirect } from 'react-router-dom';
import exportSubmissionDataPDF from './Item/Submission/Item/exportSubmissionDataPDF.js';
import { Input /* InputGroup, InputGroupText, Button  */ } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import buildTable from '../../helpers/buildTable.js';
import manipulateSubmissionsForExport from '../../helpers/manipulateSubmissionsForExport.js';

const Export = class extends Component {
	state = {
		data: '',
		downloadUrl: '',
		isSucceed: false,
		daysOfToken: 1,
		urlMessage: '',
		formID: '',
		modal: false,
		isLoaded: false,
	};

	componentWillMount() {
		const { auth } = this.props;

		if (auth === undefined || auth.authenticated === false) {
			return;
		}

		this.props.getSubmissions('all');
	}

	componentDidMount() {
		const { auth } = this.props;

		if (auth === undefined || auth.authenticated === false) {
			return;
		}

		const form = this.props.form;
		this.setState({ formID: form.form._id });
		const formioToken = localStorage.getItem('formioToken');
		const url = `${AppConfig.apiUrl}/form/${form.form._id}/export?format=csv`;

		fetch(url, {
			mode: 'cors',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'x-jwt-token': formioToken,
			},
			redirect: 'follow',
		})
			.then(response => {
				// The response is a Response instance.
				// The response return type is text and not json
				return response.text();
			})
			.then(data => {
				//afairesai ta " apo to string
				data = data.replace(/"/g, '');
				this.setState({ data: data });
			});

		if (document.getElementById("csvTableSubmitted")) {
			this.setState({ isLoaded: true })
		}
	}

	handleChangeDaysOfToken = val => {
		this.setState({ daysOfToken: val.target.value });
	};

	handleSubmitValidTokenDays = async event => {
		const { auth } = this.props;
		event.preventDefault();

		//
		// post request example
		// Header 'Bearer ' + formioToken
		// body {
		// 	user : this.props.auth.user
		//  days : 100
		// }
		const formioToken = window.localStorage.getItem('formioToken');
		const authorization = 'Bearer ' + formioToken;

		let url = `${AppConfig.apiUrlUser}/api/createtoken`;
		try {
			const res = await fetch(`${url}`, {
				method: 'POST',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json',
					Authorization: authorization,
				},
				body: JSON.stringify({
					user: auth?.user?.data?.email,
					days: this.state.daysOfToken,
				}),
			});

			const resBody = await res.json();
			if (res.status !== 200) {
				return false;
			} else {
				const url = `${AppConfig.apiUrlUser}/#/exportdirect/${this.state.formID}/${resBody.token}`;
				this.setState({
					urlMessage: `Επιτυχής Διαδικασία. Ο σύνδεσμος αντιγράφτηκε στον clipboard.`,
					downloadUrl: url,
					isSucceed: true,
				});
				navigator.clipboard.writeText(url);
				this.toggle();
			}
		} catch (err) {
			//if back server is down set maintance = true
			console.log('BackEnd Error. Description=[ ', err, ' ]');
			this.setState({
				urlMessage: `Προκλήθηκε Σφάλμα. [ ${err} ].`,
				isSucceed: false,
			});
			this.toggle();
			return false;
		}
	};

	toggle = () =>
		this.setState(prevState => ({
			modal: !prevState.modal,
		}));

	render() {
		const { auth } = this.props;
		if (auth === undefined || auth.authenticated === false) {
			return <Redirect to="/" />;
		}

		const { form } = this.props;
		const { data } = this.state;
		const { submissions } = this.props.submissions;

		//console.log("SUBMISSIONS ", submissions)
		const countDraft = submissions.reduce((s, n) => {
			return s + (n.metadata.state === 'draft');
		}, 0);
		//console.log("countDraft", countDraft)

		let exportData = manipulateSubmissionsForExport(data,
			submissions,
			form
		)

		const buttonWidth = {
			width: '130px',
		};
		const getFormTitle = form.form.title;

		const myBuildTable = buildTable(exportData.linesFinal, 'Submitted');
		const myBuildTableHTML = buildTable(exportData.linesFinal, 'Submitted', 'Html');
		const myBuildTableDraft = buildTable(exportData.linesFinalDraft, 'Draft');
		const myBuildTableDraftHTML = buildTable(exportData.linesFinalDraft, 'Draft', 'Html');

		//console.log(myBuildTable)

		function csvToJSON(csv) {
			var lines = csv.split('\n');
			var result = [];
			var headers = lines[0].split(',');

			for (var i = 1; i < lines.length; i++) {
				var obj = {};
				var currentLine = lines[i].split(',');
				for (var j = 0; j < headers.length; j++) {
					obj[headers[j]] = currentLine[j];
				}
				result.push(obj);
			}
			return JSON.stringify(result);
		}

		const getDataJSON = function (csvData) {
			//DOWNLOAD
			const jsonData = csvToJSON(csvData);
			var link = document.createElement('a');
			link.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(jsonData);
			link.target = '_blank';
			link.download = 'formData.json';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		};

		const getDataCSV = function (csvData) {
			//DOWNLOAD
			var link = document.createElement('a');
			link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
			link.target = '_blank';
			link.download = 'formData.csv';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		};

		const getUsersPDFs = function () {
			submissions.forEach(sub => {
				if (sub.metadata.state === 'submitted') {
					exportSubmissionDataPDF(sub);
				}
			});
		};

		return (
			<div>
				<h4>Εξαγωγή Δεδομένων: {getFormTitle}</h4>
				<br></br>
				<p>
					<button className="btn btn-primary" style={buttonWidth} onClick={() => getDataJSON(exportData.linesFinal)}>
						Εξαγωγή JSON
					</button>
				</p>
				<p>
					<button className="btn btn-primary" style={buttonWidth} onClick={() => getDataCSV(exportData.linesFinal)}>
						Εξαγωγή CSV
					</button>
				</p>
				{/* <p><button className="btn btn-primary" style={buttonWidth}
                    onClick={() => getDataXLSX()}>Εξαγωγή XLSX</button></p> */}

				{this.state.isLoaded ?
					<p>
						<ReactHTMLTableToHTML
							id="test-table-html-button"
							className="btn btn-primary "
							style={buttonWidth}
							//table="csvTableSubmitted"
							table="csvTableSubmittedHtml"
							filename={getFormTitle}
							sheet="Sheet1"
							buttonText="Εξαγωγή HTML"
						/>
					</p> : <p></p>}

				{this.state.isLoaded ?
					<p>
						<ReactHTMLTableToExcel
							id="test-table-xls-button"
							className="btn btn-primary "
							style={buttonWidth}
							table="csvTableSubmitted"
							filename={getFormTitle}
							sheet="Sheet1"
							buttonText="Εξαγωγή XLSX"
						/>
					</p> : <p></p>}
				{countDraft > 0 ? (
					<div>
						<p>
							<ReactHTMLTableToHTML
								id="test-table-xls-button-2"
								className="btn btn-warning "
								style={buttonWidth}
								table="csvTableDraftHtml"
								filename={getFormTitle + 'Draft'}
								sheet="Sheet1"
								buttonText="Εξαγωγή HTML Προσωρινά Αποθηκευμένων"
							/>
						</p>
						<p>
							<ReactHTMLTableToExcel
								id="test-table-xls-button-2"
								className="btn btn-warning "
								style={buttonWidth}
								table="csvTableDraft"
								filename={getFormTitle + 'Draft'}
								sheet="Sheet1"
								buttonText="Εξαγωγή XLSX Προσωρινά Αποθηκευμένων"
							/>
						</p>
					</div>
				) : null}

				{form.form.properties.exportPDF === 'YES' ? (
					<p>
						<button
							className="btn btn-outline-danger"
							style={buttonWidth}
							onClick={() => getUsersPDFs(this.props.form)}
						>
							Εξαγωγή PDFs Χρηστών
						</button>
					</p>
				) : null}
				<hr></hr>
				<div>
					<h5>Δημιουργία URL πρόσβασης στην Εξαγωγή Δεδομένων</h5>
					<br></br>
					<div>
						<p>Ημέρες εγκυρότητας token:</p>
						<form onSubmit={this.handleSubmitValidTokenDays}>
							<Input
								style={{ width: '10%' }}
								type="number"
								min="1"
								max="125"
								name="daysInput"
								id="daysInput"
								value={this.state.daysOfToken}
								onChange={this.handleChangeDaysOfToken}
								title="Ημέρες εγκυρότητας token"
							/>
							<br></br>
							<button
								type="submit"
								className="btn btn-primary"
								style={{ width: '250px' }}
								onClick={() => this.handleSubmitValidTokenDays}
							>
								Δημιουργία URL
							</button>
						</form>
					</div>

					<Modal isOpen={this.state.modal} toggle={this.toggle} /*  {...args} */>
						<ModalHeader toggle={this.toggle}>Αντιγραφή συνδέσμου download.</ModalHeader>
						<ModalBody>
							<h6 style={{ color: '#001E80' }}>{this.state.urlMessage}</h6>
							<br></br>
							{this.state.isSucceed && (
								<>
									Κάντε επικόλληση του συνδέσμου στη διεύθυνση URL του φυλλομετρητή σας (Chrome,
									Firefox, Safari κτλ)
									<br />
									<br />
									Κάντε κλικ στον παρακάτω σύνδεσμο για προεπισκόπηση.
									<br />
									<a href={`${this.state.downloadUrl}`}> Click to Download </a>
								</>
							)}
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.toggle}>
								Κλείσιμο
							</Button>
						</ModalFooter>
					</Modal>

					{/* <h5 style={{ color: "#8b0000" }}>{this.state.urlMessage}</h5> */}
				</div>
				<div
					style={{ visibility: 'hidden' }}
					className="content"
					dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(myBuildTable) }}
				></div>
				<div
					style={{ visibility: 'hidden' }}
					className="content"
					dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(myBuildTableHTML) }}
				></div>
				<div
					style={{ visibility: 'hidden' }}
					className="content"
					dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(myBuildTableDraft) }}
				></div>
				<div
					style={{ visibility: 'hidden' }}
					className="content"
					dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(myBuildTableDraftHTML) }}
				></div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	const auth = selectRoot('auth', state);
	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		auth: auth,
		submissions: selectRoot('submissions', state),
		form: selectRoot('form', state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getSubmissions: (page, query) =>
			dispatch(getSubmissions('submissions', page, { limit: ExportConst.LIMIT }, ownProps.match.params.formId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Export);
