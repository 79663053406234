import StayOnPage from './StayOnPage';
import { toast } from 'react-toastify';

const notifyNoDataInRequiredField = (msgField, sec) => {
	toast.error("Το πεδίο " + msgField + " είναι υποχρεωτικό", {
		position: 'top-right',
		autoClose: sec,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
	});
};
let stayOnPage = new StayOnPage();

const checkRequired = (currentComponent, submission) => {
	//console.log("IN CHECKREQUIRED FUNCTION")
	//console.log("currentComponent", currentComponent)

	if (currentComponent.validate.required === true) { //required field
		//condinional field
		if (currentComponent.conditional.show === true) {
			//console.log("submission", submission)
			//console.log("currentComponent.conditional", currentComponent.conditional)
			if (submission.data.hasOwnProperty(currentComponent.conditional.when)) {
				//console.log("YES ", submission.data[currentComponent.conditional.when])
				//console.log("submission.data[currentComponent.conditional.when]", submission.data[currentComponent.conditional.when])
				//console.log("currentComponent.conditional.eq)", currentComponent.conditional.eq)
				if (submission.data[currentComponent.conditional.when] === currentComponent.conditional.eq) {
					if (submission.data[currentComponent.key] === ""
						||
						submission.data[currentComponent.key] === false
						||
						!submission.data[currentComponent.key]
					) {
						stayOnPage.setStay(false)
						notifyNoDataInRequiredField(currentComponent.label, 3000)
						return true
					}
				}
			}
			return false
		}
		// empty text, url, email fields, etc
		for (const [key, value] of Object.entries(submission.data)) {
			if (currentComponent.key === key) {
				if (value === '') {
					stayOnPage.setStay(false)
					notifyNoDataInRequiredField(currentComponent.label, 3000)
					return true
				}
				//checkbox
				if (currentComponent.type === "checkbox") {
					if (value === false) {
						stayOnPage.setStay(false)
						notifyNoDataInRequiredField(currentComponent.label, 3000)
						return true
					}
				}
				//selectboxes
				if (currentComponent.type === "selectboxes") {
					let countTrues = 0
					// eslint-disable-next-line no-unused-vars
					for (const [keyCheckBox, valueCheckBox] of Object.entries(value)) {
						if (valueCheckBox === true) {
							countTrues++
						}
					}
					if (countTrues === 0) {
						stayOnPage.setStay(false)
						notifyNoDataInRequiredField(currentComponent.label, 3000)
						return true
					}
				}
			}
		}
		// in certain field types (e.g. radio), if empty, no key inserted in submission
		if (!submission.data.hasOwnProperty(currentComponent.key)) {
			stayOnPage.setStay(false)
			notifyNoDataInRequiredField(currentComponent.label, 3000)
			return true
		}
	}
}

const checkRequiredPanelsColumns = (currentComponent, submission) => {
	if (currentComponent.validate.required === true) {
		if (currentComponent.conditional.show === true) {
			if (submission.data.hasOwnProperty(currentComponent.conditional.when)) {
				if (submission.data[currentComponent.conditional.when] === currentComponent.conditional.eq) {
					if (submission.data[currentComponent.key] === ""
						||
						submission.data[currentComponent.key] === false
						||
						!submission.data[currentComponent.key]
					) {
						return true
					}
				}
			}
		}
	}


}

// CHECK REQUIRED FIELDS AND POP UP MESSAGES
const checkRequiredFields = (form, submission) => {
	for (let i = 0; i < form.components.length; i++) {
		let breakOuter = false
		//panel case
		if (form.components[i].type === "panel") {
			//console.log("IN PANEL")

			for (let j = 0; j < form.components[i].components.length; j++) {
				//console.log("form.components[i].components[j].key", form.components[i].components[j].key)
				// PANEL in PANEL
				if (form.components[i].components[j].type === "panel") {
					checkRequiredFields(form.components[i], submission)
				}
				// PANEL in PANEL END

				/* PANELS SPECIFIC */
				let currentComponent = form.components[i].components[j]
				if (checkRequiredPanelsColumns(currentComponent, submission)) {
					stayOnPage.setStay(false)
					notifyNoDataInRequiredField(currentComponent.label, 3000)
					breakOuter = true
					break
				}
				/* PANELS SPECIFIC END */

				/* COLUMNS IN PANEL */
				if (form.components[i].components[j].type === 'columns') {
					for (let l = 0; l < form.components[i].components[j].columns.length; l++) {
						for (let m = 0; m < form.components[i].components[j].columns[l].components.length; m++) {
							let currentComponent = form.components[i].components[j].columns[l].components[m]
							if (checkRequiredPanelsColumns(currentComponent, submission)) {
								stayOnPage.setStay(false)
								notifyNoDataInRequiredField(currentComponent.label, 3000)
								breakOuter = true
								break
							}
							if (submission.data.hasOwnProperty(form.components[i].components[j].columns[l].components[m].key)) {
								if (checkRequired(form.components[i].components[j].columns[l].components[m], submission)) {
									breakOuter = true
									break
								}
							}

						}
					}
				}
				/* COLUMNS IN PANEL END */

				if (submission.data.hasOwnProperty(form.components[i].components[j].key)) {
					//console.log("checkRequired", checkRequired(form.components[i].components[j], submission))
					if (checkRequired(form.components[i].components[j], submission)) {
						breakOuter = true
						break
					}
				}
			}
			if (breakOuter) break
		}
		//columns case
		if (form.components[i].type === "columns") {
			//console.log("IN COLUMNS")
			let breakInner = false
			for (let j = 0; j < form.components[i].columns.length; j++) {
				for (let k = 0; k < form.components[i].columns[j].components.length; k++) {

					/* COLUMNS SPECIFIC */
					let currentComponent = form.components[i].columns[j].components[k]
					if (checkRequiredPanelsColumns(currentComponent, submission)) {
						stayOnPage.setStay(false)
						notifyNoDataInRequiredField(currentComponent.label, 3000)
						breakOuter = true
						break
					}
					/* COLUMNS SPECIFIC END */

					if (submission.data.hasOwnProperty(form.components[i].columns[j].components[k].key)) {
						if (checkRequired(form.components[i].columns[j].components[k], submission)) {
							breakInner = true
							break
						}
					}
				}
				if (breakInner) {
					breakOuter = true
					break
				}
			}
			if (breakOuter) break
		}
		//default case
		/* console.log("FORM", form)
		console.log("SUBMISSION ", submission) */
		if (submission.data.hasOwnProperty(form.components[i].key)) {
			if (checkRequired(form.components[i], submission)) {
				break
			}
		}

	}
}
// END - CHECK REQUIRED FIELDS AND POP UP MESSAGES

export default checkRequiredFields