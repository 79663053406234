export const htmlStyle = `<style>
table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
}
table, th, td {
  border: 1px solid;
  text-align:center;
  padding-left:5px;
  padding-right:5px;
}
th {
    background-color:#1e3596;
    color:#ffffff;
    white-space:nowrap;
    height:100px;

}
tr:nth-child(even) {background-color: #dee4ff;}
 
.button-22 {
  align-items: center;
  appearance: button;
  background-color: #0276FF;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
 /*  display: flex; */
  flex-direction: row;
  flex-shrink: 0;
  font-family: "RM Neue",sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 10px 21px;
  text-align: center;
  text-transform: none;
  transition: color .13s ease-in-out,background .13s ease-in-out,opacity .13s ease-in-out,box-shadow .13s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-22:active {
  background-color: #006AE8;
}

.button-22:hover {
  background-color: #1C84FF;
}

</style>`