import { Link, Route, Switch, Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSubmission, selectRoot } from 'react-formio';
import View from './View';
import Edit from './Edit';
import Delete from './Delete';
import SubmissionExportPDF from './SubmissionExportPDF';
import { FormConst } from '../../../../../config';

const Item = class extends Component {
	constructor() {
		super();

		this.state = {
			submissionId: '',
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.match.params.submissionId !== prevState.submissionId) {
			nextProps.getSubmission(nextProps.match.params.submissionId);
		}

		return {
			submissionId: nextProps.match.params.submissionId,
		};
	}

	render() {
		if (this.props.auth === undefined || this.props.auth.authenticated === false) {
			return <Redirect to="/" />;
		}

		const {
			match: {
				params: { formId, submissionId },
			},
			auth,
			form,
			submission,
			pin
		} = this.props;

		/* console.log("INDEX JS 2 / src/views/Form/Item/Submission/Item/index.js")
		console.log(pin) */
		if (this.props.auth !== undefined && this.props.auth.is.authenticated === true) {
			if(form.properties.pin && form.properties.pin !== pin) {
				return <Redirect to="/" />;
			}
		}
		return (
			<div>
				<ul className="nav nav-tabs list-inline">
					{auth.is.administrator ? (
						<div>
							<li className="nav-item list-inline-item">
								<Link className="nav-link" to={`/form/${formId}/submission`}>
									<span hidden aria-hidden="true">Back</span>
									<i className="fa fa-chevron-left" />
								</Link>
							</li>
						</div>
					) : null}
					<li className="nav-item list-inline-item">
						<Link className="nav-link" to={`/form/${formId}/submission/${submissionId}`}>
							<i className="fa fa-eye" /> Προβολή
						</Link>
					</li>
					{submission.metadata !== undefined && submission.metadata.state !== undefined
						&& submission.metadata.state === FormConst.SUBMITTED && form.properties.exportPDF === "YES"?
						(<div>
							<li className="nav-item list-inline-item">
								<Link className="nav-link" to={`/form/${formId}/submission/${submissionId}/exportPDF`}>
									<i className="fa fa-print" /> Εκτύπωση PDF
								</Link>
							</li>
						</div> )
						: form.properties.status === FormConst.ARCHIVED ? null : (<div></div>)
					}
					{submission.metadata !== undefined && submission.metadata.state !== undefined && submission.metadata.state === FormConst.SUBMITTED ? null : form.properties.status === FormConst.ARCHIVED ? null : (
						<div>
							<li className="nav-item list-inline-item">
								<Link className="nav-link" to={`/form/${formId}/submission/${submissionId}/edit`}>
									<i className="fa fa-edit" /> Επεξεργασία
								</Link>
							</li>
							<li className="nav-item list-inline-item">
								<Link className="nav-link" to={`/form/${formId}/submission/${submissionId}/delete`}>
									<i className="fa fa-trash" /> Διαγραφή
								</Link>
							</li>
						</div>
					)}
				</ul>
				<Switch>
					<Route exact path="/form/:formId/submission/:submissionId" component={View} />
					<Route path="/form/:formId/submission/:submissionId/edit" component={Edit} />
					<Route path="/form/:formId/submission/:submissionId/delete" component={Delete} />
					<Route path="/form/:formId/submission/:submissionId/exportPDF" component={SubmissionExportPDF} />
				</Switch>
			</div>
		);
	}
};

const mapStateToProps = state => {
	const form = selectRoot('form', state);
	const auth = selectRoot('auth', state);
	const pin = selectRoot('pin', state);
	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		auth: auth,
		form: form.form,
		submission: selectRoot('submission', state).submission,
		pin: pin.pin
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getSubmission: id => dispatch(getSubmission('submission', id, ownProps.match.params.formId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
