/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */

// g_papaioannou
// 13-02-2024 - Disabled Custom Option CSS to the Form builder at line 74

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	selectRoot,
	resetSubmissions,
	resetSubmission,
	saveSubmission,
	getSubmission,
	getSubmissions,
	selectError,
	Errors,
} from 'react-formio';
import { push } from 'connected-react-router';
import { AppConfig } from '../../../../../config';
import Loading from '../../../../../containers/Loading';
import Form from '../../../../../components/Form';
import asyncFetch from '../../../../../helpers/helperFunctions';
import exportSubmissionDataPDF from './exportSubmissionDataPDF';
import { FormConst } from '../../../../../config';

class SubmissionForm extends PureComponent {
	/**
	 * get user either from state or local storage
	 * use user to get his submissions for the current form
	 */
	componentDidMount() {
		const { auth, getSubmissions, resetSubmission, form } = this.props;
		resetSubmission('submission');
		if (auth.user === null) {
			const user = JSON.parse(localStorage.getItem('formioUser'));
			getSubmissions(user.owner, form.id);
		} else {
			getSubmissions(auth.user._id, form.id);
		}
	}

	render() {
		const {
			hideComponents,
			onSubmit,
			// onLoad,
			// options,
			errors,
			form: { form, isActive: isFormActive },
			// getSubmissions,
			submission: { submission, isActive: isSubActive, url },
		} = this.props;

		if (isFormActive || isSubActive) {
			return <Loading />;
		}

		//const endDate = new Date(form.properties.endDate)
		//const dateToDisplay = endDate.getDate() + "/" + (endDate.getMonth()+1) + "/" + endDate.getFullYear()

		return (
			<>
				<h3>Υποβολή αίτησης: {form.title}</h3>
				{/* <h4 style={{color: "#20a8d8"}}> Η Φόρμα λήγει στις: {dateToDisplay} - 23:59</h4> */}
				<Errors errors={errors} />
				<Form
					form={form}
					submission={submission}
					url={url}
					hideComponents={hideComponents}
					onSubmit={onSubmit}
					//   onLoad={onLoad}
					// options={{ ...{ template: 'bootstrap3', iconset: 'fa' }, ...options }}
				/>
			</>
		);
	}
}

const setSubmissionStatus = (dispatch, submission, ownProps) => {
	if (submission.data.saveDraft !== undefined) {
		submission.metadata.state = submission.state;
	}
	dispatch(
		saveSubmission('submission', submission, ownProps.match.params.formId, (err, savedSubmission) => {
			if (!err) {
				dispatch(resetSubmissions('submission'));
				dispatch(push(`/form/${ownProps.match.params.formId}/submission/${savedSubmission._id}`));
				if (savedSubmission.metadata.state === FormConst.SUBMITTED) {
					exportSubmissionDataPDF(savedSubmission);
				}
			}
		}),
	);
};

const setUserData = async (dispatch, submission, ownProps) => {
	const user = await asyncFetch({
		url: `${AppConfig.apiUrl}/current`,
		method: 'GET',
	});
	submission.data.userEmail = user.data.email;
	setSubmissionStatus(dispatch, submission, ownProps);
};

const mapStateToProps = state => {
	return {
		auth: selectRoot('auth', state),
		form: selectRoot('form', state),
		submission: selectRoot('submission', state),
		options: {},
		errors: [selectError('submission', state), selectError('form', state)],
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		resetSubmission: name => {
			dispatch(resetSubmission(name));
		},
		getSubmissions: (ownerid, formid) => {
			dispatch(
				getSubmissions('submission', 0, { owner: ownerid }, formid, (err, submissionOfUser) => {
					if (submissionOfUser !== undefined) {
						const [subm] = submissionOfUser;
						dispatch(getSubmission('submission', subm._id, subm.form));
					}
				}),
			);
		},
		onSubmit: submission => {
			if (submission.state === FormConst.SUBMITTED && submission.data.userEmail === '') {
				return setUserData(dispatch, submission, ownProps);
			}
			return setSubmissionStatus(dispatch, submission, ownProps);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionForm);
