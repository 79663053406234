/**********************************************************************************
 * G. Papaioannou
 * g_papaioannou@rocketmail.com
 * Mar, 2022
 *
 *
 *
 *
 *
 *
 ***********************************************************************************/

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { initAuth, Formio, Components } from 'react-formio';
import { store, persistor, history } from './store';
import App from './App';
import components from './components';
import { AppConfig } from './config';
// import './styles.scss'
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
// import ReactDOM from 'react-dom';
import './index.css';
import { PersistGate } from 'redux-persist/integration/react';
//import * as serviceWorker from './serviceWorker';

import fetch from 'node-fetch';
import { SET_MAINTENANCE } from './redux/actions/types';

Formio.setProjectUrl(AppConfig.projectUrl);
Formio.setBaseUrl(AppConfig.apiUrl);
Components.setComponents(components);

// Initialize the current user
store.dispatch(initAuth());

/*************************************************************************************
Check Maintenance when a user lands at the home page without any delay that TimeInterval 
loop defined at App.js. User must know immediately when the system is under maintenance
and refuse him from loggin in

Its an IIFE JS function that executed only in the homepage and only once since React 
is a SPA type of application.

maintenance check directly from the home page

*************************************************************************************/
let urlGetMaintenanceRest = `${AppConfig.apiUrlUser}/api/getMaintenance`;

let httpHeaders = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
};

(async () => {
	try {
		const res = await fetch(`${urlGetMaintenanceRest}`, {
			method: 'GET',
			headers: httpHeaders,
		});

		const json_results = await res.json();
		const MaintenanceValue = json_results['data']['maintenance'] === 'true';
		const state = store.getState();
		store.dispatch({ ...state, type: SET_MAINTENANCE, payload: MaintenanceValue });
	} catch (err) {
		//if back server is down set maintance = true

		store.dispatch({
			type: 'SET_MAINTENANCE',
			payload: true,
		});
	}
})();

render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			{/* <div> */}
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
			{/* </div> */}
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'),
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
