import React from 'react';

export default class Loading extends React.Component {
  render() {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Φόρτωση...</span>
        </div>
      </div>
    )
  }
}



