import CheckMatrix from './CheckMatrix';
import ReactExample from "./ReactExample/ReactExample";
import SaveDraftComponent from './ReactExample/SaveDraftComponent';
import YpepthFileComponent from './ReactExample/YpepthFileComponent';

export default {
  checkmatrix: CheckMatrix,
  reactexample: ReactExample,
  savedraft: SaveDraftComponent,
  ypepthFileComponent: YpepthFileComponent
}
