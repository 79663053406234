import ReactDOM from 'react-dom';
import { ReactComponent } from 'react-formio';
import {FormConst} from '../../config'


export default class SaveDraftComponent extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Προσωρινή Αποθήκευση',
      icon: 'square',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: SaveDraftComponent.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'button',
      key: 'saveDraft',
      theme: 'secondary',
      action: 'saveState',
      state: FormConst.DRAFT,
      label: 'Προσωρινή Αποθήκευση',
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
//   static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
//   attachReact(element) {
//     console.log('attachReact', element);
//     return ReactDOM.render(
//       <Example
//         component={this.component} // These are the component settings if you want to use them to render the component.
//         value={this.dataValue} // The starting value of the component.
//         onChange={this.updateValue} // The onChange event to call when the value changes.
//       />,
//       element
//     );
//   }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  static detachReact(element) {
    // console.log('detachReact', element);
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
