import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveForm, selectForm, Errors, selectError, selectRoot } from 'react-formio';
import FormEdit from '../../../components/FormEdit';
import translateGR from '../../../translate/translateGR';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormConst } from '../../../config';
import { Redirect } from 'react-router-dom';

const Edit = props => {
	const { errors, form, saveForm, saveText, options, roles, auth, } = props;

	const [formPropertiesStatus, setFormPropertiesStatus] = useState('');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		setFormPropertiesStatus(form.properties.status);
	});

	/* let renderFormEdit = true
  if(formPropertiesStatus === "archived") {
	  renderFormEdit = false
  }

  if(formPropertiesStatus === "archived") {
	 */

	function renderFormEdit() {
		if (auth === undefined || auth.authenticated === false) {
			return <Redirect to="/" />;
		}
		/* if(form.properties.pin && form.properties.pin !== pin) {
			return <Redirect to="/" />;
		} else */

		if (formPropertiesStatus === FormConst.ARCHIVED) {
			return (
				<div>
					<div className="alert alert-danger" role="alert">
						<h4>Η φόρμα είναι Αρχειοθετημένη.</h4>
						<h5>Δεν θα αποθηκευτεί καμία αλλαγή.</h5>
					</div>
					<br></br>
					<FormEdit form={form} saveText={'Αδύνατη Αποθήκευση'} options={options} roles={roles} />
				</div>
			);
		} else if (formPropertiesStatus === FormConst.PUBLISHED) {
			return (
				<div>
					<div className="alert alert-warning" role="alert">
						<h4>Η φόρμα είναι Δημοσιευμένη.</h4>
					</div>
					<br></br>
					<FormEdit form={form} saveText={saveText} saveForm={saveForm} options={options} roles={roles} />
				</div>
			);
		} else {
			return <FormEdit form={form} saveText={saveText} saveForm={saveForm} options={options} roles={roles} />;
		}
	}

	return (
		<div>
			<h4>Επεξεργασία Φόρμας: {form.title}</h4>
			<hr />
			{form.properties && form.properties.pin && form.properties.pin !== undefined ?
				<div class="alert alert-primary" role="alert">
					Στη Φόρμα έχει οριστεί <strong>PIN: {form.properties.pin}</strong>
				</div>
				: <></>}
			<Errors errors={errors} />
			{/* <div id="formSuccess" className="alert alert-success alert-dismissible fade show" role="alert" style={{ display: "none" }}>
        <h4 className="alert-heading">Συγχαρητήρια!</h4>
        <p>Η φόρμα αποθηκεύτηκε</p>
      </div> */}
			{renderFormEdit()}
		</div>
	);
};

const mapStateToProps = state => {
	const auth = selectRoot('auth', state);

	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		auth: auth,
		form: selectForm('form', state),
		options: { language: 'en', i18n: JSON.parse(translateGR) },
		saveText: 'Αποθήκευση Φόρμας',
		errors: selectError('form', state),
		roles: selectRoot('auth', state).roles,

	};
};

const mapDispatchToProps = dispatch => {
	return {
		saveForm: form =>
			dispatch(
				saveForm('form', form, (err, form) => {
					if (!err) {
						// TODO: Display a save success message here.
						toast.success('Η φόρμα αποθηκεύτηκε επιτυχώς', {
							position: 'top-right',
							autoClose: 4000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
						});
					}
					// document.getElementById("formSuccess").style.display = "block";
				}),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
