import { AppConfig, FormConst } from '../config';
import exportSubmissionDataPDFAdminEmail from '../views/Form/Item/Submission/Item/exportSubmissionDataPDFAdminEmail';

export const sendEmailToUser = (formTitle, submissionId, userEmail) => {
    const authorization = 'Bearer ' + window.localStorage.getItem('formioToken');
    const apiURL = AppConfig.apiUrlUser
    const url = `${apiURL}/api/sendemail`
    try {
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: authorization,
            },
            body: JSON.stringify({
                emailto: `${userEmail}`,
                subject: `Επιτυχής υποβολή στη Φόρμα ${formTitle}`,
                text: `Έχετε επιτυχώς υποβάλει δεδομένα στη Φόρμα ${formTitle}. Κωδικός Υποβολής: ${submissionId}`,
                html: `<p>Έχετε επιτυχώς υποβάλει δεδομένα στη Φόρμα <strong>${formTitle}</strong>. Κωδικός Υποβολής: ${submissionId}</p>`
            })
        })
            .then(response => {
                if (response.ok) {
                    return true
                }
            })
        /* .then(response => response.json())
        .then(response => console.log(JSON.stringify(response.status)))
        .then(response => console.log(JSON.stringify(response))) */
    } catch (err) {
        console.log('BackEnd Error. Description=[ ', err, ' ]');
        return false;
    }
}

export const sendEmailToAdmin = (form, adminEmail, submission, connecteduser) => {
    const authorization = 'Bearer ' + window.localStorage.getItem('formioToken');
    const apiURL = AppConfig.apiUrlUser
    const url = `${apiURL}/api/sendemail`

    let filelinksTxt = "" 
    let filelinksHtml = ""

    let attachments = []
    /* eslint-disable no-unused-vars */
    for (const [key, value] of Object.entries(submission.data)) {
        if (typeof value === 'object' && Array.isArray(value)) {
            //file uploader
            if (value[0] && value[0].storage === 'url') {
                if (value.length > 1) {
                    for (let i = 0; i < value.length; i++) {
                        filelinksTxt += "<a href='" + value[i].url + "'>" + value[i].originalName + "</a>\n"
                        filelinksHtml += "<p><a href='" + value[i].url + "'>" + value[i].originalName + "</a></p>"
                    }
                } else {
                    filelinksTxt += "<a href='" + value[0].url + "'>" + value[0].originalName + "</a><br>\n"
                    filelinksHtml += "<p><a href='" + value[0].url + "'>" + value[0].originalName + "</a><br></p>"
                }

            }
            //base64 filefields
            if (value[0] && (value[0].storage === 'base64')) {
                if (value.length > 1) {
                    for (let i = 0; i < value.length; i++) {
                        attachments.push({ filename: value[i].originalName, size: value[i].size, data: value[i].url })
                    }
                } else {
                    attachments.push({ filename: value[0].originalName, size: value[0].size, data: value[0].url })
                }

            }
        }
    }
    //console.log("attachments", attachments)
    //console.log("filelinks", filelinks)
    if(form.properties.exportPDF === FormConst.EXPORT_PDF) {
        let usersPDF = exportSubmissionDataPDFAdminEmail(form, submission)
        attachments.unshift(usersPDF)
    }

    let text = `Επιτυχής υποβολή στη Φόρμα ${form.title} από τον χρήστη ${connecteduser.userrealname}. Κωδικός Υποβολής: ${submission._id}\n`
    let htmlText = `
    <p>Επιτυχής υποβολή στη Φόρμα <strong>${form.title}</strong> από τον χρήστη <strong>${connecteduser.userrealname}</strong>. Κωδικός Υποβολής: ${submission._id}</p>
    `
    
    if (filelinksTxt) {
        text += filelinksTxt
    }

    if (filelinksHtml) {
        htmlText += `<p>Μπορείτε να κατεβάσετε τα συνημμένα αρχεία από τους συνδέσμους παρακάτω.</p>`
        htmlText += filelinksHtml
    }

    //console.log("connecteduser name", connecteduser.userrealname)
   /*  console.log(JSON.stringify({
        emailto: `${adminEmail}`,
        subject: `Επιτυχής υποβολή στη Φόρμα ${form.title} από τον χρήστη ${connecteduser.userrealname}`,
        text: text,
        html: htmlText,
        attachments: attachments,
    })) */

    //console.log("attachments ", attachments)
    try {
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: authorization,
            },
            body: JSON.stringify({
                emailto: `${adminEmail}`,
                subject: `Επιτυχής υποβολή στη Φόρμα ${form.title} από τον χρήστη ${connecteduser.userrealname}`,
                text: text,
                html: htmlText,
                attachments: attachments,
            })
        })
            .then(response => {
                if (response.ok) {
                    return true
                }
            })
        /* .then(response => response.json())
        .then(response => console.log(JSON.stringify(response.status)))
        .then(response => console.log(JSON.stringify(response))) */
    }
    catch (err) {
        console.log('BackEnd Error. Description=[ ', err, ' ]');
        return false;
    }
}

export const sendEmailToUserAfterAdminsEdit = (formTitle, submissionId, userEmail) => {
    const authorization = 'Bearer ' + window.localStorage.getItem('formioToken');
    const apiURL = AppConfig.apiUrlUser
    const url = `${apiURL}/api/sendemail`
    try {
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: authorization,
            },
            body: JSON.stringify({
                emailto: `${userEmail}`,
                subject: `Ενημέρωση της υποβολής ${submissionId} στη Φόρμα ${formTitle} από τον Διαχειριστή.`,
                text: `Η υποβολή με Κωδικό ${submissionId} στη Φόρμα ${formTitle} έχει ενημερωθεί από τον Διαχειριστή της Φόρμας`,
                html: `<p>Η υποβολή με Κωδικό ${submissionId} στη Φόρμα ${formTitle} έχει ενημερωθεί από τον Διαχειριστή της Φόρμας</p>`
            })
        })
            .then(response => {
                if (response.ok) {
                    return true
                }
            })
        /* .then(response => response.json())
        .then(response => console.log(JSON.stringify(response.status)))
        .then(response => console.log(JSON.stringify(response))) */
    }
    catch (err) {
        console.log('BackEnd Error. Description=[ ', err, ' ]');
        return false;
    }
}