/**********************************************************************************
 * G. Papaioannou
 * g_papaioannou@rocketmail.com
 * Oct, 2022
 *
 *
 *
 * 20-10-2022 un-authorized users can not delete the form
 * 			  Fixed the redirection after deletion
 * 			  Added undefined check
 *
 *
 ***********************************************************************************/

import React from 'react';
import { connect } from 'react-redux';
import Confirm from '../../../containers/Confirm';
import { deleteForm, resetForms, selectError, Errors, selectRoot } from 'react-formio';
import { push, goBack } from 'connected-react-router';
import { Redirect } from 'react-router-dom';

const Delete = props => {
	const { auth } = props;

	if (auth === undefined || auth.authenticated === false || !auth.is.administrator) {
		return <Redirect to="/" />;
	}

	return (
		<div>
			<Errors errors={props.errors} />
			<Confirm {...props} />
		</div>
	);
};

const mapStateToProps = state => {
	const auth = selectRoot('auth', state);
	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		auth: auth,
		message: `Είστε βέβαιοι ότι θέλετε να διαγράψετε τη φόρμα "${state.form.form.title}"?`,
		errors: selectError('form', state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onYes: adminType => {
			dispatch(
				deleteForm('form', ownProps.match.params.formId, err => {
					if (!err) {
						dispatch(resetForms('forms'));
						if (typeof adminType === 'undefined') return;
						//check to see if is Admin or Creator
						if (adminType === 'adminRole')
							//admin
							dispatch(push('/form/adminlistforms'));
						//formbuilderRole;
						else dispatch(push('/form/formbuilderlistforms'));
					}
				}),
			);
		},
		onNo: () => {
			dispatch(goBack());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
