import React from "react";
import { Link } from 'react-router-dom';
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Badge from 'react-bootstrap/Badge';
import DOMPurify from 'dompurify';

const FormsSubmissionItem = (props) => {
    //console.log("props.submission", props.submission)
    const [open, setOpen] = useState(false);
    const [arrowButtonColor, setArrowButtonColor] = useState("blue");

    const theDateAndTime = new Date(props.submission.modified)
    const strDate = `${theDateAndTime.getDate()}/${theDateAndTime.getMonth() + 1}/${theDateAndTime.getFullYear()}`
    const strTime = `${(theDateAndTime.getHours() < 10 ? '0' : '') + theDateAndTime.getHours()}:${theDateAndTime.getMinutes() < 10 ? '0' : ''}${theDateAndTime.getMinutes()}`

    let draftSubmission = false
    if(props.submission.metadata.state === 'draft') {
        draftSubmission = true
    }

    let badgeSubState
    if (props.submission.metadata.state === 'submitted') {
        badgeSubState = <Badge style={{ fontSize: "1.05rem", cursor: "default" }} bg="success" data-toggle="tooltip" data-placement="left" title="Τελική Υποβολή">T</Badge>
    }
    else {
        badgeSubState = <Badge style={{ fontSize: "1.05rem", cursor: "default" }} bg="warning" data-toggle="tooltip" data-placement="left" title="Προσωρινή Υποβολή">Π</Badge>
    }

    let str = ""
    
    const objValuesArray = Object.values(props.submission.data)
    //console.log(objValuesArray)
    objValuesArray.forEach((el, /* index, objValuesArray */) => {
        str += el + " "
     })
    //console.log(str)

    let usersName = ""
    if(props.submission.data.name.length > 45) {
        usersName = props.submission.data.name.split(' ').slice(0,2).join(' ')
    } else {
        usersName = props.submission.data.name
    }

    return (
        <li class="list-group-item">
            {badgeSubState} &nbsp;
            {/* <i><span style={{ color: "maroon", fontWeight: 'bold' }}>{submission.submission.metadata.state}</span></i> &nbsp; */}
            <b>{strDate}/ {strTime}</b>&nbsp;
            <span style={{ color: "navy" }}>{props.submission.data.userEmail}</span>&nbsp;
            {usersName}&nbsp;
            <Button style={{
                fontSize: "0.6rem",
                backgroundColor: 'white',
                color: arrowButtonColor,
                borderRadius: '50px',
                marginLeft: '10px',
            }} title="Προεπισκόπηση"
                onClick={() => {
                    setOpen(!open)
                    if (open) {
                        setArrowButtonColor("blue")
                    } else {
                        setArrowButtonColor("#9ba9f9")
                    }
                }}
                aria-controls="collapse-text"
                aria-expanded={open}
            >
                Προεπισκόπηση
            </Button>

            {/* Operations */}
            <span style={{ border: "1px solid #9ba9f9", borderRadius: "10px", float: "right", fontSize: "1rem" }}>
                <Link /* className="nav-link" */ to={`/form/${props.formID}/submission/${props.submission._id}`}>
                    <span style={{ color: "blue", marginLeft: "5px", marginRight: "5px" }}>
                        <i class="fa fa-eye" aria-hidden="true" title="Προβολή"></i>
                    </span>
                </Link>&nbsp;
                <Link /* className="nav-link" */ to={`/form/${props.formID}/submission/${props.submission._id}/edit`}>
                    <span style={{ color: "#023020", marginRight: "5px" }}>
                        <i class="fa fa-pencil" aria-hidden="true" title="Επεξεργασία"></i>
                    </span>
                </Link>&nbsp;
                {props.formPDF === "YES" ?
                    <Link /* className="nav-link" */
                    style={{pointerEvents: draftSubmission ? 'none' : 'auto',
                    opacity: draftSubmission ? '0.5' : 'auto'}} 
                    to={`/form/${props.formID}/submission/${props.submission._id}/exportPDF`}>
                        <span style={{ color: "red", marginRight: "5px" }}>
                            <i className="fa fa-file-pdf-o" aria-hidden="true" title="Εξαγωγή PDF"></i>
                        </span>
                    </Link>
                    : <></>}
                <Link /* className="nav-link" */ to={`/form/${props.formID}/submission/${props.submission._id}/delete`}>
                    <span style={{ color: "red", marginRight: "5px" }}>
                        <i className="fa fa-trash fa-1x" title="Διαγραφή"></i>
                    </span>
                </Link>
            </span>
            {/* Operations */}

            <Collapse in={open}>
                <div id="collapse-text">
                    <p style={{
                        textAlign: "justify", marginLeft: "50px",
                        inlineSize: "inherit",
                        overflowWrap: "break-word"
                    }}>
                        {/* {str} */}
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(str) }}>
                        </div>
                    </p>
                </div>
            </Collapse>


        </li>)
}

export { FormsSubmissionItem }