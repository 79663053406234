export const removeTimeFromDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export const set2359Hours = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
}

export const setInitialSatrtDate = () => {
  let date = new Date() 
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export const setInitialEndDate = () => {
  let currentDdate = new Date()
  return new Date(currentDdate.getFullYear(), currentDdate.getMonth(), currentDdate.getDate() + 7, 23, 59, 59)
}
